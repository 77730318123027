import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { UserType } from 'shared/Interfaces'
import { auth } from '../API/auth'
import { getTokens } from '../config/tokens'
import useAsync from '../hooks/useAsync'
import Loading from './Layout/Loading'

export const useUser = () => React.useContext(UserContext)

interface Props {
    children: React.ReactChild
}

export interface UserContextType {
    loading: boolean
    user: UserType | undefined
}

export const UserContext = React.createContext<UserContextType>({
    loading: true,
    user: undefined,
})
export default function UserProvider({ children }: Props) {
    const { push, location } = useHistory()

    const [user, loading, error] = useAsync(async () => {
        try {
            const token = await getTokens()

            if (!token || !token.userId) {
                return [undefined, undefined]
            }

            return auth.getUser({ id: token.userId! })
        } catch (e) {
            console.error({ useUserDataError: e }, error)
            return Promise.resolve([e, undefined])
        }
    })
    useEffect(() => {
        const toHomeRoutes = ['/login']

        if (user && toHomeRoutes.some((path) => location.pathname.includes(path))) {
            const params = queryString.parse(location.search)
            if (params.redirect) {
                push(`${params.redirect}`)
            } else {
                push(`/`)
            }
        }
    }, [location.pathname, location.search, push, user])

    if (loading) {
        return <Loading />
    }

    return <UserContext.Provider value={{ loading: loading, user: user?.users }}>{children}</UserContext.Provider>
}
