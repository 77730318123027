import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import moment from 'moment'
import React from 'react'
import { CampaignType } from 'shared/Interfaces'

interface Props {
    campaign: CampaignType | undefined
}

export default React.memo(CampaignInfoView)

function CampaignInfoView(props: Props) {
    const { campaign } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.placeholder} />

            <Typography className={classes.title}>{'Campaign Title'}</Typography>
            <Typography className={classes.value}>{campaign?.title ?? '...'}</Typography>

            <Typography className={classes.title}>{'Campaign Type'}</Typography>
            <Typography className={classes.value}>{campaign?.type ?? '...'}</Typography>

            <Typography className={classes.title}>{'Campaign Description'}</Typography>
            <Typography style={{ maxWidth: 600 }} className={classes.value}>
                {campaign?.description ?? '...'}
            </Typography>

            <Typography className={classes.title}>{'Campaign Start Date'}</Typography>
            <Typography className={classes.value}>
                {campaign?.collectTime ? moment(campaign?.collectTime).format('YYYY/MM/DD HH:mm A') : '...'}
            </Typography>

            <Typography className={classes.title}>{'Campaign End Date'}</Typography>
            <Typography className={classes.value}>
                {campaign?.untilTime ? moment(campaign?.untilTime).format('YYYY/MM/DD HH:mm A') : '...'}
            </Typography>

            <Typography className={classes.title}>{'Campaign Images'}</Typography>
            <div className={classes.imagesContainer}>
                {campaign?.images?.map((img, idx) => {
                    return <img key={idx} className={classes.img} src={img} />
                })}
            </div>

            <div className={classes.placeholder} />
            <div className={classes.placeholder} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.text.hint,
        marginBottom: 8,
        wordBreak: 'break-word',
    },
    value: {
        fontSize: 16,
        color: theme.palette.text.primary,
        marginBottom: 32,
        wordBreak: 'break-word',

    },
    placeholder: {
        minHeight: 24,
    },
    imagesContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 8,
        maxWidth: 640,
    },
    img: {
        maxWidth: 200,
        borderRadius: 4,
    },
}))
