import {
    AddAgencyArgs,
    AddAgencyResponse,
    AddAgentArgs,
    AddAgentResponse,
    ChangeAgentPermissionsArgs,
    ChangeAgentPermissionsResponse,
    GetAgenciesResponse,
    GetAgentsArgs,
    GetAgentsResponse,
    ToggleAgencyArgs,
    ToggleAgencyResponse,
    ToggleAgentArgs,
    ToggleAgentResponse,
} from 'shared/types/agencyTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const agencies = {
    getAll: () => sendRequest<GetAgenciesResponse>({ endpoint: serverEndPoints.agencies.get, args: {}, method: 'get' }),
    add: (args: AddAgencyArgs) => sendRequest<AddAgencyResponse>({ endpoint: serverEndPoints.agencies.add, args }),
    getAgents: (args: GetAgentsArgs) =>
        sendRequest<GetAgentsResponse>({ endpoint: serverEndPoints.agents.get, args, method: 'get' }),
    addAgent: (args: AddAgentArgs) => sendRequest<AddAgentResponse>({ endpoint: serverEndPoints.agents.add, args }),
    toggleAgency: (args: ToggleAgencyArgs) =>
        sendRequest<ToggleAgencyResponse>({ endpoint: serverEndPoints.agencies.toggle, args, method: 'put' }),
    toggleAgent: (args: ToggleAgentArgs) =>
        sendRequest<ToggleAgentResponse>({ endpoint: serverEndPoints.agents.toggle, args, method: 'put' }),
    changePermssions: (args: ChangeAgentPermissionsArgs) =>
        sendRequest<ChangeAgentPermissionsResponse>({
            endpoint: serverEndPoints.agents.changePermissions.replace(':id', args._id),
            args,
            method: 'put',
        }),
}
