import { Avatar, Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiGiftOutline } from '@mdi/js'
import { default as React } from 'react'
import { PointType } from 'shared/Interfaces'
import useBoolean from '../../../hooks/useBoolean'
import { colorSelector } from '../../../utils/colorSelector'
import { mdiIcon } from '../../../utils/mdiIcon'
import PointDialog from './PointDialog'
interface Props {
    point: PointType
    theme: Theme
    pointRemover: (idx: number) => void
    idx: number
}

export default React.memo(MapMarker)
const Gift = mdiIcon(mdiGiftOutline)

function MapMarker(props: Props) {
    const { theme, point, pointRemover, idx } = props
    const classes = useStyles()

    const [open, handleOpen, handleClose] = useBoolean()

    const colour = colorSelector(point.category)

    return (
        <div className={classes.container}>
            {open ? (
                <PointDialog
                    originalPoint={point}
                    theme={theme}
                    state={open}
                    onPress={handleClose}
                    // eslint-disable-next-line react/jsx-no-bind
                    pointRemover={() => pointRemover(idx)}
                />
            ) : null}

            <svg
                className={classes.marker}
                onClick={handleOpen}
                width="42"
                height="59"
                viewBox="0 0 42 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_f)">
                    <ellipse cx="21" cy="53" rx="8" ry="4" fill="#374047" fillOpacity="0.16" />
                </g>
                <path
                    d="M33.1529 37.5109C38.2147 33.7791 41.5 27.7732 41.5 21C41.5 9.67816 32.3218 0.5 21 0.5C9.67816 0.5 0.5 9.67816 0.5 21C0.5 27.7732 3.78529 33.7791 8.84713 37.511C13.935 41.3687 17.8775 46.5399 20.2497 52.4705C20.5206 53.1478 21.4794 53.1478 21.7503 52.4705C24.1226 46.5399 28.065 41.3687 33.1529 37.5109Z"
                    fill={colour}
                    stroke="url(#paint0_linear)"
                />
                <g>
                    <rect x="5" y="5" width="32" height="32" rx="16" fill="#374047" fillOpacity="0.16" />
                </g>
                <defs>
                    <filter
                        id="filter0_f"
                        x="11"
                        y="47"
                        width="20"
                        height="12"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur" />
                    </filter>
                    <linearGradient
                        id="paint0_linear"
                        x1="21"
                        y1="1"
                        x2="21"
                        y2="52.4785"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0.35" />
                    </linearGradient>
                </defs>
            </svg>
            <Gift className={classes.gift} />
            {point.avatar ? <Avatar src={point.avatar} className={classes.avatar} /> : null}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        height: 0,
        width: 42,
        cursor: 'pointer',
        bottom: 0,
        transition: theme.transitions.create('bottom'),
        '&:hover': {
            bottom: 4,
        },
        '&:active': {
            bottom: -4,
        },
    },
    marker: {
        position: 'absolute',
        bottom: -8,
        right: 21,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '24px 16px',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    tableContainer: {
        width: 800,
    },
    dialog: {
        width: '100%',
    },

    markerDialogZindex: {
        width: '100%',
        zIndex: 1299,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    gift: {
        color: theme.palette.background.paper,
        top: -41,
        left: -12,
        height: 21,
        position: 'absolute',
        pointerEvents: 'none',
    },
    avatar: {
        color: theme.palette.background.paper,
        top: -46,
        left: -16,
        position: 'absolute',
        pointerEvents: 'none',
        width: 32,
        height: 32,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    regionCoords: {
        fontWeight: 400,
        fontSize: 12,
    },
    titleCode: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerInfo: {},
    phoneNumber: {
        marginBottom: 16,
    },
}))
