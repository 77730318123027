import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiGiftOutline } from '@mdi/js'
import { FormikProps } from 'formik'
import { map, sum } from 'lodash'
import React, { useMemo } from 'react'
import { PointType } from 'shared/Interfaces'
import { regions } from '../../../API/regions'
import useAsync from '../../../hooks/useAsync'
import { mdiIcon } from '../../../utils/mdiIcon'
import { CreateCampaignForm } from '../CreateCampaign'

interface Props {
    point: PointType
    form: FormikProps<CreateCampaignForm>
}

const Gift = mdiIcon(mdiGiftOutline)

export default React.memo(PointViewItem)

function PointViewItem(props: Props) {
    const { point, form } = props



    const classes = useStyles()
    const [loadedRegion] = useAsync(() => regions.getById({ regionId: point.region! }), [])


    const pointsList = useMemo(() => map(form.values.points, (it) => it), [form.values.points])
    const offersAmount = useMemo(
        () => sum(pointsList.filter((it) => it.pointId === point._id).map((x) => parseInt(x.amount))),
        [point._id, pointsList]
    )

    if (!offersAmount || !loadedRegion) {
        return null
    }

    return (
        <div key={point._id} className={classes.container}>
            <div className={classes.pointInfoHeader}>
                <div className={classes.infoContainer}>
                    <div className={classes.giftIconContainer}>
                        <Gift className={classes.giftIcon} />
                    </div>

                    <div className={classes.pointInfo}>
                        <Typography className={classes.pointTitle}> {point.title}</Typography>
                        <Typography className={classes.pointRegion}> {loadedRegion.region?.name}</Typography>
                    </div>
                </div>

                <div className={classes.giftsLength}>
                    <Typography className={classes.lengthNumber}>{offersAmount}</Typography>
                    <Typography className={classes.lengthNumber}>Offer</Typography>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        // alignItems: 'center',
        // justifyContent: 'space-between',
    },

    giftsLength: {

        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        border: `1px solid ${theme.palette.primary.main}`,
        padding: 4,
    },
    lengthNumber: {
        color: theme.palette.primary.main,
        fontSize: 14,
        margin: 2,

    },
    infoContainer: {
        display: 'flex',
    },
    pointInfoHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    pointInfo: {
        margin: '0px 8px',
    },
    pointTitle: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 14,
    },
    pointRegion: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },

    giftIconContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#673AB7',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    giftIcon: {
        color: '#FFF',
    },

    titleTypography: {
        color: theme.palette.text.primary,
        fontSize: 14,
    },
    descriptionTypography: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
}))
