import {
    ChangeAdminPermissionsArgs,
    ChangeAdminPermissionsResponse,
    CreateAdminArgs,
    CreateAdminResponse,
    GetAdminsArgs,
    GetAdminsResponse,
    ToggleAdminArgs,
    ToggleAdminResponse,
} from 'shared/types/adminTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const admins = {
    getAll: (args: GetAdminsArgs) =>
        sendRequest<GetAdminsResponse>({ endpoint: serverEndPoints.admins.getAll, args, method: 'get' }),
    addAdmin: (args: CreateAdminArgs) =>
        sendRequest<CreateAdminResponse>({ endpoint: serverEndPoints.admins.add, args }),
    changePermssions: (args: ChangeAdminPermissionsArgs) =>
        sendRequest<ChangeAdminPermissionsResponse>({
            endpoint: serverEndPoints.admins.changePermissions.replace(':id', args._id),
            args,
            method: 'put',
        }),
    toggle: (args: ToggleAdminArgs) =>
        sendRequest<ToggleAdminResponse>({ endpoint: serverEndPoints.admins.toggle, args, method: 'put' }),
}
