import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiArrowRight, mdiStoreOutline } from '@mdi/js'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { CampaignStatus, CampaignType } from 'shared/Interfaces'
import { regions } from '../../API/regions'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import CampaignDialog from './CampaignDialog'
import { sum } from 'lodash'

interface Props {
    campaign: CampaignType
}

export default React.memo(CampaignCard)
const Store = mdiIcon(mdiStoreOutline)
const Arrow = mdiIcon(mdiArrowRight)
function CampaignCard(props: Props) {
    const [loadedRegion] = useAsync(() => regions.getById({ regionId: campaign.region! }), [])

    const { campaign } = props

    const campaignWithRegion = {
        ...campaign,
        region: loadedRegion?.region?.name ?? campaign.region,
    }

    const classes = useStyles()

    const [currentStatus, setStatus] = useState<CampaignStatus>(campaign.status)

    const [open, setOpen, close] = useBoolean()

    const status = useCallback((statusType: CampaignStatus) => {
        if (statusType === 'pendingApproval') {
            return 'Pending Approval'
        }
        if (statusType === 'approved') {
            return 'Approved'
        }
        if (statusType === 'rejected') {
            return 'Rejected'
        } else {
            return statusType
        }
    }, [])
    const handleCloseApprove = useCallback(() => {
        close()
        setStatus('approved')
    }, [close])
    const handleCloseReject = useCallback(() => {
        close()
        setStatus('rejected')
    }, [close])


    const campaignOffers = useMemo(() => {
        return sum(Object.values(campaign.points).map(x => x.amount))
    },[campaign])



    return (
        <div>
            <CampaignDialog
                state={open}
                onPress={close}
                approveS={handleCloseApprove}
                rejectS={handleCloseReject}
                campaign={campaignWithRegion}
                currentStatus={currentStatus}
            />
            <div className={classes.container} onClick={setOpen}>
                <div className={classes.info}>
                    <div className={classes.storeContainer}>
                        <Store className={classes.store} />
                    </div>
                    <div className={classes.infoContent}>
                        <Typography className={classes[currentStatus]}>{status(currentStatus)}</Typography>
                        <Typography className={classes.title}>{campaign.title}</Typography>
                        <div className={classes.dates}>
                            <Typography className={classes.datesText}>
                                {moment(campaign.collectTime).format('LL')}
                            </Typography>
                            <Arrow className={classes.arrow} />
                            <Typography className={classes.datesText}>
                                {moment(campaign.untilTime).format('LL')}
                            </Typography>
                        </div>
                        <div className={classes.offersAds}>
                            <Typography className={classes.offersAdsText}>{campaignOffers} offers · 1 ads</Typography>
                        </div>
                    </div>
                </div>

                <div className={classes.costAndDetails}>
                    <Typography className={classes.viewDetails}>View Details</Typography>

                    <div className={classes.cost}>
                        {/* <Typography className={classes.costTitle}>Cost</Typography> */}
                        {/* <Typography className={classes.costValue}>... $</Typography> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        width: 938,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        padding: 16,
        marginTop: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create(['background']),
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.action.hover,
            '& $viewDetails': {
                opacity: 1,
            },
        },
        '&:active': {
            background: theme.palette.action.selected,
            '& $viewDetails': {
                opacity: 1,
            },
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    storeContainer: {
        width: 54,
        height: 54,
        borderRadius: 50,
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    store: {
        fontSize: 28,
        color: theme.palette.text.disabled,
    },
    infoContent: {
        marginLeft: 24,
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    advName: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginBottom: 4,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: 12,
    },
    datesText: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.hint,
    },
    offersAdsText: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    arrow: {
        fontSize: 12,
        marginInline: 4,
        color: theme.palette.divider,
    },
    dates: {
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    offersAds: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    costAndDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: '100%',
    },
    viewDetails: {
        color: theme.palette.primary.main,
        opacity: 0,
        fontWeight: 500,
        fontSize: 14,
        transition: theme.transitions.create(['opacity']),
    },
    cost: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    costTitle: { color: theme.palette.text.hint, fontWeight: 400, fontSize: 12 },
    costValue: { color: theme.palette.text.primary, fontWeight: 500, fontSize: 20 },
    pendingApproval: {
        color: "#673AB7",
        padding: 4,
        backgroundColor: "#ECE7F6",
        borderRadius: 4,
        fontSize: 14,
        textTransform: 'capitalize',
        margin: '8px 0px'
    },
    rejected: {
        color: '#F44336',
        backgroundColor: '#FFEBEE',
        padding: 4,
        borderRadius: 4,
        fontSize: 14,
        textTransform: 'capitalize',
        margin: '8px 0px'
    },
    approved: {
        color: theme.palette.success.dark,
        padding: 4,
        backgroundColor: '#A3E6CD',
        borderRadius: 4,
        fontSize: 12,
        textTransform: 'capitalize',
        margin: '8px 0px'
    },
    paused: {
        color: '#512DA8',
        backgroundColor: '#ECE7F6',
        padding: 4,
        borderRadius: 4,
        fontSize: 14,
        textTransform: 'capitalize',
        margin: '8px 0px'
    },
    stopped: {},

}))
