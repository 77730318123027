import { InputBase } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { MdSearch } from 'react-icons/md'

interface Props {
    center: (lat: number, lng: number) => void
}

export default React.memo(SearchBox)

declare const google: any

function SearchBox(props: Props) {
    const { center } = props
    const ref = useRef(null)
    const classes = useStyles()

    const [search, setSearch] = useState('')

    const setSearchState = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }, [])
    useLayoutEffect(() => {
        const searchBox = new google.maps.places.SearchBox(ref.current)

        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces()

            if (places.length == 0 || !places) {
                return
            }

            const lng = searchBox.getPlaces()[0].geometry.location.lng()
            const lat = searchBox.getPlaces()[0].geometry.location.lat()
            center(lat, lng)
        })
    }, [center])

    return (
        <div className={classes.container}>
            <InputBase
                inputProps={{ ref: ref }}
                className={classes.textField}
                onChange={setSearchState}
                value={search}
                placeholder={'Search...'}
            />
            <MdSearch className={classes.searchIcon} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    textField: {
        paddingLeft: 12,
        width: '100%',
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 14,
    },

    container: {
        zIndex: 1298,
        background: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 50,
        width: 400,
        height: 40,
        marginRight: 12,
    },
    searchIcon: {
        padding: '0px 12px',
        color: theme.palette.text.secondary,
    },
}))
