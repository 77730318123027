import { ButtonBase, Dialog, IconButton, Menu, MenuItem, Typography, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsHorizontal, mdiEyeOutline } from '@mdi/js'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { MdCheck, MdDelete } from 'react-icons/md'
import { AdVideoType } from 'shared/Interfaces'
import { adsVideos } from '../../API/adsVideos'
import { useAlert } from '../../containers/AlertProvider'
import { extraBackgrounds } from '../../containers/ThemeProvider'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import DeleteDialog from '../Points/Components/DeleteDialog'
import VideoCardDialog from './VideoCardDialog'

interface Props {
    video: AdVideoType
    deleteId: (id: string) => void
}

export default React.memo(VideoCard)

const EyeOn = mdiIcon(mdiEyeOutline)
const Dots = mdiIcon(mdiDotsHorizontal)

function VideoCard(props: Props) {
    const { video, deleteId } = props
    const theme = useTheme()
    const alert = useAlert()
    const classes = useStyles()
    const date = moment(video.createdAt).format('DD/MM/YYYY hh:mm')
    const complete = video.status == 'complete'
    const purple = extraBackgrounds.purple
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [deleteState, openDelete, closeDelete] = useBoolean()
    const [videoState, openVideo, closeVideo] = useBoolean()

    const setOpenDelete = useCallback(
        (event: any) => {
            event.preventDefault()
            event.stopPropagation()
            openDelete()
        },
        [openDelete]
    )
    const setCloseVid = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            event.stopPropagation()
            closeVideo()
        },
        [closeVideo]
    )
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const deleteVideo = useCallback(async () => {
        await adsVideos.delete({ _id: video._id })
        deleteId(video._id)
        closeDelete()
        closeVideo()
        alert('success', 'Video Deleted Successfully')
    }, [alert, closeDelete, closeVideo, deleteId, video._id])

    return (
        <>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem onClick={setOpenDelete}>
                    <MdDelete className={classes.delete} />
                    <Typography>Delete</Typography>
                </MenuItem>
            </Menu>

            <Dialog open={videoState} onClose={closeVideo} fullScreen className={classes.videoDialog}>
                <VideoCardDialog setOpenDelete={setOpenDelete} setCloseVid={setCloseVid} video={video} />
            </Dialog>

            <ButtonBase className={classes.container} onClick={openVideo}>
                {deleteState ? (
                    <DeleteDialog
                        open={deleteState}
                        handleClose={closeDelete}
                        handleConfirm={deleteVideo}
                        itemName={'Video'}
                    />
                ) : null}

                <div className={classes.info}>
                    <video src={video.video} width={104} height={64} className={classes.video} />
                    <div className={classes.infoText}>
                        <div className={classes.codeTitle}>
                            <Typography className={classes.title}>{video?.title}</Typography>
                            <Typography className={classes.code} style={{ background: purple }}>
                                {video?.code}
                            </Typography>
                        </div>

                        <div
                            className={classes.viewsContainer}
                            style={{
                                background: complete ? theme.palette.success.main : theme.palette.secondary.main,
                            }}
                        >
                            {complete ? <MdCheck className={classes.check} /> : <EyeOn className={classes.eyeOn} />}
                            <Typography className={classes.views}>
                                {video?.currentViews} / {video?.views}
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className={classes.memberCount}>
                    <Typography className={classes.date}>{date}</Typography>
                    <IconButton onClick={handleClick}>
                        <Dots />
                    </IconButton>
                </div>
            </ButtonBase>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create(['background']),
        userSelect: 'none',
        borderRadius: 4,
        '&:hover': {
            background: theme.palette.action.hover,
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    avatar: {
        height: 24,
        width: 24,
        marginInlineEnd: 8,
    },
    people: {
        fontSize: 24,
        color: theme.palette.primary.main,
    },

    agencyTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    region: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    name: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    members: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    nameAvatar: {
        display: 'flex',
        alignItems: 'center',
    },
    memberCount: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 8,
        marginInlineStart: 16,
    },
    date: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginInlineEnd: 12,
    },
    video: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },

    code: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 4,
        marginInlineStart: 8,
    },
    views: {
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.background.paper,
    },
    codeTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    viewsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        padding: '2px 4px',
    },
    check: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        fontSize: 16,
    },
    eyeOn: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        height: 16,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    videoDialog: {
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
    },
    dialogHeader: {
        zIndex: 1300,
        position: 'absolute',
        width: '100%',

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgba(55, 64, 71, 0.4)',
    },
    dialogInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 24,
    },
    dialogInfoText: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 24,
    },
    videoLibrary: {
        background: theme.palette.text.primary,
        color: theme.palette.background.paper,
        fontSize: 20,
        padding: 10,
        borderRadius: 4,
        marginInlineEnd: 16,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.background.paper,
    },
    dialogDate: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.background.paper,
    },
    dialogViewsContainer: {
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        padding: '8px 12px',
    },
    dialogViews: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.background.paper,
    },
    checkDialog: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        fontSize: 20,
    },
    eyeOnDialog: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        height: 20,
    },
    button: {
        marginInlineStart: 16,
        borderRadius: 4,
        width: 40,
        height: 40,
    },
    dots: {
        color: theme.palette.background.paper,
        margin: 0,
    },
}))
