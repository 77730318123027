import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { CampaignType } from 'shared/Interfaces'

interface Props {
    campaign: CampaignType | undefined
}

export default React.memo(CampaignVideoView)

function CampaignVideoView(props: Props) {
    const { campaign } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.placeholder} />

            <Typography className={classes.title}>{'Campaign Ad Video'}</Typography>

            <video src={campaign?.adsVideo} controls className={classes.video} />

            <div className={classes.placeholder} />
            <div className={classes.placeholder} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.palette.text.hint,
        marginBottom: 24,
    },
    placeholder: {
        minHeight: 24,
    },
    video: {
        maxHeight: 600,
    },
}))
