import { useCallback, useState } from 'react'

export default function useBoolean(initial = false) {
    const [state, setState] = useState(initial)

    const setTrue = useCallback(() => setState(true), [])
    const setFalse = useCallback(() => setState(false), [])
    const toggle = useCallback(() => setState(!state), [state])

    return [state, setTrue, setFalse, toggle] as const
}
