import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import assets from './assets'

export default React.memo(OnBoardingBackground)

function OnBoardingBackground() {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <img src={assets.CircleRight} className={classes.circleLeft} />
            <img src={assets.CircleTop} className={classes.circleTop} />
            <img src={assets.RectRight} className={classes.rectRight} />
            <img src={assets.logo} className={classes.logo} />
            <div className={classes.textContainer}>
                <Typography className={classes.title}>{'Lorem ipsum dolor sit amet, consect.'}</Typography>
                <Typography className={classes.text}>
                    {
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae sed adipiscing consequat, molestie neque urna. Etiam massa nisl, porta consectetur venenatis amet. Sed sed risus massa malesuada.'
                    }
                </Typography>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.text.primary,
        height: '100%',
        width: '30%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 0,
    },
    circleLeft: {
        width: 120,
        position: 'absolute',
        bottom: -64,
        left: 0,
        userSelect: 'none',
    },
    rectRight: {
        width: 120,
        position: 'absolute',
        bottom: 0,
        right: 0,
        userSelect: 'none',
    },
    circleTop: {
        width: 120,
        position: 'absolute',
        top: 0,
        right: 0,
        userSelect: 'none',
    },
    logo: {
        position: 'absolute',
        top: 96,
        left: 32,
        userSelect: 'none',
    },
    textContainer: {
        display: 'flex',
        margin: '0px 24px',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 450,
        zIndex: 2,
    },
    title: {
        color: '#fff',
        fontWeight: 700,
        fontSize: 32,
        textAlign: 'center',
        marginBottom: 16,
    },
    text: {
        color: '#fff',
        fontSize: 16,
        textAlign: 'center',
    },
}))
