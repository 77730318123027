import { Button, IconButton, Paper, Tabs, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tab from '@material-ui/core/Tab'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import { keys, map, values } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { CampaignType } from 'shared/Interfaces'
import * as yup from 'yup'
import { campaigns } from '../../API/campaigns'
import { subscriptions } from '../../API/subscriptions'
import TabPanel from '../../components/TabPanel'
import { useAlert } from '../../containers/AlertProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import EditCampaignADVideo from './Components/EditCampaignADVideo'
import EditCampaignCbe3dImage from './Components/EditCampaignCbe3dImage'
import EditCampaignInfo from './Components/EditCampaignInfo'
import EditCampaignPoints from './Components/EditCampaignPoints'
import EditTargetedAudience from './Components/EditTargetedAudience'

export default React.memo(EditCampaign)

export interface EditingCampaignForm {
    title: string
    type: string
    description: string
    collectTime: Date | null
    untilTime: Date | null
    adsVideo: string
    points: {
        [pointId: string]: { type: 'gifts' | 'offers'; pointId: string; amount: string }
    }
    filters: {
        ageFrom?: number
        ageUntil?: number
        gender?: 'male' | 'female' | 'both'
    }
    region?: string
    images: File[]
    cube3dImage: string
}

interface Props {
    campaignId: string
    onCloseRequest: () => void
    duplicating?: boolean
    onReload?: () => void
}

function EditCampaign(props: Props) {
    const { campaignId, onCloseRequest, duplicating,onReload } = props

    const [campaignQuery, loadingCampaignData] = useAsync(() => campaigns.getById({ id: campaignId }), [!campaignId])
    const [loaded, setLoaded] = useState(false)

    const [value, setValue] = useState(0)
    const alert = useAlert()

    const classes = useStyles()

    const [loading, setLoading, stopLoading] = useBoolean()

    const handleTabsChange = useCallback((event, newValue) => {
        setValue(newValue)
    }, [])

    const [loadedOffers] = useAsync(() => subscriptions.GetOffersCount({}), [])
    const totalCount = loadedOffers?.unlimited ? 'unlimited' : loadedOffers?.count


    const form = useFormik<Partial<EditingCampaignForm>>({
        initialValues: {
            collectTime: null,
            description: '',
            title: '',
            type: '',
            untilTime: null,
            adsVideo: undefined,
            cube3dImage: undefined,
            filters: {
                ageFrom: undefined,
                ageUntil: undefined,
                gender: undefined,
            },
            points: {},
            region: undefined,
            images: [],
        },
        validationSchema: schema,

        onSubmit: async (data) => {
            try {
                setLoading()
                await campaigns.add({
                    ...data,
                    status: 'pendingApproval',
                    adsVideo: data.adsVideo as any,
                    images: data.images as any,
                    points: data.points as any,
                } as CampaignType)

                onCloseRequest()
                if (onReload) {
                    onReload()
                }
            } catch (e) {
                alert('error', 'Error Create Campaign')
            } finally {
                stopLoading()
                alert('success', 'Create successfully')
            }
            return
        },
    })

    useEffect(() => {
        if (!campaignQuery || loaded || !campaignQuery.campaign) {
            return
        }

        const newTitle = (campaignQuery.campaign.title + ' (2)') as string
        form.setValues({ ...(campaignQuery.campaign as any as EditingCampaignForm), title: newTitle })
        setLoaded(true)
    }, [campaignId, campaignQuery, form, loaded])

    let chosenOffers = 0
    map(form.values.points, 'amount').forEach((x) => (chosenOffers += parseInt(x)))

    const validNumber = totalCount === 'unlimited' || totalCount! >= chosenOffers

    const disabled =
        !form.isValid ||
        !form.dirty ||
        !keys(form.values.points ?? {}).length ||
        // !validNumber ||
        values(form.values.points).some((x) => parseInt(x.amount, 10) < 0) ||
        chosenOffers === 0

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerBack}>
                    <IconButton onClick={onCloseRequest}>
                        <FaArrowLeft size={16} />
                    </IconButton>

                    <Typography className={classes.offerInfo}>Offer Info</Typography>
                </div>

                {loading || loadingCampaignData ? (
                    <CircularProgress size={24} />
                ) : (
                    <Button
                        disabled={disabled}
                        onClick={form.submitForm}
                        variant="contained"
                        color="primary"
                        className={classes.save}
                    >
                        {duplicating ? 'Duplicate' : 'Update'}
                    </Button>
                )}
            </div>

            <div className={classes.content}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleTabsChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Campaign Info" />

                        <Tab label="Points" />
                        <Tab label="AD Video" />
                        <Tab label="AD Image" />

                        <Tab label=" Targeted audience" />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <EditCampaignInfo editing form={form as any} loading={loading} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <EditCampaignPoints
                            form={form as any}
                            loading={loading}
                            totalCount={totalCount}
                            chosenOffers={chosenOffers}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <EditCampaignADVideo editing form={form as any} loading={loading} />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <EditCampaignCbe3dImage editing form={form as any} loading={loading} />
                    </TabPanel>

                    <TabPanel value={value} index={4}>
                        <EditTargetedAudience form={form as any} loading={loading} />
                    </TabPanel>
                </Paper>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
    },
    offerInfo: {
        color: theme.palette.text.primary,
        marginLeft: 8,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        flex: 1,
    },
    headerBack: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    header: {
        width: 'calc(100% - 48px)',
        minHeight: 72,
        maxHeight: 72,
        padding: '0px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#F9F9FA',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    root: {
        border: 'none',
        boxShadow: 'none',
        marginTop: 16,
    },
    save: {
        color: '#FFF',
        width: 120,
    },
}))

const schema = yup.object({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    type: yup.string().required('Type is required'),
    collectTime: yup.date().required('Collect time is required'),
    untilTime: yup.date().required('Until time is required'),
    points: yup.object().required('Points are required'),
    cube3dImage: yup.mixed().required(),

    filters: yup.object().shape({
        ageFrom: yup.string().required('Age From is required'),
        ageUntil: yup.string().required('Age Until is required'),
        gender: yup.string().required('Gender is required'),
    }),
})
