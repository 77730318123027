/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-multi-comp */
import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'
import { Divider, Paper } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { map, sortBy, sum } from 'lodash'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { FaBuilding } from 'react-icons/fa'
import { MdAdd } from 'react-icons/md'
import { CampaignType } from 'shared/Interfaces'
import useRouter from 'use-react-router'
import { campaigns } from '../../API/campaigns'
import Header from '../../containers/Layout/Header'
import Loading from '../../containers/Layout/Loading'
import { useUser } from '../../containers/UserProvider'
import useAsync from '../../hooks/useAsync'
import { CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from '../Regions/tableUtils'
import EditCampaign from './EditCampaign'
import ViewCampaignDialog from './View/ViewCampaignDialog'

interface Props { }

export default React.memo(CampaignsList)

function CampaignsList(props: Props) {
    const { } = props

    const { history } = useRouter()

    const classes = useStyles()
    const user = useUser().user
    const [loadedCampaigns] = useAsync(() => campaigns.getByUserId({ id: user!._id }))
    const [duplicatingCampaignId, setDuplicatingCampaignId] = useState<string | undefined>()


    const [search, setSearch] = useState('')
    const [viewedCampaign, setViewedCampaign] = useState<CampaignType | undefined>()

    const tableRowComponent = useCallback((_props) => <CustomTableFilterRow {..._props} />, [])
    const closeView = useCallback(() => setViewedCampaign(undefined), [])
    const goToCreateCampaigns = useCallback(() => history.push('/create-campaigns'), [history])




    const columns: Column[] = useMemo(() => {
        return [
            {
                title: 'Name',
                name: 'title',
                getCellValue: (row: any) => {
                    return row.title
                },
            },

            {
                title: 'Created At',
                name: 'createdAt',
                getCellValue: (row: any) => {
                    const date = moment(row.createdAt)
                    if (date.isSame(moment(), 'hour')) {
                        return date.fromNow()
                    }

                    return date.format('DD/MM/YYYY hh:mm:ss')
                },
            },
            {
                title: 'Offers',
                name: 'points',
                getCellValue: (row: any) => {
                    const points = map(row.points, (it) => {
                        return it
                    })

                    const offersAmount = sum(points.map((x) => parseInt(x.amount)))
                    return offersAmount
                },
            },
            {
                title: 'Status',
                name: 'status',
                getCellValue: (row: any) => {
                    if (row.status === 'pendingApproval') {
                        return 'Pending Approval'
                    }
                    if (row.status === 'approved') {
                        return 'Approved'
                    }
                    if (row.status === 'rejected') {
                        return 'Rejected'
                    } else {
                        return row.status
                    }
                },
            },
            {
                title: 'Actions',
                name: 'actions',
                getCellValue: (row: any) => {

                    return (
                        <div className={classes.actionsContainer}>
                            <Button onClick={() => setViewedCampaign(row)} color="primary">
                                View
                            </Button>
                            <Divider orientation='vertical' />
                            <Button onClick={() => setDuplicatingCampaignId(row._id)} color="primary">
                                Duplicate
                            </Button>
                        </div>

                    )
                },
            },
        ]
    }, [classes.actionsContainer])


    const closeDuplicateDialog = useCallback(() => {
        setDuplicatingCampaignId(undefined)
    }, [])

    const reload = useCallback(() => {
        window.location.reload()
    }, [])


    if (!loadedCampaigns) {
        return <Loading />
    }

    const sortedCampaigns = sortBy(loadedCampaigns.campaign, x=> x.createdAt).reverse()

    return (
        <div className={classes.container}>
            <Dialog fullScreen open={!!viewedCampaign}>
                <ViewCampaignDialog close={closeView} campaign={viewedCampaign} />
            </Dialog>

            {duplicatingCampaignId ? <Dialog fullScreen open>
                <EditCampaign campaignId={duplicatingCampaignId} onReload={reload} onCloseRequest={closeDuplicateDialog} duplicating />
            </Dialog> : null}

            <Header
                value={search}
                onChange={setSearch}
                title={'Campaigns'}
                Icon={FaBuilding}
                actions={[{ title: 'New Campaign', onClick: goToCreateCampaigns, Icon: MdAdd }]}
            />

            <div className={classes.content}>
                <Paper className={classes.tableContainer}>
                    {/* @ts-ignore */}
                    <Grid rows={sortedCampaigns} columns={columns}>
                        <DataTypeProvider for={['regions']} formatterComponent={CampaignComponent} />
                        <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />

                        <SortingState />

                        <IntegratedSorting />

                        <PagingState defaultCurrentPage={0} pageSize={10} />
                        <IntegratedPaging />

                        <FilteringState />
                        <IntegratedFiltering />

                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                        <PagingPanel containerComponent={CustomPagingPanel} />
                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                    </Grid>
                </Paper>
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    content: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    tableContainer: {
        width: 800,
        marginTop: 34,
    },
}))

const CampaignComponent = ({ row }: any) => <span title={row.region}>{row.region}</span>

const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />
