import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import { Button, FormControl, Paper, TextField, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiEarth } from '@mdi/js'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { RegionType } from 'shared/Interfaces'
import { regions } from '../../API/regions'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import { CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from './tableUtils'

const Earth = mdiIcon(mdiEarth)

export default React.memo(Regions)

function Regions() {
    const [loadedRegions, , , setRegions] = useAsync(() => regions.get({}))

    const alert = useAlert()
    const classes = useStyles()

    const [open, setOpen, setClosed] = useBoolean()
    const [loading, setLoading, stopLoading] = useBoolean()

    const formik = useFormik({
        initialValues: { region: '' },

        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await regions.add(values)

            if (!res) {
                console.error(err)
                stopLoading()

                return
            }

            if (res.msg === 'success') {
                alert('success', 'Region added successfully')

                setRegions({ regions: [...loadedRegions!.regions!, res.region!] })

                formik.resetForm()
            } else {
                alert('error', 'Error adding region!')
            }
            stopLoading()
            setClosed()
        },
    })

    ////////////////////////////////////////////////////
    const tableRowComponent = useCallback((_props) => <CustomTableFilterRow {..._props} />, [])

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: 'Region',
                name: 'name',
                getCellValue: (row: RegionType) => row.name,
            },

            {
                title: 'Created At',
                name: 'createdAt',
                getCellValue: (row: RegionType) => {
                    const date = moment(row.createdAt)
                    if (date.isSame(moment(), 'hour')) {
                        return date.fromNow()
                    }

                    return date.format('DD/MM/YYYY hh:mm:ss')
                },
            },
        ]
    }, [])

    if (!loadedRegions) {
        return <Loading />
    }
    
    return (
        <div className={classes.container}>
            <Dialog onClose={setClosed} open={open} classes={{ paper: classes.dialog }}>
                <div className={classes.dialogTitleContainer}>
                    <Typography className={classes.dialogTitle}>Add Region</Typography>
                    <IconButton onClick={setClosed} disabled={loading}>
                        <MdClose />
                    </IconButton>
                </div>

                <FormControl className={classes.select}>
                    <TextField
                        disabled={loading}
                        label="Region"
                        variant="filled"
                        className={classes.region}
                        onChange={formik.handleChange('region')}
                        value={formik.values.region}
                        error={formik.touched.region && Boolean(formik.errors.region)}
                        helperText={formik.touched.region && formik.errors.region}
                    />
                </FormControl>
                <div className={classes.dialogButtons}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <form onSubmit={formik.handleSubmit}>
                            <Button onClick={setClosed} className={classes.cancelButton} variant="text" color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                            >
                                Add Region
                            </Button>
                        </form>
                    )}
                </div>
            </Dialog>
            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <Earth className={classes.earth} />
                    <Typography className={classes.title}>Regions</Typography>
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        disabled={loading}
                        className={classes.addButton}
                        startIcon={<MdAdd className={classes.mdAdd} />}
                        variant="contained"
                        color="primary"
                        onClick={setOpen}
                    >
                        Add Region
                    </Button>
                </div>
            </div>
            <div className={classes.body}>
                <Paper className={classes.tableContainer}>
                    {/* @ts-ignore */}
                    <Grid rows={loadedRegions.regions} columns={columns}>
                        <DataTypeProvider for={['regions']} formatterComponent={RegionComponent} />
                        <DataTypeProvider for={['createdAt']} formatterComponent={DateComponent} />

                        <SortingState />

                        <IntegratedSorting />

                        <PagingState defaultCurrentPage={0} pageSize={12} />
                        <IntegratedPaging />

                        <FilteringState />
                        <IntegratedFiltering />

                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                        <PagingPanel containerComponent={CustomPagingPanel} />
                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                    </Grid>
                </Paper>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    titleContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    buttonContainer: {},
    earth: {
        background: theme.palette.primary.main,
        padding: 8,
        borderRadius: 4,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        paddingTop: 24,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '16px 16px',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '16px 24px',
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    tableContainer: {
        width: 800,
    },
    body: {
        height: 'calc(100% - 128px)',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
    region: {
        marginBottom: 16,
    },
}))
/* eslint-disable react/no-multi-comp */

const RegionComponent = ({ row }: any) => <span title={row.region}>{row.region}</span>

const DateComponent = ({ row }: any) => {
    return <span style={{ fontSize: 14 }}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</span>
}
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />
