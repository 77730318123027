import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useUser } from '../containers/UserProvider'

interface Props {}

const LoginRedirect = ({}: Props) => {
    const { loading, user } = useUser()
    const { push, location } = useHistory()

    useEffect(() => {
        if (!loading && !user && !location.pathname.startsWith('/login')) {
            const search =
                location.search?.trim() !== ''
                    ? `&search=${encodeURIComponent(location.search.replace(/^\?/g, ''))}`
                    : ''
            push(`/login?redirect=${encodeURIComponent(location.pathname)}${search}`)
        }
    }, [loading, location.pathname, location.search, push, user])

    return <h1>loading</h1>
}

export default LoginRedirect
