import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useCallback, useState } from 'react'
import CountriesCodes from 'shared/countries.json'

export type CountryData = {
    name: string
    code: string
    emoji: string
    unicode: string
    image: string
    dialCode: string
}

interface Props {
    loading: boolean
    setCode: (code: string) => void
}

export default React.memo(CountrySearchMenu)
function CountrySearchMenu(props: Props) {
    const { loading, setCode } = props
    const classes = useStyles()

    const countriesList = CountriesCodes as CountryData[]

    const [value, setValue] = useState(countriesList[231])

    const renderInput = useCallback(
        (params) => <TextField {...params} disabled={loading} label="Code" variant="filled" />,
        [loading]
    )

    const onChange = useCallback(
        (_, newValue: any) => {
            setValue(newValue)
            if (newValue?.dialCode) {
                setCode(newValue.dialCode)
            }
        },
        [setCode]
    )
    const getOptionLabel = useCallback((x: CountryData) => x.dialCode + ' ' + x.emoji ?? '', [])

    return (
        <Autocomplete
            className={classes.auto}
            disabled={loading}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
            options={countriesList}
            renderInput={renderInput}
        />
    )
}
const useStyles = makeStyles(() => ({
    auto: {
        marginRight: 12,
        width: 134,
    },
}))
