import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'

export const mdiIcon = (path: string) => {
    return function MdiIcon(props: SvgIconProps) {
        return (
            <SvgIcon {...props}>
                <path d={path} />
            </SvgIcon>
        )
    }
}
