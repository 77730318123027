import queryString from 'query-string'
import React, { useCallback, useMemo } from 'react'
import { ExtractRouteParams } from 'react-router'
import { Route as RouteDom, RouteProps, useLocation } from 'react-router-dom'

type Props<
    Path extends string = string,
    Params extends { [K: string]: string | undefined } = ExtractRouteParams<Path, string>
> = Omit<RouteProps<Path, Params>, 'render' | 'component'> & {
    component: React.ComponentType<any> | any
}

export default React.memo(Route)

function Route(props: Props) {
    const { children, component: Component, ...restOfProps } = props

    const { search } = useLocation()

    const params = useMemo(() => ({ ...queryString.parse(search) }), [search])
    const render = useCallback(
        ({ match }) => (
            <Component {...params} {...match.params}>
                {children}
            </Component>
        ),
        [Component, params, children]
    )

    return <RouteDom render={render} {...restOfProps} />
}
