import { Button, CircularProgress, FormControl, InputLabel, TextField, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiAccountSupervisor } from '@mdi/js'
import { useFormik } from 'formik'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import * as yup from 'yup'
import { agencies } from '../../API/agencies'
import { regions } from '../../API/regions'
import assets from '../../assets/assets'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import { useUser } from '../../containers/UserProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import { countryCodeRegex, phoneRegex } from '../Users/Login'
import AgencyCard from './AgencyCard'

const AccountIcon = mdiIcon(mdiAccountSupervisor)

export default React.memo(AgenciesList)

function AgenciesList() {
    const classes = useStyles()
    const alert = useAlert()

    const [loadedRegions] = useAsync(() => regions.get({}))
    const [loadedAgencies, , , setAgencies] = useAsync(() => agencies.getAll())

    const [loading, setLoading, stopLoading] = useBoolean()
    const [open, setOpen, setClosed] = useBoolean()

    const currentUser = useUser().user
    const isPermitted = currentUser!.permissions.includes('addAgency') || currentUser?.superAdmin

    const formik = useFormik({
        initialValues: { name: '', phoneNumber: '', region: '', title: '', countryCode: '+90' },
        validationSchema: agencySchema,

        onSubmit: async (values) => {
            setLoading()
            const { countryCode, name, phoneNumber, region, title } = values
            const PN = countryCode + phoneNumber
            const [err, res] = await agencies.add({ name, phoneNumber: PN, region, title })

            if (err || !res) {
                stopLoading()
                alert('error', 'Unknown Error')
                return
            }

            if (res.msg === 'userAlreadyAnAdmin') {
                stopLoading()
                alert('error', 'User Already Exists')
                return
            }

            alert('success', 'Agency Added Successfully')
            stopLoading()

            setAgencies({ agencies: [...loadedAgencies!.agencies!, res.data!] })

            closeAdd()
        },
    })
    const setCode = useCallback(
        (code: string) => {
            formik.setFieldValue('countryCode', code)
        },
        [formik]
    )
    const closeAdd = useCallback(() => {
        setClosed()
        formik.resetForm()
    }, [formik, setClosed])

    const sortedAgencies = useMemo(
        () => sortBy(loadedAgencies?.agencies ?? [], (x) => x.title).reverse(),
        [loadedAgencies?.agencies]
    )

    if (!loadedRegions || !loadedAgencies) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <Dialog onClose={closeAdd} open={open} classes={{ paper: classes.dialog }}>
                <div className={classes.dialogTitleContainer}>
                    <Typography className={classes.dialogTitle}>Add Agency</Typography>
                    <IconButton onClick={closeAdd}>
                        <MdClose />
                    </IconButton>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className={classes.textFields}>
                        <TextField
                            disabled={loading}
                            label="Agency Title"
                            onChange={formik.handleChange('title')}
                            variant="filled"
                            className={classes.phoneNumber}
                            value={formik.values.title}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                        <TextField
                            disabled={loading}
                            label="Admin Name"
                            onChange={formik.handleChange('name')}
                            variant="filled"
                            className={classes.phoneNumber}
                            value={formik.values.name}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <div className={classes.phoneInput}>
                            <CountrySearchMenu setCode={setCode} loading={loading} />
                            <TextField
                                disabled={loading}
                                label="Admin Phone Number"
                                onChange={formik.handleChange('phoneNumber')}
                                variant="filled"
                                className={classes.phoneNumber}
                                value={formik.values.phoneNumber}
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            />
                        </div>

                        <FormControl variant="filled" className={classes.phoneNumber}>
                            <InputLabel>Region</InputLabel>
                            <Select
                                disabled={loading}
                                error={formik.touched.region && Boolean(formik.errors.region)}
                                value={formik.values.region}
                                onChange={formik.handleChange('region')}
                            >
                                {loadedRegions?.regions.map((x) => {
                                    return (
                                        <MenuItem key={x._id} value={x._id}>
                                            {x.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </div>

                    <div className={classes.dialogButtons}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button
                                    onClick={setClosed}
                                    className={classes.cancelButton}
                                    variant="text"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.addRegionButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    Add Agency
                                </Button>
                            </>
                        )}
                    </div>
                </form>
            </Dialog>

            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <AccountIcon className={classes.accountIcon} />
                    <Typography className={classes.title}>Agencies</Typography>
                </div>

                <div>
                    {isPermitted && (
                        <Button
                            className={classes.addButton}
                            startIcon={<MdAdd className={classes.mdAdd} />}
                            variant="contained"
                            color="primary"
                            onClick={setOpen}
                        >
                            Add Agency
                        </Button>
                    )}
                </div>
            </div>

            <div className={classes.body}>
                {sortedAgencies.length == 0 ? (
                    <GenericPlaceHolder
                        description="There are no agnecies here yet."
                        svg={assets.placeHolders.Agencies}
                        customWidth={320}
                        customFS={20}
                        action={setOpen}
                        actionName="Add Agency"
                        actionHidden={!isPermitted}
                    />
                ) : (
                    <div className={classes.gridFather}>
                        <div className={classes.placeholder} />

                        <div className={classes.gridContainer}>
                            {sortedAgencies.map((a, idx) => {
                                const regionName = loadedRegions?.regions.find((r) => a.region === r._id)?.name
                                return <AgencyCard agency={a} key={idx} regionName={regionName!} />
                            })}
                        </div>

                        <div className={classes.placeholder} />
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.default,
        padding: '0px 16px',
        height: 72,
    },
    dialogButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    tableContainer: {
        width: 800,
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    gridContainer: {
        display: 'grid',
        gridGap: `32px 40px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(1, auto)',
        margin: '0px 24px',
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
        flex: 1,
    },
    agencyCards: {
        paddingTop: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    placeholder: {
        minHeight: 48,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))

const agencySchema = yup.object({
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),

    title: yup.string().required('Agency Title is required'),
    name: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Admin Name is required'),
    phoneNumber: yup.string().matches(phoneRegex, 'Phone number is not valid').required('Phone Number is required'),
    region: yup.string().required('Region is required'),
})
