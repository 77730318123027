import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { MdClose, MdDelete } from 'react-icons/md'
import { AdVideoType } from 'shared/Interfaces'
import * as yup from 'yup'
import { adsVideos } from '../../API/adsVideos'
import assets from '../../assets/assets'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'

const MAX_SIZE = 10 * 1024 * 1024

export default React.memo(CreateVideoAdDialog)
interface Props {
    setClosed: () => void
    onSubmit: (res: AdVideoType | undefined) => void
}

function CreateVideoAdDialog(props: Props) {
    const { setClosed, onSubmit } = props
    const classes = useStyles()
    const alert = useAlert()

    const [loading, setLoading, stopLoading] = useBoolean()

    const formik = useFormik({
        initialValues: { code: '', views: undefined, title: '', video: undefined },
        validationSchema: videoAdSchema,

        onSubmit: async (values) => {
            setLoading()

            const [err, res] = await adsVideos.add({ ...values, status: 'on-going', currentViews: 0 })

            if (err || !res) {
                stopLoading()
                alert('error', 'Unknown Error')
                return
            }

            if (res.msg === 'error') {
                stopLoading()
                alert('error', 'Error Adding Ad Video')
                return
            }

            alert('success', 'Ad Video Added Successfully')
            stopLoading()

            onSubmit(res.data!)

            closeAdd()
        },
    })

    const closeAdd = useCallback(() => {
        setClosed()
        formik.resetForm()
    }, [formik, setClosed])
    const onChange = useCallback(
        (e) => {
            const file = e.target.files[0]

            if (file.size > MAX_SIZE) {
                alert('error', 'Max File Size is 10MB')
                return
            }

            formik.setFieldValue('video', file)
            e.target.value = null
        },
        [alert, formik]
    )

    const video = useMemo(
        () => (formik.values.video ? URL.createObjectURL(formik.values.video) : formik.values.video),
        [formik.values.video]
    )

    const clear = useCallback(() => formik.setFieldValue('video', undefined), [formik])

    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>Add Video Ad</Typography>
                <IconButton onClick={closeAdd}>
                    <MdClose />
                </IconButton>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className={classes.textFields}>
                    <input hidden accept="video/*" type="file" id="upload-file" onChange={onChange} />

                    {video ? (
                        <div className={classes.videoContainer}>
                            <IconButton className={classes.closeIcon} disabled={loading} onClick={clear}>
                                <MdDelete />
                            </IconButton>
                            <video className={classes.video} src={video} controls />
                        </div>
                    ) : null}

                    {video ? null : (
                        <label htmlFor={'upload-file'} className={classes.uploadLabel}>
                            <div className={classes.adVideoContainer}>
                                <img src={assets.video} />
                                <Typography className={classes.dropDescription}>Click to upload your video</Typography>

                                <a className={classes.browseFile}>Browse Files</a>
                            </div>
                        </label>
                    )}
                    <div className={classes.titleCode}>
                        <TextField
                            disabled={loading}
                            label="Title"
                            onChange={formik.handleChange('title')}
                            variant="filled"
                            className={classes.titleInput}
                            value={formik.values.title}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                        <TextField
                            disabled={loading}
                            label="Code"
                            onChange={formik.handleChange('code')}
                            variant="filled"
                            className={classes.codeInput}
                            value={formik.values.code}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                            helperText={formik.touched.code && formik.errors.code}
                        />
                    </div>
                    <TextField
                        disabled={loading}
                        label="Views"
                        onChange={formik.handleChange('views')}
                        variant="filled"
                        className={classes.viewsInput}
                        value={formik.values.views}
                        error={formik.touched.views && Boolean(formik.errors.views)}
                        helperText={formik.touched.views && formik.errors.views}
                    />
                </div>

                <div className={classes.dialogButtons}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={setClosed} className={classes.cancelButton} variant="text" color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                            >
                                Add Video Ad
                            </Button>
                        </>
                    )}
                </div>
            </form>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.default,
        padding: '0px 16px',
        height: 72,
    },

    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    videoContainer: {
        position: 'relative',
    },
    adVideoContainer: {
        backgroundColor: '#F9F9FA',
        cursor: 'pointer',
        border: '1px dashed #DEE1E3',
        boxSizing: 'border-box',
        borderRadius: 4,
        height: 320,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        zIndex: 1299,
        position: 'absolute',
        top: 8,
        right: 8,
        background: theme.palette.background.default,
        padding: 8,
        transition: theme.transitions.create(['background']),
        '&:hover': {
            background: '#F9F9FA',
        },
    },
    video: {
        width: '100%',
        border: `1px ${theme.palette.divider} solid`,
        borderRadius: 8,
    },
    uploadLabel: {},
    dropDescription: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginTop: 12,
    },
    browseFile: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        marginTop: 16,
    },
    titleCode: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 16,
        marginTop: 24,
    },
    titleInput: {
        width: '100%',
        marginInlineEnd: 12,
    },
    codeInput: {},
    viewsInput: {},
    dialogButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
}))
const videoAdSchema = yup.object({
    title: yup.string().required('Title is required'),
    code: yup.string().required('Code is required'),
    views: yup.number().typeError('You must specify a number').required('Views are required'),
})
