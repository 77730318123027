import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiBellAlertOutline } from '@mdi/js'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { campaigns } from '../../API/campaigns'
import assets from '../../assets/assets'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import Loading from '../../containers/Layout/Loading'
import SearchBar from '../../containers/Layout/SearchBar'
import useAsync from '../../hooks/useAsync'
import { mdiIcon } from '../../utils/mdiIcon'
import CampaignCard from './CampaignCard'

const Bell = mdiIcon(mdiBellAlertOutline)

export default React.memo(CampaignsList)

function CampaignsList() {
    const classes = useStyles()

    const [loadedCampaigns, loading] = useAsync(() => campaigns.get({}))

    const [search, setSearch] = React.useState('')

    const onSearchChange = useCallback((e: string) => {
        setSearch(e)
    }, [])
    const sortedCampaigns = useMemo(
        () => sortBy(loadedCampaigns?.campaign ?? [], (x) => x.createdAt).reverse(),
        [loadedCampaigns?.campaign]
    )

    if (loading) {
        return <Loading />
    }

    const filteredCampaigns = search.length
        ? sortedCampaigns.filter((x) => x.title.toLowerCase().includes(search.toLowerCase()))
        : sortedCampaigns

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <Bell className={classes.bell} />
                    <Typography className={classes.title}>Campaigns</Typography>
                </div>
                <div className={classes.buttonContainer}>
                    <SearchBar onChange={onSearchChange} value={search} />
                </div>
            </div>

            {sortedCampaigns.length == 0 ? (
                <GenericPlaceHolder
                    description="There are no campaigns to review yet."
                    svg={assets.placeHolders.Campaigns}
                    customWidth={320}
                    customFS={20}
                />
            ) : (
                <div className={classes.cards}>
                    {filteredCampaigns?.map((x) => {
                        return <CampaignCard campaign={x} key={x.status} />
                    })}
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    titleContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    buttonContainer: {},
    bell: {
        background: theme.palette.primary.main,
        padding: 8,
        borderRadius: 4,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    cards: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        overflowY: 'auto',
        padding: 16,
    },
}))
