import {
    AddPointArgs,
    AddPointResponse,
    CheckPointArgs,
    CheckPointResponse,
    DeletePointArgs,
    DeletePointResponse,
    GetPointRegionArgs,
    GetPointRegionResponse,
    GetPointsByIdsArgs,
    GetPointsByIdsResponse,
} from 'shared/types/pointTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'
import { Optional } from 'ts-toolbelt/out/Object/Optional'

export const points = {
    add: (args: AddPointArgs) => sendRequest<AddPointResponse>({ endpoint: serverEndPoints.points.add, args }),
    edit: (args: Optional<AddPointArgs>, _id: string) =>
        sendRequest<AddPointResponse>({
            endpoint: serverEndPoints.points.edit.replace(':id', _id),
            args,
            method: 'put',
        }),
    get: (args: AddPointArgs) =>
        sendRequest<AddPointResponse>({ endpoint: serverEndPoints.points.get, args, method: 'get' }),
    delete: ({ _id }: DeletePointArgs) =>
        sendRequest<DeletePointResponse>({
            endpoint: serverEndPoints.points.delete.replace(':id', _id),
            args: {},
            method: 'delete',
        }),

    getByRegion: ({ region }: GetPointRegionArgs) =>
        sendRequest<GetPointRegionResponse>({
            endpoint: serverEndPoints.points.getByRegion.replace(':region', region!),
            args: {},
            method: 'get',
        }),
    check: (args: CheckPointArgs) =>
        sendRequest<CheckPointResponse>({
            endpoint: serverEndPoints.points.check,
            args,
            method: 'post',
        }),
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    getPointsByIds: (args: GetPointsByIdsArgs) =>
        sendRequest<GetPointsByIdsResponse>({
            endpoint: serverEndPoints.points.getPointsByIds,
            args,
            method: 'post',
        }),
}
