import { Button, Dialog, Typography, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormikProps } from 'formik'
import GoogleMapReact from 'google-map-react'
import { map, orderBy } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { points } from '../../../API/points'
import Loading from '../../../containers/Layout/Loading'
import { useUser } from '../../../containers/UserProvider'
import useAsync from '../../../hooks/useAsync'
import LocationMarker from '../../Points/Components/LocationMarker'
import SlideTransition from '../../Points/Components/SlideTransition'
import { CreateCampaignForm } from '../CreateCampaign'
import MapMarker, { PointsValuesState } from './MapMarker'

interface Props {
    visible: boolean
    setHidden: () => void
    form: FormikProps<CreateCampaignForm>
}

export default React.memo(EditPointsDialog)

function EditPointsDialog(props: Props) {
    const { visible, form, setHidden } = props

    const theme = useTheme()
    const classes = useStyles()
    const user = useUser()

    const region = user.user?.regionId

    const [loadedPoints] = useAsync(() => points.getByRegion({ region: region }), [!region])

    const onChangeSave = useCallback(
        (values: PointsValuesState) => form.setFieldValue('points', { ...form.values.points, ...values }),
        [form]
    )
    const resetPoints = useCallback(() => {
        form.setFieldValue('points', {})
        setHidden()
    }, [form, setHidden])

    const [gotLocation, setGotLocation] = useState<boolean>(false)
    const [position, setPosition] = useState<GeolocationCoordinates>({
        accuracy: 7093,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: 37.0311562,
        longitude: 37.3292757,
        speed: null,
    })
    const currentCoords = { lng: position.longitude, lat: position.latitude }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((p) => {
            setPosition(p.coords)
            setGotLocation(true)
        })
    }, [])

    if (!loadedPoints) {
        return <Loading />
    }

    return (
        <Dialog onClose={setHidden} open={visible} fullScreen TransitionComponent={SlideTransition}>
            <div className={classes.header}>
                <Typography className={classes.editPts}>Edit Points</Typography>
                <div className={classes.buttonsContainer}>
                    <Button onClick={resetPoints} style={{ margin: '0px 16px' }}>
                        Reset
                    </Button>

                    <Button onClick={setHidden} variant="contained" color="primary" className={classes.save}>
                        Save
                    </Button>
                </div>
            </div>

            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBgvyMxoLgmvzth-WHq2OubjI-DbQDCbto' }}
                defaultCenter={{ lat: position!.latitude, lng: position!.longitude }}
                defaultZoom={14}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: false,
                    clickableIcons: true,
                }}
            >
                {gotLocation ? <LocationMarker {...currentCoords} /> : null}

                {orderBy(loadedPoints.points, (x) => x.lat, ['desc']).map((point, idx) => {
                    const coords = { lat: point.lat, lng: point.lng }
                    const originalAmount = map(form.values.points).find((i) => {
                        return i.pointId == point._id
                    })?.amount

                    return (
                        <MapMarker
                            {...coords}
                            point={point}
                            key={idx}
                            theme={theme}
                            onChangeSave={onChangeSave}
                            originalAmount={originalAmount}
                        />
                    )
                })}
            </GoogleMapReact>
        </Dialog>
    )
}

const useStyles = makeStyles(() => ({
    container: {},
    header: {
        background: '#FFFFFF',
        width: '100vw',
        zIndex: 999,
        height: 60,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12,
        boxSizing: 'border-box',
        boxShadow: '0px 0px 1px rgba(44, 28, 13, 0.24), 0px 1px 4px rgba(44, 28, 13, 0.16)',
    },
    buttonsContainer: {
        display: 'flex',
    },
    save: {
        color: '#FFF',
        width: 120,
    },
    editPts: {
        fontWeight: 500,
    },
}))
