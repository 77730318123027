import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import React from 'react'

const SlideTransition = React.forwardRef(function SlideTransition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction={'up'} ref={ref} {...props} />
})

export default React.memo(SlideTransition)
