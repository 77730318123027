/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import {
    Column,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import { Dialog, IconButton, Paper, Theme, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiGiftOutline } from '@mdi/js'
import React, { useCallback, useMemo, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { PointType } from 'shared/Interfaces'
import { backgroundSelector } from '../../../utils/backgroundSelector'
import { colorSelector } from '../../../utils/colorSelector'
import { mdiIcon } from '../../../utils/mdiIcon'
import { CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from '../../Regions/tableUtils'
import PointDialog from './PointDialog'
import SlideTransition from './SlideTransition'

interface Props {
    points: PointType[]
    onPress: () => void
    state: any
    theme: Theme
}

export default React.memo(AllPoints)
const Gift = mdiIcon(mdiGiftOutline)

function AllPoints(props: Props) {
    const { state, onPress, points, theme } = props
    const classes = useStyles()

    const [viewPoint, setViewPoint] = useState<PointType | undefined>(undefined)

    const closePoint = useCallback(() => {
        setViewPoint(undefined)
    }, [])
    const tableRowComponent = useCallback((_props) => <CustomTableFilterRow {..._props} />, [])

    const columns: Column[] = useMemo(() => {
        return [
            {
                title: 'Title',
                name: 'title',
                getCellValue: (row: PointType) => {
                    return (
                        <div className={classes.titleContainer}>
                            {row.avatar ? (
                                <img className={classes.logo} src={row.avatar} />
                            ) : (
                                <div className={classes.giftBG}>
                                    <Gift className={classes.gift} />
                                </div>
                            )}

                            {row.title}
                        </div>
                    )
                },
            },
            { title: `Advertiser`, name: 'name', getCellValue: (row: PointType) => row.title },
            { title: `Code`, name: 'code', getCellValue: (row: PointType) => row.code },
            {
                title: `Category`,
                name: 'category',
                getCellValue: (row: PointType) => {
                    return (
                        <div
                            className={classes.categoryBG}
                            style={{ backgroundColor: backgroundSelector(row.category) }}
                        >
                            <Typography className={classes.category} style={{ color: colorSelector(row.category) }}>
                                {row.category}
                            </Typography>
                        </div>
                    )
                },
            },
            {
                title: 'Actions',
                name: 'actions',
                // eslint-disable-next-line react/display-name
                getCellValue: (row: PointType) => {
                    return (
                        // eslint-disable-next-line react/jsx-no-bind
                        <Button onClick={() => setViewPoint(row)} color="primary">
                            View
                        </Button>
                    )
                },
            },
        ]
    }, [classes.category, classes.categoryBG, classes.gift, classes.giftBG, classes.logo, classes.titleContainer])
    return viewPoint ? (
        <PointDialog originalPoint={viewPoint} theme={theme} state={!!viewPoint} onPress={closePoint} />
    ) : (
        <div className={classes.container}>
            <Dialog
                onClose={onPress}
                open={state}
                classes={{ paper: classes.dialogPaper, root: classes.dialogRoot }}
                fullScreen
                TransitionComponent={SlideTransition}
            >
                <div className={classes.header}>
                    <Typography className={classes.dialogTitle}>All Points</Typography>
                    <IconButton onClick={onPress}>
                        <MdClose />
                    </IconButton>
                </div>
                <div className={classes.body}>
                    <Paper className={classes.tableContainer}>
                        {/* @ts-ignore */}
                        <Grid rows={points} columns={columns}>
                            <SortingState />

                            <IntegratedSorting />

                            <PagingState defaultCurrentPage={0} pageSize={12} />
                            <IntegratedPaging />

                            <FilteringState />
                            <IntegratedFiltering />

                            <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                            <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                            <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                            <PagingPanel containerComponent={CustomPagingPanel} />
                            <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                        </Grid>
                    </Paper>
                </div>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: { position: 'relative', height: 0, width: 42, cursor: 'pointer' },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '24px 16px',
    },
    dialogRoot: {
        width: '100%',
        zIndex: 1299,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogPaper: {
        width: '100%',
        overflow: 'hidden',
    },
    tableContainer: {
        width: 800,
    },
    body: {
        height: 'calc(100% - 128px)',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
    logo: {
        borderRadius: '50%',
        height: 36,
        width: 36,
        userSelect: 'none',
        marginInlineEnd: 12,
    },
    gift: {
        fontSize: 20,
    },
    giftBG: {
        borderRadius: '50%',
        background: theme.palette.background.default,
        userSelect: 'none',
        marginInlineEnd: 12,
        display: 'flex',
        padding: 8,
    },
    categoryBG: { borderRadius: 4, marginRight: 16, marginTop: 4, width: 'fit-content' },
    category: { padding: '4px 12px', fontWeight: 400, fontSize: 14 },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}))
/* eslint-disable react/no-multi-comp */
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />
