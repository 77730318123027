import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import isAdmin from '../../config/isAdmin'
import { useUser } from '../UserProvider'
import { ADMIN_LAYOUT_ROUTES, ADVERTISER_LAYOUT_ROUTES, SUPER_ADMIN_LAYOUT_ROUTES } from './NavMenu/menuTabs'
import NavMenu from './NavMenu/NavMenu'

interface Props {
    children: any
}

export default React.memo(Layout)

function Layout(props: Props) {
    const { children } = props
    const notIncluded = location.pathname == '/payment-success' || location.pathname == '/payment-error'

    const classes = useStyles()

    const user = useUser()

    const isSuperAdmin = user.user?.superAdmin


    const tabs = isSuperAdmin ? SUPER_ADMIN_LAYOUT_ROUTES : isAdmin ? ADMIN_LAYOUT_ROUTES : ADVERTISER_LAYOUT_ROUTES

    return (
        <div className={classes.container}>
            {notIncluded ? null : <NavMenu tabs={tabs} />}
            <div className={classes.content}>{children}</div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
    },
    content: {
        flex: 1,
        display: 'flex',
    },
}))
