import { CircularProgress, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

interface Props {
    campaignsCount: number | undefined
}

export default React.memo(AdvertiserInfo)

function AdvertiserInfo(props: Props) {
    const { campaignsCount } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.offersAdsContainer}>
                <div className={classes.offersCards}>
                    <div className={classes.cardContent}>
                        <Typography className={classes.totalText}>Total Offers</Typography>
                        <Typography className={classes.totalCount}>{campaignsCount ?? <CircularProgress />}</Typography>
                    </div>
                </div>
            </div>

            <div className={classes.planCard} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    offersAdsContainer: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    offersCards: {
        border: `1px solid ${theme.palette.divider}`,
        height: 200,
        width: 160,
        borderRadius: 8,
    },
    adsCards: {
        border: `1px solid ${theme.palette.divider}`,
        height: 200,
        width: 160,
        borderRadius: 8,
        marginInlineStart: 40,
    },
    planCard: {},
    cardContent: {
        marginTop: 104,
        marginInlineStart: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    totalText: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    totalCount: {
        fontWeight: 500,
        fontSize: 24,
        color: theme.palette.text.primary,
    },
}))
