import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiCheckCircleOutline } from '@mdi/js'
import React from 'react'
import { mdiIcon } from '../../../utils/mdiIcon'

export default React.memo(SuccessMessage)
const Check = mdiIcon(mdiCheckCircleOutline)

function SuccessMessage() {
    const classes = useStyles()
    setTimeout(() => {
        window.parent.postMessage('done', process.env.PORTAL + '/subscriptions')
    }, 1000)
    return (
        <div className={classes.container} id="success">
            <Check className={classes.cross} />
            <Typography className={classes.success}>Payment Successful</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cross: { color: theme.palette.primary.main, fontSize: 40 },
    success: { color: theme.palette.text.primary, fontSize: 24, fontWeight: 500, marginTop: 16 },
}))
