import { useUser } from '../UserProvider'
import { AuthMode } from './RouteObj'

export default function useAuthMode(): AuthMode | 'loading' {
    const { user, loading } = useUser()

    if (loading) {
        return 'loading'
    }

    return user ? 'authenticated' : 'unauthenticated'
}
