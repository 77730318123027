import { extraBackgrounds } from '../containers/ThemeProvider'

const xColours = extraBackgrounds

export const backgroundSelector = (category: string) => {
    if (category === 'Malls') {
        return xColours.cyan
    } else if (category === 'General') {
        return xColours.purple
    } else if (category === 'Luxury') {
        return xColours.yellow
    } else if (category === 'Parks') {
        return xColours.green
    } else if (category === 'Beaches') {
        return xColours.red
    }
}
