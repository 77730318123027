import { IconButton, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'

interface Props {
    handleClose: () => void
    handleConfirm: () => void
    open: boolean
    itemName:string
}

export default React.memo(DeleteDialog)

function DeleteDialog(props: Props) {
    const { handleClose, handleConfirm, open,itemName } = props

    const classes = useStyles()

    return (
        <Dialog open={open} onClose={handleClose}>
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography className={classes.title}>Delete {itemName}</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>

                <div className={classes.item}>
                    <Typography className={classes.itemText}>Are you sure you want to delete this {itemName.toLowerCase()}?</Typography>
                </div>
                <div className={classes.dialogButtons}>
                    <Button onClick={handleClose} className={classes.cancelButton} variant="text">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} className={classes.deleteButton} variant="contained">
                        Delete {itemName}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 72,
        padding: '0px 16px',
        background: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 24,
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    item: {
        height: 56,
        margin: '0px 24px',
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 8px',
        transition: theme.transitions.create(['background']),
    },
    placeholder: {
        minHeight: 32,
    },
    itemText: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: `16px 24px`,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    deleteButton: {
        color: theme.palette.background.paper,
        background: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.dark,
        },
    },
}))
