import {
    Column,
    DataTypeProvider,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    SortingState,
} from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableColumnReordering,
    TableFilterRow,
    TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui'
import { CircularProgress, Paper, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiEarth } from '@mdi/js'
import React, { useCallback, useMemo } from 'react'
import { endUsers } from '../../API/endUsers'
import useAsync from '../../hooks/useAsync'
import { UserType } from 'shared/Interfaces'
import { mdiIcon } from '../../utils/mdiIcon'
import { CustomPagingPanel, CustomTableFilterRow, CustomTableHeaderRow } from '../Regions/tableUtils'

const Earth = mdiIcon(mdiEarth)

export default React.memo(EndUsersList)

function EndUsersList() {
    const [loadedUsers] = useAsync(() => endUsers.get({}))

    const classes = useStyles()

    const tableRowComponent = useCallback((_props) => <CustomTableFilterRow {..._props} />, [])

    const columns: Column[] = useMemo(() => {
        return [
            { title: 'User Id', name: 'id', getCellValue: (row: UserType) => row._id },
            { title: 'Phone Number', name: 'phoneNumber', getCellValue: (row: UserType) => row.phoneNumber },
            { title: 'Name', name: 'name', getCellValue: (row: UserType) => row.name },
        ]
    }, [])

    if (!loadedUsers?.users) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <Earth className={classes.earth} />
                    <Typography className={classes.title}>End Users</Typography>
                </div>

                <div className={classes.buttonContainer} />
            </div>

            <div className={classes.body}>
                <Paper className={classes.tableContainer}>
                    {/* @ts-ignore */}
                    <Grid rows={loadedUsers.users} columns={columns}>
                        <DataTypeProvider for={['endUsers']} formatterComponent={EndUserComponent} />

                        <SortingState />

                        <IntegratedSorting />

                        <PagingState defaultCurrentPage={0} pageSize={12} />
                        <IntegratedPaging />

                        <FilteringState />
                        <IntegratedFiltering />

                        <Table cellComponent={CellComponent} rowComponent={tableRowComponent} />
                        <TableHeaderRow showSortingControls rowComponent={CustomTableHeaderRow} />

                        <TableFilterRow rowComponent={tableRowComponent} showFilterSelector />
                        <PagingPanel containerComponent={CustomPagingPanel} />
                        <TableColumnReordering defaultOrder={columns.map((x) => x.name)} />
                    </Grid>
                </Paper>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    titleContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    buttonContainer: {},
    earth: {
        background: theme.palette.primary.main,
        padding: 8,
        borderRadius: 4,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        paddingTop: 24,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '16px 16px',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: '16px 24px',
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addEndUserButton: {
        color: theme.palette.background.paper,
    },
    tableContainer: {
        width: 800,
    },
    body: {
        height: 'calc(100% - 128px)',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
    endUser: {
        marginBottom: 16,
    },
    loading: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

/* eslint-disable react/no-multi-comp */
const EndUserComponent = ({ row }: any) => <span title={row.endUser}>{row.endUser}</span>
const CellComponent = (props: any) => <Table.Cell {...props} style={{ fontSize: 12 }} />
