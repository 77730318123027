/* eslint-disable react/jsx-no-bind */
import MomentUtils from '@date-io/moment'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FormikProps } from 'formik'
import moment from 'moment'
import React, { useCallback } from 'react'
import LargeImageUploadButton from '../../../components/LargeImageUploadButton'
import { CreateCampaignForm } from '../CreateCampaign'

interface Props {
    form: FormikProps<CreateCampaignForm>
    loading: boolean
    editing?: boolean
}

export default React.memo(EditCampaignInfo)

function EditCampaignInfo(props: Props) {
    const { form, loading, editing } = props

    const classes = useStyles()

    const onCollectTime = useCallback((v) => form.setFieldValue('collectTime', v?.toDate() || null), [form])

    const onUntilTime = useCallback((v) => form.setFieldValue('untilTime', v?.toDate() || null), [form])

    const onImage = useCallback((e) => form.setFieldValue('images', [...form.values.images, e]), [form])

    const removeImage = useCallback(
        (idx: number) => {
            form.setFieldValue('images', [...form.values.images.slice(0, idx), ...form.values.images.slice(idx + 1)])
        },
        [form]
    )

    return (
        <div className={classes.container}>
            <h2>Info</h2>

            <div className={classes.formContainer}>
                <div className={classes.topFormInputs}>
                    <TextField
                        disabled={loading}
                        className={classes.titleInput}
                        required
                        label="Title"
                        variant="filled"
                        color="primary"
                        value={form.values.title}
                        onChange={form.handleChange('title')}
                    />

                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel required id="demo-simple-select-label">
                            Type
                        </InputLabel>

                        <Select
                            disabled={loading}
                            value={form.values.type}
                            onChange={form.handleChange('type')}
                            variant="filled"
                            label="Type"
                            className={classes.typeSelect}
                        >
                            <MenuItem value="gift">Gift</MenuItem>
                            <MenuItem value="offer">Offer</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <TextField
                    disabled={loading}
                    id="filled-multiline-static"
                    label="Description"
                    multiline
                    required
                    rows={4}
                    variant="filled"
                    className={classes.descriptionInput}
                    classes={{ root: classes.inputRoot }}
                    value={form.values.description}
                    onChange={form.handleChange('description')}
                />

                <div className={classes.collectTimeContainer}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
                        <DateTimePicker
                            disabled={loading}
                            className={classes.textField}
                            inputVariant="filled"
                            format="DD/MM/YYYY HH:mm"
                            required
                            value={form.values.collectTime ?? null}
                            onChange={onCollectTime}
                            label={'From'}
                        />
                    </MuiPickersUtilsProvider>

                    <div className={classes.divider} />

                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'en'}>
                        <DateTimePicker
                            disabled={loading}
                            className={classes.textField}
                            inputVariant="filled"
                            format="DD/MM/YYYY HH:mm"
                            required
                            value={form.values.untilTime ?? null}
                            onChange={onUntilTime}
                            label={'To'}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                <div className={classes.imagesSelectContainer}>
                    <div className={classes.imagesContainer}>
                        {form.values.images.map((img, idx) => {
                            return (
                                <div className={classes.imgContainer} key={idx}>
                                    <img className={classes.img} src={editing && typeof img === 'string' ? img : URL.createObjectURL(img as File)} />

                                    <IconButton
                                        onClick={() => removeImage(idx)}
                                        disabled={loading}
                                        className={classes.removeIcon}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            )
                        })}
                    </div>

                    <LargeImageUploadButton onImage={onImage} loading={loading} />

                    <div className={classes.placeholder} />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    formContainer: {},
    titleInput: {
        display: 'flex',
        flex: 1,
        background: 'transparent',
    },
    topFormInputs: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    },
    typeSelect: {
        display: 'flex',
        minWidth: 200,
    },
    formControl: {
        display: 'flex',
        marginLeft: 12,
        minWidth: 200,
    },
    descriptionInput: {
        marginTop: 24,
        flex: 1,
        display: 'flex',
    },
    textField: {
        flex: 1,
        display: 'flex',
    },
    collectTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 24,
    },
    divider: {
        display: 'flex',
        minWidth: 20,
    },
    imagesSelectContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 32,
    },
    inputRoot: {
        backgroundColor: '#FFF',
    },
    imagesContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 8,
        maxWidth: 640,
    },
    imgContainer: {
        position: 'relative',
        borderRadius: 4,
    },
    img: {
        maxWidth: 200,
        borderRadius: 4,
    },
    placeholder: {
        minHeight: 64,
    },
    removeIcon: {
        height: 32,
        width: 32,
        position: 'absolute',
        top: 8,
        right: 16,
        background: theme.palette.text.primary,
        color: '#fff',
        fontSize: 20,
    },
}))
