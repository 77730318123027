import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { CampaignType } from 'shared/Interfaces'

interface Props {
    campaign: CampaignType | undefined
}

export default React.memo(CampaignAudienceView)

function CampaignAudienceView(props: Props) {
    const { campaign } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.placeholder} />

            <Typography className={classes.title}>{'From Age'}</Typography>
            <Typography className={classes.value}>{campaign?.filters.ageFrom ?? '...'}</Typography>

            <Typography className={classes.title}>{'Until Age'}</Typography>
            <Typography className={classes.value}>{campaign?.filters.ageUntil ?? '...'}</Typography>

            <Typography className={classes.title}>{'Gender'}</Typography>
            <Typography className={classes.value}>{campaign?.filters.gender ?? '...'}</Typography>

            <div className={classes.placeholder} />
            <div className={classes.placeholder} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.text.hint,
        marginBottom: 8,
    },
    value: {
        fontSize: 16,
        color: theme.palette.text.primary,
        marginBottom: 32,
    },
    placeholder: {
        minHeight: 24,
    },
}))
