import { Divider, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { PlanType } from 'shared/Interfaces'
import assets from '../../../assets/assets'
import { currencySymbol } from './../PlanCard'

interface Props {
    plan: PlanType
}

export default React.memo(PlanInfo)

function PlanInfo(props: Props) {
    const { plan } = props
    const { cost, currency, numberOfOffers, paymentDue, title, unlimitedOffers } = plan

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <img src={assets.Plans} width={64} />
            </div>
            <Typography className={classes.planTitle}>{title}</Typography>
            <Typography className={classes.planPrice}>
                {currencySymbol(currency)} {cost}
            </Typography>
            <Divider className={classes.divider} />
            <Typography className={classes.offersCount}>
                {unlimitedOffers ? 'Unlimited' : numberOfOffers} Offers
            </Typography>

            <Typography className={classes.paymentPeriod}>{paymentDue} Payment</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        padding: 24,
    },
    planTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginTop: 40,
    },
    planPrice: {
        fontWeight: 700,
        fontSize: 28,
        color: theme.palette.text.primary,
    },
    divider: {
        marginTop: 24,
        width: 208,
    },
    offersCount: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
        marginTop: 24,
    },
    paymentPeriod: {
        marginTop: 12,

        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    typeInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: theme.palette.background.default,
        height: 32,
    },
    audience: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        letterSpacing: 0.4,
    },
    earth: {
        color: theme.palette.text.secondary,
        height: 16,
        fontSize: 16,
        marginInline: 4,
    },
    offersStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    paymentStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 12,
    },
    star: {
        height: 16,

        color: theme.palette.divider,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    header: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },
}))
