/* eslint-disable react/no-multi-comp */
import { DragDropProvider, PagingPanel, Table, TableHeaderRow, Toolbar } from '@devexpress/dx-react-grid-material-ui'
import React from 'react'

export const CustomTableHeaderRow = (props: Table.RowProps) => (
    <TableHeaderRow.Row {...props} style={{ backgroundColor: '#ECEEEF' }} />
)

export const CustomDragComponent = (props: any) => (
    <DragDropProvider.Container {...props} style={{ backgroundColor: '#FFFFFF' }} />
)

export const CustomPagingPanel = (props: any) => (
    <PagingPanel.Container {...props} style={{ backgroundColor: '#FFFFFF', justifyContent: 'space-between' }} />
)

export const CustomTableFilterRow = ({ children, tableRow }: any) => {
    const props = { children, tableRow }
    return <Table.Row {...props} row={tableRow} />
}

export const CustomToolbar = ({ children }: Toolbar.RootProps) => {
    const props = { children }
    return <Toolbar.Root {...props} style={{ backgroundColor: '#FFFFFF' }} />
}
