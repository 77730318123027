import {
    AddRegionArgs,
    AddRegionResponse,
    GetRegionArgs,
    GetRegionByIdArgs,
    GetRegionByIdResponse,
    GetRegionResponse,
} from 'shared/types/regionTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const regions = {
    add: (args: AddRegionArgs) => sendRequest<AddRegionResponse>({ endpoint: serverEndPoints.regions.add, args }),
    get: (args: GetRegionArgs) =>
        sendRequest<GetRegionResponse>({ endpoint: serverEndPoints.regions.get, args, method: 'get' }),
    getById: (args: GetRegionByIdArgs) =>
        sendRequest<GetRegionByIdResponse>({ endpoint: serverEndPoints.regions.getById, args, method: 'post' }),
}
