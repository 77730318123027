import { Button, ButtonBase, Dialog, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsHorizontal, mdiEarth } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { MdLockOutline, MdStar } from 'react-icons/md'
import { PlanType, SubscriptionType } from 'shared/Interfaces'
import { subscriptions } from '../../API/subscriptions'
import assets from '../../assets/assets'
import { useAlert } from '../../containers/AlertProvider'
import { useUser } from '../../containers/UserProvider'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import GenericDialog from './GenericDialog'
import ChangePaymentDialog from './Payments/ChangePaymentDialog'
import PaymentDialog from './Payments/PaymentDialog'

interface Props {
    plan: PlanType
    current?: SubscriptionType
    subbedToPlan?: boolean
    planRemover?: (_id: string) => void
}
export default React.memo(PlanCard)
const Earth = mdiIcon(mdiEarth)
const Dots = mdiIcon(mdiDotsHorizontal)

function PlanCard(props: Props) {
    const { plan, current } = props

    const alert = useAlert()
    const classes = useStyles()
    const { cost, currency, numberOfOffers, paymentDue, title, type, unlimitedOffers, subscribers, _id, active } = plan
    const [activeState, setActiveState] = useState(active)
    const [cancelState, setCancelState] = useState(current?.recurring)

    const subs = subscribers ? subscribers : 0
    const user = useUser().user
    const isAdmin = user?.permissions.includes('manageSubscriptions') || user?.superAdmin
    const [activationState, openActivation, closeActivation] = useBoolean()
    const [cancellationState, openCancellation, closeCancellation] = useBoolean()
    const [paymentState, openPayment, closePayment] = useBoolean()
    const [changeState, openChange, closeChange] = useBoolean()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const DeReActivatePlan = useCallback(async () => {
        await subscriptions
            .DeReActivateSub({ _id, status: activeState, type: 'plan' })
            .then((x) => {
                if (x[1]?.msg == 'success') {
                    closeActivation()
                    handleCloseMenu()
                    setTimeout(() => {
                        setActiveState(!activeState)
                        alert('success', `Plan ${activeState ? 'Rectivated' : 'Decativated'} Successfully`)
                    }, 1000)
                } else {
                    alert('error', `Failed to ${activeState ? 'Reactivate' : 'Decativate'} Plan`)
                    closeActivation()
                    handleCloseMenu()
                }
            })
            .catch(() => {
                alert('error', `Failed to ${activeState ? 'Reactivate' : 'Decativate'} Plan`)
                closeActivation()
                handleCloseMenu()
            })
    }, [_id, activeState, alert, closeActivation, handleCloseMenu])
    const cancelSubscription = useCallback(async () => {
        await subscriptions
            .cancelSubcription({ _id: current!._id, status: !cancelState })
            .then((x) => {
                if (x[1]?.msg == 'success') {
                    closeCancellation()
                    handleCloseMenu()
                    setTimeout(() => {
                        setCancelState(!cancelState)
                        alert('success', `Success`)
                    }, 1000)
                } else {
                    closeCancellation()
                    handleCloseMenu()
                    alert('error', `Failed`)
                }
            })
            .catch(() => {
                alert('error', `Failed`)
                closeCancellation()
                handleCloseMenu()
            })
    }, [alert, cancelState, closeCancellation, current, handleCloseMenu])
    const closePaymentDialog = useCallback(() => {
        closePayment()
        setAnchorEl(null)
    }, [closePayment])
    const closeChangeDialog = useCallback(() => {
        closeChange()
        setAnchorEl(null)
    }, [closeChange])

    return (
        <div className={classes.container}>
            <Dialog onClose={closePaymentDialog} open={paymentState} classes={{ paper: classes.dialog }}>
                <PaymentDialog close={closePaymentDialog} plan={plan} />
            </Dialog>
            <Dialog onClose={closeChangeDialog} open={changeState} classes={{ paper: classes.dialog }}>
                <ChangePaymentDialog close={closeChangeDialog} plan={plan} current={current!} />
            </Dialog>
            <GenericDialog
                handleClose={closeActivation}
                action={activeState ? 'Deactivate' : 'Recativate'}
                description={`Are you sure you want to ${activeState ? 'deactivate' : 'recativate'} this plan?`}
                handleConfirm={DeReActivatePlan}
                open={activationState}
            />
            <GenericDialog
                handleClose={closeCancellation}
                action={cancelState ? 'Cancel Plan' : 'Renew Plan'}
                description={`Are you sure you want to ${cancelState ? 'cancel' : 'renew'} your subscription?`}
                additionalInfo={
                    cancelState
                        ? `Your subscription will be canceled at the end of your billing period. You can change your mind any time vefore this date.`
                        : `You won't be charged until your next billing period.`
                }
                handleConfirm={cancelSubscription}
                open={cancellationState}
            />

            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                {isAdmin ? (
                    <MenuItem onClick={openActivation}>
                        {activeState ? <Typography>Deactivate</Typography> : <Typography>Reactivate</Typography>}
                    </MenuItem>
                ) : (
                    <>
                        <MenuItem onClick={openCancellation}>
                            {cancelState ? <Typography>Cancel</Typography> : <Typography>Renew</Typography>}
                        </MenuItem>

                        <MenuItem onClick={openChange}>
                            <Typography>Change Payment Method</Typography>
                        </MenuItem>
                    </>
                )}
            </Menu>
            <div className={classes.mainInfo}>
                <div className={classes.header}>
                    <img src={assets.Plans} width={64} />

                    {isAdmin || !!current ? (
                        <ButtonBase onClick={handleClick} className={classes.menuButton}>
                            <Dots />
                        </ButtonBase>
                    ) : null}
                </div>
                <Typography className={classes.planTitle}>{title}</Typography>
                <Typography className={classes.planPrice}>
                    {currencySymbol(currency)} {cost}
                </Typography>
                {current ? (
                    <Typography className={cancelState ? classes.dueDate : classes.cancelDate}>
                        {cancelState ? 'Active' : 'To Be Canceled'}
                    </Typography>
                ) : null}
                <Divider className={current ? classes.dividerCurrent : classes.divider} />
                <div className={classes.offersStar} style={isAdmin ? { marginTop: 40 } : { marginTop: 24 }}>
                    <Typography className={classes.offersCount}>
                        {unlimitedOffers ? 'Unlimited' : numberOfOffers} Offers
                    </Typography>
                    <MdStar className={classes.star} />
                </div>
                <div className={classes.paymentStar}>
                    <Typography className={classes.paymentPeriod}>{paymentDue} Payment</Typography>
                    <MdStar className={classes.star} />
                </div>
                {isAdmin ? null : (
                    <Button
                        disabled={!!current}
                        className={classes.buyButton}
                        variant="contained"
                        onClick={openPayment}
                    >
                        {current ? `Current` : `Buy`}
                    </Button>
                )}
            </div>
            {isAdmin ? (
                <div className={classes.typeInfo}>
                    {type == 'public' ? (
                        <Earth className={classes.earth} />
                    ) : (
                        <MdLockOutline className={classes.earth} />
                    )}

                    <Typography className={classes.audience}>
                        {type == 'public' ? `${subs} subscribers` : `Private`}
                    </Typography>
                </div>
            ) : null}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: 360,
        minWidth: 238,
        display: 'flex',
        flexDirection: 'column',
        marginInlineEnd: 40,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
    },
    mainInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: `16px 16px 10px 16px`,
    },
    planTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginTop: 40,
    },
    dueDate: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.success.main,
        marginTop: 8,
    },
    cancelDate: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.error.main,
        marginTop: 8,
    },
    planPrice: {
        fontWeight: 700,
        fontSize: 28,
        color: theme.palette.text.primary,
    },
    divider: {
        marginTop: 40,
    },
    dividerCurrent: {
        marginTop: 8,
    },
    offersCount: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    paymentPeriod: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    typeInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: theme.palette.background.default,
        height: 32,
    },
    audience: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        letterSpacing: 0.4,
    },
    earth: {
        color: theme.palette.text.secondary,
        height: 16,
        fontSize: 16,
        marginInline: 4,
    },
    offersStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    paymentStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 12,
    },
    star: {
        height: 16,

        color: theme.palette.divider,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuButton: { alignSelf: 'flex-start' },
    buyButton: { marginTop: 8 },
    dialog: {
        maxWidth: 960,
        width: '100%',
        height: 560,
    },
}))
export function currencySymbol(cur: string) {
    if (cur == 'USD') {
        return '$'
    } else if (cur == 'EUR') {
        return '€'
    } else if (cur == 'TRY') {
        return '₺'
    }
}
