import { Avatar, Dialog, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiLogout } from '@mdi/js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiFillShop } from 'react-icons/ai'
import { MdKeyboardArrowUp, MdPerson } from 'react-icons/md'
import useReactRouter from 'use-react-router'
import logo from '../../../assets/Carrot.png'
import useBoolean from '../../../hooks/useBoolean'
import { mdiIcon } from '../../../utils/mdiIcon'
import { useUser } from '../../UserProvider'
import ChangePasswordDialog from './ChangePasswordDialog'
import { MenuTab } from './menuTabs'
import NavMenuItem from './NavMenuItem'
import ProfileDialog from './ProfileDialog'

const Logout = mdiIcon(mdiLogout)

interface Props {
    tabs: MenuTab[]
}

export const HIDE_MENU_ROUTES = ['create-campaigns']

export default React.memo(NavMenu)

function NavMenu(props: Props) {
    const { tabs } = props

    const classes = useStyles()
    const user = useUser()!
    const permissions = user.user?.permissions
    const { history, location } = useReactRouter()
    const currentLocation = location.pathname.replace('/', '').trim()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = useCallback((e) => setAnchorEl(e.currentTarget), [])
    const handleCloseMenu = useCallback(() => setAnchorEl(null), [])
    const [avatar, setAvatar] = useState(user.user?.avatar)

    const setNewAvatar = useCallback((newAvatar: string) => {
        setAvatar(newAvatar)
    }, [])

    const filteredTabs = useMemo(
        () => tabs.filter((tab) => tab.roles.some((r) => permissions?.includes(r))),
        [permissions, tabs]
    )
    useEffect(() => {
        if (filteredTabs[0]?.path && location.pathname === '/') {
            history.push(filteredTabs[0].path!)
        }
    }, [filteredTabs, history, location.pathname, tabs])

    const [profile, openProfile, closeProfile] = useBoolean()
    const [changePassword, openChangePassword, closeChangePassword] = useBoolean()

    const logout = useCallback(() => {
        webLogout().then(() => {
            setTimeout(() => {
                window.location.reload()
            }, 500)
        })
    }, [])

    const onProfile = useCallback(() => {
        handleCloseMenu()
        openProfile()
    }, [handleCloseMenu, openProfile])

    if (HIDE_MENU_ROUTES.some((x) => x === currentLocation)) {
        return null
    }

    return (
        <div className={classes.container}>
            <Dialog onClose={closeChangePassword} open={changePassword} classes={{ paper: classes.passwordDialog }}>
                <ChangePasswordDialog close={closeChangePassword} openProfile={openProfile} />
            </Dialog>
            <Dialog onClose={closeProfile} open={profile} classes={{ paper: classes.dialog }}>
                <ProfileDialog
                    close={closeProfile}
                    openPassword={openChangePassword}
                    setNewAvatar={setNewAvatar}
                    currentAvatar={avatar}
                />
            </Dialog>
            <div className={classes.content}>
                <img src={logo} className={classes.logo} />

                <div className={classes.tabsContainer}>
                    {filteredTabs.map((tab, idx) => {
                        if (tab.roles.includes('manageAgencyUsers') && user.user?.userType != 'agent') {
                            return
                        }
                        if (tab.roles.includes('manageAdminUsers') && user.user?.userType != 'admin') {
                            return
                        }
                        return <NavMenuItem idx={idx} tab={tab} key={idx} />
                    })}
                </div>

                <div className={classes.userInfo}>
                    <div className={classes.userInfoItems}>
                        {user?.user?.avatar ? (
                            <Avatar className={classes.userAvatar} src={avatar} />
                        ) : (
                            <div className={classes.AiFillShopContainer}>
                                <AiFillShop color="#FFF" size={22} />
                            </div>
                        )}

                        <div className={classes.infoContainer}>
                            <Typography className={classes.nameTypography}>{user?.user?.name ?? ''}</Typography>
                            <Typography className={classes.typeTypography}>
                                {user?.user?.userType.toUpperCase()}
                            </Typography>
                        </div>
                    </div>

                    <IconButton onClick={handleClick}>
                        <MdKeyboardArrowUp />
                    </IconButton>

                    <Menu
                        getContentAnchorEl={null}
                        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                        className={classes.menu}
                    >
                        <MenuItem onClick={onProfile}>
                            <MdPerson className={classes.logout} />
                            <Typography>Profile</Typography>
                        </MenuItem>
                        <MenuItem onClick={logout}>
                            <Logout className={classes.logout} />
                            <Typography>Logout</Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: 288,
        background: theme.palette.background.default,
        maxWidth: 288,
        minWidth: 224,
        borderRight: `1px solid ${theme.palette.divider}`,
        userSelect: 'none',
        display: 'flex',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderLeft: theme.palette.divider,
        flex: 1,
    },
    logo: {
        width: 108,
        pointerEvents: 'none',
        userSelect: 'none',
        margin: 24,
        marginBottom: 40,
    },
    avatar: {
        marginBottom: 16,
    },
    title: {
        fontWeight: 500,
        color: theme.palette.text.primary,
        fontSize: 16,
        marginBottom: 4,
    },
    address: {
        color: theme.palette.text.secondary,
        fontSize: 11,
        marginBottom: 16,
    },
    tabsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    userContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    userAvatar: {
        height: 40,
        width: 40,
        marginRight: 2,
        borderRadius: 8,
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    name: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    email: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        fontWeight: 500,
    },
    settingsButton: {
        height: 40,
        width: 40,
        padding: 0,
        fontSize: 22,
    },
    menuContent: {
        width: 200,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
    },
    userInfo: {
        width: 'calc(100% - 32px)',
        alignItems: 'center',
        display: 'flex',
        margin: '0px 16px',
        borderTop: `1px solid ${theme.palette.divider}`,
        marginBottom: 16,
        paddingTop: 8,
    },
    userInfoItems: {
        width: '100%',
        display: 'flex',
    },
    AiFillShopContainer: {
        width: 40,
        height: 40,
        borderRadius: 4,
        background: theme.palette.text.primary,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 8,
        marginRight: 8,
    },
    nameTypography: {
        display: 'flex',
        fontSize: 13,
        fontWeight: 500,
    },
    typeTypography: {
        display: 'flex',
        fontSize: 11,
        color: theme.palette.text.hint,
    },
    logout: {
        fontSize: 20,
        marginRight: 8,
    },
    menu: {
        width: 240,
    },
    dialog: {
        width: 480,
        height: 560,
        padding: 16,
    },
    passwordDialog: {
        width: 480,
        height: 352,
        padding: 16,
    },
}))

const webLogout = async () => window.localStorage.clear()
