import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import { FormikProps } from 'formik'
import React, { useCallback } from 'react'
import assets from '../../../assets/assets'
import { useAlert } from '../../../containers/AlertProvider'
import { CreateCampaignForm } from '../CreateCampaign'

const MAX_SIZE = 10 * 1024 * 1024

interface Props {
    form: FormikProps<CreateCampaignForm>
    loading: boolean
    editing?: boolean
}

export default React.memo(EditCampaignADVideo)

function EditCampaignADVideo(props: Props) {
    const { form, loading, editing } = props

    const classes = useStyles()
    const alert = useAlert()

    const onChange = useCallback(
        async (e) => {
            const file = e.target.files[0]


            if (file.size > MAX_SIZE) {
                alert('error', 'Max File Size is 10MB')
                return
            }

            const videoDuration = await loadVideo(file) as { duration: number }

            if (videoDuration.duration > 30) {
                alert('error', 'Max video duration is 30 second')
                return
            }




            form.setFieldValue('adsVideo', e.target.files[0])
            e.target.value = null
        },
        [alert, form]
    )

    const video = form.values.adsVideo && !editing ? URL.createObjectURL(form.values.adsVideo as any as Blob) : form.values.adsVideo

    const clear = useCallback(() => form.setFieldValue('adsVideo', undefined), [form])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h2 className={classes.tabTitle}>Ad Video</h2>
                {video ? (
                    <IconButton disabled={loading} onClick={clear}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </div>

            <input hidden accept="video/*" type="file" id="upload-file" onChange={onChange} />

            {video ? <video className={classes.video} src={video} controls /> : null}

            {video ? null : (
                <label htmlFor={'upload-file'} className={classes.uploadLabel}>
                    <div className={classes.videoContainer}>
                        <img src={assets.video} />
                        <Typography className={classes.dropDescription}>
                            Add video ad to highlight your product
                        </Typography>

                        <a href="#" className={classes.browseFile}>
                            Browse Files
                        </a>
                    </div>
                </label>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tabTitle: {
        color: theme.palette.text.primary,
    },
    videoContainer: {
        marginTop: 24,
        backgroundColor: '#F9F9FA',
        cursor: 'pointer',
        border: '1px dashed #DEE1E3',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: 640,
        height: 320,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropDescription: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginTop: 12,
    },
    browseFile: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        marginTop: 16,
    },
    uploadLabel: {},
    video: {
        width: '100%',
    },
}))


const loadVideo = (file: File) => new Promise((resolve, reject) => {
    try {
        const video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
    } catch (e) {
        reject(e)
    }
})