import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiCloseCircleOutline } from '@mdi/js'
import React from 'react'
import { mdiIcon } from '../../../utils/mdiIcon'

export default React.memo(ErrorMessage)
const Cross = mdiIcon(mdiCloseCircleOutline)
function ErrorMessage() {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Cross className={classes.cross} />
            <Typography className={classes.redeem}>Payment Failed</Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cross: { color: theme.palette.error.main, fontSize: 40 },
    redeem: { color: theme.palette.text.primary, fontSize: 24, fontWeight: 500, marginTop: 16 },
}))
