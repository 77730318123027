import { Button, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiCheckCircleOutline } from '@mdi/js'
import React from 'react'
import { mdiIcon } from '../../utils/mdiIcon'

interface Props {
    close: () => void
}

export default React.memo(SuccessDialog)
const Check = mdiIcon(mdiCheckCircleOutline)
function SuccessDialog(props: Props) {
    const { close } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <Check className={classes.check} />
                <Typography className={classes.redeem}>Redeemed Successfully</Typography>
            </div>
            <Button className={classes.done} variant="contained" color="primary" onClick={close}>
                Done
            </Button>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    content: {
        height: '100%',
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    check: { color: theme.palette.primary.main, fontSize: 40 },
    redeem: { color: theme.palette.text.primary, fontSize: 24, fontWeight: 500, marginTop: 16 },
    done: {
        maxWidth: 352,
        width: '100%',
        color: theme.palette.background.paper,
        margin: 24,
    },
}))
