import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiArrowRight } from '@mdi/js'
import { useFormik } from 'formik'
import React from 'react'
import { SavedCardsType, SubscriptionType } from 'shared/Interfaces'
import { subscriptions } from '../../../API/subscriptions'
import { useAlert } from '../../../containers/AlertProvider'
import { mdiIcon } from '../../../utils/mdiIcon'
import { currencySymbol } from '../PlanCard'
import getCardIssuer from './getCardIssuer'

interface Props {
    goToNew: () => void
    type: 'plan' | 'package'
    price: number | undefined
    currency: 'USD' | 'TRY' | 'EUR' | undefined
    _id: string
    setUrl: (url: string) => void
    savedCards: SavedCardsType[]
    current?: SubscriptionType
    closeSelf: () => void
}

export default React.memo(SavedPaymentInfo)
const Arrow = mdiIcon(mdiArrowRight)

function SavedPaymentInfo(props: Props) {
    const { goToNew, _id, currency, price, type, savedCards, current, closeSelf } = props
    const alert = useAlert()

    const classes = useStyles()
    const formik = useFormik({
        initialValues: {
            authorised: true,

            cardId: current?.cardId ?? '',
        },
        onSubmit: async (values) => {
            if (current) {
                const [err, res] = await subscriptions.ChangeSavedCard({
                    _id: values.cardId,
                    subId: current._id,
                })
                if (!res) {
                    console.error(err)
                    return
                }

                if (res.msg) {
                    formik.resetForm()
                    alert('success', 'Card Changed Successfully!')
                    closeSelf()
                } else {
                    alert('error', 'Error changing card!')
                }

                return
            }
            const [err, res] = await subscriptions.buySavedCard({
                _id,
                cardId: values.cardId,
                type,
                active: '',
            })
            if (!res || err) {
                alert('error', 'Error buying package!')
                return
            }

            if (res.Sonuc == 1) {
                alert('success', 'Purchased Successfully!')

                setTimeout(() => {
                    location.reload()
                }, 1000)
            } else {
                alert('error', 'Error buying package!')
            }
            return
        },
    })
    return (
        <div className={classes.container}>
            <Typography className={classes.existing}>Choose existing account</Typography>
            <FormControl fullWidth>
                <RadioGroup
                    style={{ overflowY: 'auto', flexFlow: 'wrap', maxHeight: 290 }}
                    value={formik.values.cardId}
                    onChange={formik.handleChange('cardId')}
                >
                    {savedCards.map((c, idx) => {
                        const checked = formik.values.cardId == c._id
                        const issuer = getCardIssuer(c.cardType)
                        return (
                            <FormControlLabel
                                key={idx}
                                value={c._id}
                                control={<Radio color="primary" className={classes.checked} />}
                                label={
                                    <div className={classes.cardLabel}>
                                        <img height={32} src={issuer.logo} />
                                        <div className={classes.cardDescription}>
                                            <Typography className={classes.info}>
                                                Card ending with {c.last4Digits}
                                            </Typography>
                                            <Typography className={classes.type}>{issuer.name}</Typography>
                                        </div>
                                    </div>
                                }
                                labelPlacement="start"
                                className={checked ? classes.savedCardFormChecked : classes.savedCardFormControl}
                            />
                        )
                    })}
                </RadioGroup>
                <div className={classes.goToOld} onClick={goToNew}>
                    <Typography className={classes.back}>New payment method</Typography>
                    <Arrow className={classes.arrow} />
                </div>
            </FormControl>
            <div className={classes.footer}>
                <div />
                <div className={classes.totalPay}>
                    {current ? null : <Typography className={classes.total}>Total</Typography>}
                    {current ? null : (
                        <Typography className={classes.price}>
                            {currencySymbol(currency!)}
                            {price}
                        </Typography>
                    )}
                    <Button
                        className={classes.payButton}
                        variant="contained"
                        color="primary"
                        disabled={!formik.values.authorised}
                        onClick={formik.submitForm}
                    >
                        {current ? `Change` : `Pay Now`}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        userSelect: 'none',
        position: 'absolute',
        bottom: 0,
    },
    goToOld: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            '& $arrow': {
                color: theme.palette.primary.dark,
            },
            '& $back': {
                color: theme.palette.primary.dark,
            },
        },
    },
    arrow: {
        height: 20,
        color: theme.palette.primary.main,
        marginInlineStart: 8,
        transition: theme.transitions.create('color'),
    },
    back: {
        transition: theme.transitions.create('color'),
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
    },
    existing: {
        marginTop: 16,
        marginBottom: 8,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: 500,
    },
    totalPay: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    total: {
        color: theme.palette.text.hint,
        fontSize: 16,
        fontWeight: 500,
        marginInlineEnd: 8,
    },
    price: {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 500,
        marginInlineEnd: 16,
    },

    payButton: {
        color: theme.palette.background.paper,
    },
    checked: { marginInlineEnd: 8 },
    savedCardFormControl: {
        width: 'calc(100% - 2px)',
        justifyContent: 'space-between',
        margin: 0,
        height: 64,
        background: theme.palette.background.default,
        borderRadius: 8,
        marginBottom: 8,
        border: `1px solid ${theme.palette.background.default}`,
        transition: theme.transitions.create('border'),
    },
    savedCardFormChecked: {
        width: 'calc(100% - 2px)',
        justifyContent: 'space-between',
        margin: 0,
        height: 64,
        background: theme.palette.background.default,
        borderRadius: 8,
        marginBottom: 8,
        transition: theme.transitions.create('border'),
        border: `1px solid ${theme.palette.primary.main}`,
    },
    cardLabel: { marginInlineStart: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' },
    cardDescription: { marginInlineStart: 16, display: 'flex', flexDirection: 'column' },
    info: { color: theme.palette.text.hint, fontSize: 14, fontWeight: 500 },
    type: { color: theme.palette.text.primary, fontSize: 12, fontWeight: 400 },
}))
