import { Button, TextField, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import { MdClose } from 'react-icons/md'
import * as yup from 'yup'
import { auth } from '../../../API/auth'
import useBoolean from '../../../hooks/useBoolean'
import { mdiIcon } from '../../../utils/mdiIcon'
import { useAlert } from '../../AlertProvider'

const EyeOn = mdiIcon(mdiEyeOutline)
const EyeOff = mdiIcon(mdiEyeOffOutline)
interface Props {
    close: () => void
    openProfile: () => void
}

export default React.memo(ChangePasswordDialog)

function ChangePasswordDialog(props: Props) {
    const { close, openProfile } = props
    const classes = useStyles()
    const alert = useAlert()
    const [visibleCurrent, setVisibleCurrent, setCurrentHidden] = useBoolean(false)
    const [visibleNew, setVisibleNew, setNewHidden] = useBoolean(false)
    const [visibleConfirm, setVisibleConfirm, setConfirmHidden] = useBoolean(false)

    const [loading, setLoading, closeLoading] = useBoolean()

    const formik = useFormik({
        initialValues: {
            current: '',
            new: '',
            confirm: '',
        },
        validationSchema: passwordSchema,
        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await auth.changePasswordConfirm({
                newPassword: values.new,
                oldPassword: values.current,
            })

            if (err) {
                closeLoading()
                alert('error', 'Error changing password')
                return
            }
            if (res?.msg == 'wrongPass') {
                closeLoading()
                alert('error', 'Current password is incorrect')
                return
            }

            closeLoading()
            alert('success', 'Changed Password Successfully')
            openProfile()
            close()
        },
    })
    const closeDialog = useCallback(() => {
        openProfile()
        close()
    }, [close, openProfile])
    
    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>Change Password</Typography>
                <IconButton onClick={closeDialog}>
                    <MdClose />
                </IconButton>
            </div>

            <div className={classes.dialogContent}>
                <div className={classes.adminInfo}>
                    <TextField
                        style={{
                            marginTop: 8,
                        }}
                        disabled={loading}
                        className={classes.passwordField}
                        label="Current Password"
                        type={visibleCurrent ? 'text' : 'password'}
                        variant="filled"
                        onChange={formik.handleChange('current')}
                        value={formik.values.current}
                        error={formik.touched.current && Boolean(formik.errors.current)}
                        helperText={formik.touched.current && formik.errors.current}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={visibleCurrent ? setCurrentHidden : setVisibleCurrent}>
                                    {visibleCurrent ? <EyeOn /> : <EyeOff />}
                                </IconButton>
                            ),
                        }}
                    />
                    <TextField
                        disabled={loading}
                        className={classes.passwordField}
                        label="New Password"
                        type={visibleNew ? 'text' : 'password'}
                        variant="filled"
                        onChange={formik.handleChange('new')}
                        value={formik.values.new}
                        error={formik.touched.new && Boolean(formik.errors.new)}
                        helperText={formik.touched.new && formik.errors.new}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={visibleNew ? setNewHidden : setVisibleNew}>
                                    {visibleNew ? <EyeOn /> : <EyeOff />}
                                </IconButton>
                            ),
                        }}
                    />
                    <TextField
                        disabled={loading}
                        className={classes.passwordField}
                        label="Confirm New Password"
                        type={visibleConfirm ? 'text' : 'password'}
                        variant="filled"
                        onChange={formik.handleChange('confirm')}
                        value={formik.values.confirm}
                        error={formik.touched.confirm && Boolean(formik.errors.confirm)}
                        helperText={formik.touched.confirm && formik.errors.confirm}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={visibleConfirm ? setConfirmHidden : setVisibleConfirm}>
                                    {visibleConfirm ? <EyeOn /> : <EyeOff />}
                                </IconButton>
                            ),
                        }}
                    />
                </div>

                <div className={classes.dialogButtons}>
                    <form onSubmit={formik.handleSubmit}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button
                                    onClick={closeDialog}
                                    className={classes.cancelButton}
                                    variant="text"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.addRegionButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    Change Password
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 240,
        height: 320,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,

        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fullName: {
        marginTop: 16,
        fontWeight: 500,
        fontSize: 14,
    },
    region: {
        marginTop: 4,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
    },
    button: {
        width: '100%',
    },
    avatar: {
        height: 128,
        width: 128,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    changeButton: {},
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    adminInfo: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    adminNameRegion: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogName: {
        color: theme.palette.text.primary,
        fontWeight: 400,
        fontSize: 24,
        marginTop: 16,
    },
    security: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 12,
        marginTop: 24,
    },
    dialogRegion: {
        color: theme.palette.text.secondary,
        fontWeight: 400,
        fontSize: 12,
    },
    miniAvatar: {
        height: 120,
        width: 120,
        background: theme.palette.text.primary,
    },
    dialogContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    lol: {
        backgroundColor: theme.palette.background.default,
    },
    xd: {
        backgroundColor: theme.palette.background.default,
        padding: '0px 24px 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    permContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    permText: {
        display: 'flex',
        flexDirection: 'column',
    },
    permTitle: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    permDesc: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    aa: {
        boxShadow: 'none',
        borderRadius: 9,
    },
    profile: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    divider: {
        marginTop: 24,
        width: '100%',
    },
    passwordContainer: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    password: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    stars: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.disabled,
    },
    fallback: {
        height: 40,
    },
    passwordField: {
        marginBottom: 16,
        width: '100%',
    },
}))
const passwordSchema = yup.object({
    current: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Old Password is required'),
    new: yup.string().min(8, 'Password should be of minimum 8 length').required('New Password is required'),
    confirm: yup.string().oneOf([yup.ref('new'), null], 'Passwords must match'),
})
