import { Avatar, ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsVertical } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { MdPeople } from 'react-icons/md'
import { AgencyType } from 'shared/Interfaces'
import { agencies } from '../../API/agencies'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import GenericDialog from '../Subscriptions/GenericDialog'

interface Props {
    agency: AgencyType
    regionName: string
}

export default React.memo(AgencyCard)
const Dots = mdiIcon(mdiDotsVertical)

function AgencyCard(props: Props) {
    const { agency, regionName } = props
    const alert = useAlert()
    const [status, setStatus] = useState(agency.active)
    const [toggleState, openToggle, closeToggle] = useBoolean()

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const toggleAgency = useCallback(async () => {
        const [err, res] = await agencies.toggleAgency({ _id: agency._id, status })
        if (err || res?.msg === 'failed') {
            alert('error', `Failed to ${status ? 'Deactivate' : 'Recativate'} Agency`)
            closeToggle()
            handleCloseMenu()
            return
        }
        if (res?.msg === 'success') {
            closeToggle()
            handleCloseMenu()
            setTimeout(() => {
                setStatus(!status)
                alert('success', `Agency ${status ? 'Rectivated' : 'Decativated'} Successfully`)
            }, 500)
        }
    }, [agency._id, alert, closeToggle, handleCloseMenu, status])
    return (
        <div className={classes.container}>
            <GenericDialog
                handleClose={closeToggle}
                action={status ? 'Deactivate' : 'Recativate'}
                description={`Are you sure you want to ${status ? 'deactivate' : 'recativate'} this agency?`}
                additionalInfo={`The agency's members won't be able to sign in again unless reactivated!`}
                handleConfirm={toggleAgency}
                open={toggleState}
            />
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem onClick={openToggle}>
                    {status ? <Typography>Deactivate</Typography> : <Typography>Reactivate</Typography>}
                </MenuItem>
            </Menu>
            <div className={classes.info}>
                <div className={classes.titleRegion}>
                    <div className={classes.titleStatus}>
                        <Typography className={classes.agencyTitle}>{agency?.title}</Typography>
                        <Typography className={status ? classes.activeStatus : classes.inactiveStatus}>
                            {status ? '(Active)' : '(Inactive)'}
                        </Typography>
                    </div>
                    <Typography className={classes.region}>{regionName}</Typography>
                </div>
                <div className={classes.nameAvatar}>
                    <Avatar className={classes.avatar} />
                    <Typography className={classes.name}>{agency?.user?.name ?? 'Pending account creation'}</Typography>
                </div>
            </div>

            <div className={classes.memberCount}>
                <ButtonBase onClick={handleClick}>
                    <Dots />
                </ButtonBase>
                <MdPeople className={classes.people} />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create(['background']),
        userSelect: 'none',
        position: 'relative',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    avatar: {
        height: 24,
        width: 24,
        marginInlineEnd: 8,
    },
    people: {
        fontSize: 24,
        color: theme.palette.primary.main,
    },

    agencyTitle: {
        marginRight: 12,

        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },

    activeStatus: {
        fontSize: 14,
        color: theme.palette.success.main,
        marginBottom: 8,
    },
    inactiveStatus: {
        fontSize: 14,
        color: theme.palette.error.main,
        marginBottom: 8,
    },

    region: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    name: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    members: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    nameAvatar: {
        display: 'flex',
        alignItems: 'center',
    },
    memberCount: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    titleRegion: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: 24,
    },
    titleStatus: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
}))
