import axios from 'axios'
import { isArray } from 'lodash'
import { UploadFilesResponse } from 'shared/types/fileTypes'
import { getTokens } from '../../config/tokens'
import removeUndefined from '../../utils/removeUndefined'
import { serverEndPoints, withServerUrl } from '../serverEndpoints'
import generateMongoId from './generateMongoId'

export type ErrorOrValue<E, R> = [E, undefined] | [undefined, R]
export type AsyncErrorOrValue<E, R> = Promise<ErrorOrValue<E, R>>

interface Args {
    args: any
    method?: 'post' | 'get' | 'put' | 'delete'
    endpoint: string
    auth?: boolean
}

async function upload(value: File) {
    let fileName = generateMongoId()

    const extension = value!.name.split('.').pop()
    if (extension) {
        fileName = `${fileName}.${extension}`
    }

    const [err, data] = await sendRequest<UploadFilesResponse>({
        endpoint: serverEndPoints.files.upload,
        args: { fileName },
        method: 'post',
    })

    if (err) {
        throw err
    }

    const { downloadUrl, uploadUrl } = data!

    let fileValue = value

    if (typeof value === 'string') {
        const file = dataURLtoFile(value, fileName)
        fileValue = file
    }

    const headers = { 'Access-Control-Allow-Origin': '*', 'x-amz-acl': 'public-read' }

    await axios.put(uploadUrl, fileValue, { headers: headers })

    return downloadUrl
}

export default async function sendRequest<R, E = any>({
    args,
    method = 'post',
    endpoint,
    auth = true,
}: Args): AsyncErrorOrValue<E, R> {
    try {
        args = { ...args }
        const { token } = await getTokens()

        let url = withServerUrl(endpoint)

        if (method === 'get') {
            url += '?' + new URLSearchParams(args)
        } else {
            for (const [key, value] of Object.entries(args)) {
                if (isArray(value) && value[0] instanceof File) {
                    args[key] = await Promise.all(
                        value.map((file) => {
                            return upload(file)
                        })
                    )
                }
                if (value instanceof File) {
                    args[key] = await upload(value)
                }
            }
        }
        const body = method === 'get' ? undefined : args
        const res = await fetch(url, {
            method: method,
            mode: 'cors',
            headers: removeUndefined({
                Accept: 'application/json',
                'Content-Type': 'application/json',
                token: auth ? token : undefined,
            }) as any,
            body: JSON.stringify(body),
        })

        if (res.status !== 200) {
            throw Error('Status not success')
        }

        const result = (await res.json()) as R

        return [undefined, result]
    } catch (e: any) {
        console.error(e)

        return [e, undefined]
    }
}

const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)![1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1
    }

    return new File([u8arr], filename, { type: mime })
}
