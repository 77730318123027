import issuers from './svgs/issuers'
export default function getCardIssuer(issuer: string) {
    switch (issuer) {
        case 'alipay':
            return { logo: issuers.alipay, name: 'Alipay' }
        case 'american-express':
            return { logo: issuers.amex, name: 'American Express' }

        case 'diners-club':
            return { logo: issuers.diners, name: 'Diners Club' }

        case 'discover':
            return { logo: issuers.discover, name: 'Discover' }

        case 'elo':
            return { logo: issuers.elo, name: 'Elo' }

        case 'hiper':
            return { logo: issuers.hiper, name: 'Hiper' }

        case 'hipercard':
            return { logo: issuers.hipercard, name: 'Hipercard' }

        case 'jcb':
            return { logo: issuers.jcb, name: 'JCB' }

        case 'maestro':
            return { logo: issuers.maestro, name: 'Maestro' }

        case 'mastercard':
            return { logo: issuers.mastercard, name: 'Mastercard' }

        case 'mir':
            return { logo: issuers.mir, name: 'Mir' }

        case 'paypal':
            return { logo: issuers.paypal, name: 'PayPal' }

        case 'unionpay':
            return { logo: issuers.unionpay, name: 'UnionPay' }

        case 'visa':
            return { logo: issuers.visa, name: 'Visa' }

        default:
            return { logo: issuers.generic, name: '' }
    }
}
