import { makeStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useCallback } from 'react'
import { UserType } from 'shared/Interfaces'
import { advertisers } from '../../../API/advertisers'
import { useUser } from '../../../containers/UserProvider'
import useAsync from '../../../hooks/useAsync'

interface Props {
    loading: boolean
    onChange: (advertiser: UserType) => void
    value: UserType | undefined
    error?: boolean
    helperText?: string
}

function AdvertiserSelectInput(props: Props) {
    const { loading, onChange, error, helperText, value } = props

    const classes = useStyles()
    const user = useUser()
    const region = user.user?.regionId

    const [users] = useAsync(() => advertisers.getAdvertisers({ region: region }), [!region])

    const getOptionLabel = useCallback((x) => x.name ?? '', [])

    const onItemSelect = useCallback((_, val) => onChange(val as UserType), [onChange])

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                disabled={loading}
                className={classes.advertiserInput}
                label="Advertiser"
                variant="filled"
                error={error}
                helperText={helperText}
            />
        ),
        [classes.advertiserInput, error, helperText, loading]
    )

    return (
        <Autocomplete
            disabled={loading}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onItemSelect}
            options={(users ?? {}).advertisers || []}
            renderInput={renderInput}
        />
    )
}

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
    },
    advertiserInput: {
        marginBottom: 16,
    },
}))

export default React.memo(AdvertiserSelectInput)
