import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
interface Props {
    lng: number
    lat: number
}

export default React.memo(LocationMarker)
function LocationMarker(props: Props) { //DONT REMOVE PROPS
    const {} = props

    const classes = useStyles()

    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.location}
        >
            <circle cx="9" cy="9" r="9" fill="#FF9800" fillOpacity="0.16" />
            <g filter="url(#filter0_dd)">
                <circle cx="9" cy="9" r="5" fill="white" />
            </g>
            <circle cx="9" cy="9" r="3" fill="#FF9800" />
            <defs>
                <filter
                    id="filter0_dd"
                    x="0"
                    y="1"
                    width="18"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.172549 0 0 0 0 0.109804 0 0 0 0 0.0509804 0 0 0 0.16 0"
                    />
                    <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.172549 0 0 0 0 0.109804 0 0 0 0 0.0509804 0 0 0 0.24 0"
                    />
                    <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

const useStyles = makeStyles(() => ({
    location: { fontSize: 24, position: 'absolute', left: -15, top: -15, zIndex: 1300 },
}))
