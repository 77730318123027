import { Button, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { MdAdd } from 'react-icons/md'

interface Props {
    svg: string
    description: string
    action?: () => void
    actionName?: string
    customWidth?: number
    customFS?: number
    actionHidden?: boolean
}

export default React.memo(GenericPlaceHolder)

function GenericPlaceHolder(props: Props) {
    const { svg, description, action, actionName, customWidth, customFS,actionHidden } = props

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <img src={svg} className={classes.svg} width={customWidth ?? 64} />
            <Typography style={{ fontSize: customFS ?? 12 }} className={classes.description}>
                {description}
            </Typography>
            {action && !actionHidden ? (
                <Button
                    startIcon={<MdAdd className={classes.mdAdd} />}
                    variant="contained"
                    color="primary"
                    className={classes.action}
                    onClick={action}
                >
                    {actionName}
                </Button>
            ) : null}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    description: { color: theme.palette.text.hint, marginBottom: 24 },
    action: { color: theme.palette.background.paper },
    svg: { marginBottom: 24 },
    mdAdd: {
        color: theme.palette.background.paper,
    },
}))
