import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { auth } from '../../API/auth'
import OnBoardingBackground from '../../assets/OnBoardingBackground'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import isAdmin from '../../config/isAdmin'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import storeToken from '../../utils/storeToken'

const EyeOn = mdiIcon(mdiEyeOutline)
const EyeOff = mdiIcon(mdiEyeOffOutline)

export const phoneRegex = /^[0-9]{3}[-\s.]?[0-9]{5,7}$/im
export const countryCodeRegex = /^\+?[0-9]{1,4}$/im

export default React.memo(Login)

function Login() {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert()

    const [visiblePassword, setVisiblePassword] = useState(false)

    const [loading, setLoading, stopLoading] = useBoolean()
    const formik = useFormik({
        initialValues: { phoneNumber: '', password: '', countryCode: '+90' },
        validationSchema: loginSchema,

        onSubmit: async (values) => {
            setLoading()

            const [err, result] = await auth.login({
                portalType: isAdmin ? 'admin' : 'advertiser',
                password: values.password,
                phoneNumber: values.countryCode + values.phoneNumber,
            })

            if (err || !result) {
                alert('error', 'An unknown error has occured')
                stopLoading()
                return
            }

            const { token, userId, res } = result

            if (res === 'invalidAttempt' || !token) {
                alert('error', 'Invalid Phone Number or Password')
                stopLoading()
                return
            }

            storeToken(token, userId!)
            stopLoading()
            setTimeout(() => {
                location.reload()
            }, 50)
        },
    })

    const goToForgotPassword = useCallback(() => history.push('/forgot-password'), [history])
    const goToSignup = useCallback(() => history.push('/signup'), [history])
    const reversePassword = useCallback(() => setVisiblePassword((x) => !x), [])

    const setCode = useCallback(
        (code: string) => {
            formik.setFieldValue('countryCode', code)
        },
        [formik]
    )

    return (
        <div className={classes.container}>
            <OnBoardingBackground />

            <div className={classes.flex}>
                <div className={classes.body}>
                    <Typography className={classes.title}>Sign In</Typography>

                    <form onSubmit={formik.handleSubmit}>
                        <div className={classes.textFields}>
                            <div className={classes.phoneInput}>
                                <CountrySearchMenu setCode={setCode} loading={loading} />
                                <TextField
                                    disabled={loading}
                                    label="Phone Number"
                                    onChange={formik.handleChange('phoneNumber')}
                                    variant="filled"
                                    className={classes.phoneNumber}
                                    value={formik.values.phoneNumber}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />
                            </div>
                            <TextField
                                disabled={loading}
                                className={classes.passwordField}
                                label="Password"
                                type={visiblePassword ? 'text' : 'password'}
                                variant="filled"
                                onChange={formik.handleChange('password')}
                                value={formik.values.password}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={reversePassword}>
                                            {visiblePassword ? <EyeOn /> : <EyeOff />}
                                        </IconButton>
                                    ),
                                }}
                            />

                            <div className={classes.forgotPassDiv} onClick={goToForgotPassword}>
                                <Typography className={classes.forgotPassword}>Forgot Password?</Typography>
                            </div>
                        </div>

                        {loading ? (
                            <div className={classes.loadingContainer}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                Sign In
                            </Button>
                        )}
                    </form>
                </div>

                {isAdmin ? null : (
                    <div className={classes.regContainer}>
                        <Typography className={classes.dontHave}>Dont have an account?</Typography>
                        <div className={classes.regNowIcon} onClick={goToSignup}>
                            <Typography className={classes.regNow}>Register Now</Typography>
                            <MdArrowForward className={classes.arrow} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
    },
    body: {
        width: 400,
    },
    flex: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flex: 2,
    },
    button: {
        color: theme.palette.background.paper,
        width: '100%',
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    textFields: {
        marginBottom: 40,
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        flex: 1,
        marginBottom: 16,
    },
    passwordField: { marginBottom: 12 },
    forgotPassword: {
        fontWeight: 400,
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    forgotPassDiv: {
        cursor: 'pointer',
        userSelect: 'none',

        '&:hover': {
            '& $forgotPassword': {
                color: theme.palette.primary.dark,
            },
        },
    },
    title: {
        fontWeight: 400,
        fontSize: 24,
        color: theme.palette.text.primary,
    },
    regContainer: {
        marginTop: 62,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 400,
    },
    regNowIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& $arrow': {
                color: theme.palette.primary.dark,
            },
            '& $regNow': {
                color: theme.palette.primary.dark,
            },
        },
    },
    dontHave: {
        fontWeight: 400,
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    regNow: {
        fontWeight: 500,
        color: theme.palette.primary.main,
        fontSize: 14,
        marginInlineEnd: 8,
        transition: theme.transitions.create(['color']),
    },
    arrow: {
        color: theme.palette.primary.main,
        transition: theme.transitions.create(['color']),
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
}))

const loginSchema = yup.object({
    phoneNumber: yup.string().matches(phoneRegex, 'Phone number is not valid').required('Phone Number is required'),
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),
    password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
})
