import { IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useCallback } from 'react'
import { subscriptions } from '../../../API/subscriptions'
import Loading from '../../../containers/Layout/Loading'
import useAsync from '../../../hooks/useAsync'
import CreditCardItem from './CreditCardItem'

interface Props {
    close: () => void
}

export default React.memo(ManageCards)

function ManageCards(props: Props) {
    const { close } = props
    const classes = useStyles()
    const [loadedCards, cardsLoading, , setCards] = useAsync(() => subscriptions.getSavedCards({}))
    const setDeleteCards = useCallback(
        (idx: number) => {
            setCards({ data: [...loadedCards!.data!.slice(0, idx), ...loadedCards!.data!.slice(idx + 1)] })
        },
        [loadedCards, setCards]
    )
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography className={classes.title}>Manage Cards</Typography>
                <IconButton onClick={close}>
                    <CloseIcon />
                </IconButton>
            </div>

            {cardsLoading ? (
                <Loading />
            ) : (
                <div className={classes.content}>
                    {loadedCards?.data.map((c, idx) => {
                        return <CreditCardItem card={c} key={idx} setDeleteCards={setDeleteCards} idx={idx} />
                    })}

                    {!loadedCards?.data.length ? (
                        <div className={classes.placeholderContainer}>
                            <Typography className={classes.placeholder}>{`You don't have any saved cards`}</Typography>
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 72,
        padding: '0px 16px',
        background: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 8,
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    content: { padding: 16 },
    placeholder: {
        textAlign: 'center',
    },
    placeholderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        minHeight: 64,
    },
}))
