import { Avatar, Divider, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiStoreOutline } from '@mdi/js'
import React from 'react'
import { IoLocationOutline } from 'react-icons/io5'
import { UserType } from 'shared/Interfaces'
import { mdiIcon } from '../../utils/mdiIcon'

interface Props {
    advertiser: UserType
    className: string
    regionName: string | undefined
}

export default React.memo(InfoCard)
const Store = mdiIcon(mdiStoreOutline)

function InfoCard(props: Props) {
    const { advertiser, className, regionName } = props

    const classes = useStyles()

    return (
        <div className={className}>
            {advertiser.avatar ? (
                <Avatar className={classes.userAvatar} src={advertiser.avatar} />
            ) : (
                <div className={classes.storeContainer}>
                    <Store className={classes.store} />
                </div>
            )}
            <Typography className={classes.infoName}>{advertiser.name}</Typography>
            <Typography className={classes.infoRegion}>{regionName ?? 'Region'}</Typography>
            <Typography className={classes.infoInfo}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit metus,elit egestas sed.
            </Typography>
            <Divider className={classes.divider} />
            <IoLocationOutline className={classes.infoLocationIcon} />
            <Typography className={classes.infoLocation}>
                stasyon cd. Yaprak Mah. No:76 Şehitkamil / Gaziantep
            </Typography>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    storeContainer: {
        width: 128,
        height: 128,
        borderRadius: 68,
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    store: {
        fontSize: 54,
        color: theme.palette.text.disabled,
    },
    userAvatar: {
        height: 128,
        width: 128,
        borderRadius: 68,
    },

    infoName: { fontWeight: 500, fontSize: 20, color: theme.palette.text.primary, marginTop: 24 },
    infoRegion: { fontWeight: 400, fontSize: 14, color: theme.palette.primary.main, marginTop: 4 },
    infoInfo: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.hint,
        marginTop: 24,
        width: 200,
        textAlign: 'center',
    },
    infoLocationIcon: { fontSize: 20, color: theme.palette.background.default, marginTop: 24 },
    infoLocation: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginTop: 12,
        width: 176,
        textAlign: 'center',
    },
    divider: { width: '90%', marginTop: 24 },
}))
