import { Button, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { MdAdd, MdOndemandVideo } from 'react-icons/md'
import { AdVideoType } from 'shared/Interfaces'
import { adsVideos } from '../../API/adsVideos'
import { regions } from '../../API/regions'
import assets from '../../assets/assets'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import Loading from '../../containers/Layout/Loading'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import CreateVideoAdDialog from './CreateVideoAdDialog'
import VideoCard from './VideoCard'

export default React.memo(VideoAdsList)

function VideoAdsList() {
    const classes = useStyles()

    const [loadedRegions] = useAsync(() => regions.get({}))
    const [loadedVideos, , , setVideos] = useAsync(() => adsVideos.get())

    const [open, setOpen, setClosed] = useBoolean()

    const onSubmit = useCallback(
        (res: AdVideoType | undefined) => {
            setVideos({ adVideos: [...loadedVideos!.adVideos!, res!] })
        },
        [loadedVideos, setVideos]
    )

    const onDelete = useCallback(
        (id: string) => {
            const idx = loadedVideos!.adVideos!.findIndex((v) => v._id == id)
            setVideos({
                adVideos: [...loadedVideos!.adVideos!.slice(0, idx), ...loadedVideos!.adVideos!.slice(idx + 1)],
            })
        },
        [loadedVideos, setVideos]
    )
    const sortedVideos = useMemo(
        () => sortBy(loadedVideos?.adVideos ?? [], (x) => x.title).reverse(),
        [loadedVideos?.adVideos]
    )

    if (!loadedRegions || !loadedVideos) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <Dialog onClose={setClosed} open={open} classes={{ paper: classes.dialog }}>
                <CreateVideoAdDialog setClosed={setClosed} onSubmit={onSubmit} />
            </Dialog>

            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <MdOndemandVideo className={classes.accountIcon} />
                    <Typography className={classes.title}>Video Ads</Typography>
                </div>

                <div>
                    <Button
                        className={classes.addButton}
                        startIcon={<MdAdd className={classes.mdAdd} />}
                        variant="contained"
                        color="primary"
                        onClick={setOpen}
                    >
                        Add Video Ad
                    </Button>
                </div>
            </div>

            <div className={classes.body}>
                {sortedVideos.length == 0 ? (
                    <GenericPlaceHolder
                        description="There are no video ads here yet."
                        svg={assets.placeHolders.VideoAds}
                        customWidth={320}
                        customFS={20}
                        action={setOpen}
                        actionName="Add Video Ad"
                    />
                ) : (
                    <div className={classes.gridFather}>
                        <div className={classes.placeholder} />

                        <div className={classes.gridContainer}>
                            {sortedVideos.map((v, idx) => {
                                return <VideoCard video={v} key={idx} deleteId={onDelete} />
                            })}
                        </div>

                        <div className={classes.placeholder} />
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    dialog: {
        width: 560,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    accountIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        fontSize: 20,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },

    body: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    gridContainer: {
        display: 'grid',
        gridGap: `32px 40px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(1, auto)',
        margin: '0px 24px',
    },

    placeholder: {
        minHeight: 48,
    },
}))
