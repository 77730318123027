import { Button, Dialog, Tab, Tabs, Typography, useTheme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import moment from 'moment'
import { default as React, useCallback, useState } from 'react'
import Cube from 'react-3d-cube'
import { MdCheck, MdClose } from 'react-icons/md'
import { CampaignStatus, CampaignType } from 'shared/Interfaces'
import { UpdateCampaignArgs } from 'shared/types/campaignTypes'
import { campaigns } from '../../API/campaigns'
import TabPanel from '../../components/TabPanel'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import useBoolean from '../../hooks/useBoolean'
import SlideTransition from './SlideTransition'

interface Props {
    campaign: CampaignType
    onPress: () => void
    approveS: () => void
    rejectS: () => void
    state: boolean
    currentStatus: CampaignStatus
}

export default React.memo(CampaignDialog)

function CampaignDialog(props: Props) {
    const { campaign, onPress, state, approveS, rejectS, currentStatus } = props
    const theme = useTheme()
    const alert = useAlert()
    const classes = useStyles()
    const [tabIndex, setTabIndex] = useState(0)


    const [loading, setLoading, stopLoading] = useBoolean()
    const formik = useFormik<UpdateCampaignArgs>({
        initialValues: {
            id: '',
            status: 'pendingApproval',
        },

        onSubmit: async (values) => {
            setLoading()
            campaigns
                .update(values)
                .then(() => {
                    alert('success', 'Success!')
                })
                .catch((e) => {
                    console.error(e)
                    alert('error', 'Error processing your request.')
                })
            stopLoading()
            onPress()
        },
    })

    const approve = useCallback(async () => {
        await formik.setValues({ id: campaign._id!, status: 'approved' })
        formik.submitForm()
        approveS()
    }, [approveS, campaign._id, formik])

    const reject = useCallback(async () => {
        await formik.setValues({ id: campaign._id!, status: 'rejected' })
        formik.submitForm()
        rejectS()
    }, [campaign._id, formik, rejectS])



    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabsChange = useCallback((event, newValue) => {
        setTabIndex(newValue)
    }, [])

    console.log({ tabIndex })

    return (
        <div className={classes.container}>
            <Dialog
                onClose={onPress}
                open={state}
                scroll='body'
                fullWidth
                classes={{ paper: classes.dialog, root: classes.markerDialogZindex }}
                TransitionComponent={SlideTransition}
                PaperProps={{ style: { minWidth: '820px' } }}
            >
                <div className={classes.dialogTitleContainer}>
                    <Typography className={classes.dialogTitle}>Campaign Approval</Typography>
                </div>


                <Tabs value={tabIndex} onChange={handleTabsChange} aria-label="basic tabs example">
                    <Tab label="Info" {...a11yProps(0)} />
                    <Tab label="Images" {...a11yProps(1)} />
                    <Tab label="Ad video" {...a11yProps(2)} />

                    <Tab label="AR Cube" {...a11yProps(3)} />

                    <Tab label="Targeted Aud" {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>

                    <div className={classes.tabPanel}>

                        <Typography className={classes.info}>Info</Typography>
                        <div className={classes.unit}>
                            <Typography className={classes.type}>Campaign Title</Typography>
                            <Typography className={classes.campaignVal}>{campaign.title}</Typography>
                        </div>

                        <div className={classes.unit}>
                            <Typography className={classes.type}>Description</Typography>
                            <Typography className={classes.campaignVal}>{campaign.description}</Typography>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <div className={classes.tabPanel}>
                        <div className={classes.imagesContainer}>
                            {campaign.images.map((url, idx) => {
                                return (
                                    <div className={classes.imgContainer} key={idx}>
                                        <img className={classes.img} src={url} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <div className={classes.tabPanel} >

                        {campaign.adsVideo ?
                            <video controls width={400} height={500}>
                                <source src={campaign.adsVideo} type="video/mp4" />
                            </video>
                            :
                            <div className={classes.emptyVideo}>
                                <span>No video selected</span>
                            </div>

                        }

                    </div>
                </TabPanel>

                <TabPanel value={tabIndex} index={3}>
                    <div className={classes.tabPanel} >
                        <div className={classes.CubePreview}>
                            <div className={classes.CubePreviewContainer}>
                                {/* @ts-ignore */}
                                <Cube size={240} index="front">
                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />
                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />

                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />

                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />

                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />

                                    <img className={classes.cubeImage} src={campaign.cube3dImage} />
                                </Cube>
                            </div>
                        </div>

                    </div>
                </TabPanel>

                <TabPanel value={tabIndex} index={4}>
                    <div className={classes.tabPanel}>
                        <div className={classes.unit}>
                            <Typography className={classes.type}>Start Date</Typography>
                            <Typography className={classes.campaignVal}>{moment(campaign.collectTime).format('LL')}</Typography>
                        </div>
                        <div className={classes.unit}>
                            <Typography className={classes.type}>End Date</Typography>
                            <Typography className={classes.campaignVal}>{moment(campaign.untilTime).format('LL')}</Typography>
                        </div>

                        <div className={classes.unit}>
                            <Typography className={classes.type}>Age Between</Typography>
                            <Typography className={classes.campaignVal}>
                                {campaign.filters.ageFrom}-{campaign.filters.ageUntil}
                            </Typography>
                        </div>

                        <div className={classes.unit}>
                            <Typography className={classes.type}>Region</Typography>
                            <Typography className={classes.campaignVal}>{campaign.region}</Typography>
                        </div>

                        <div className={classes.unit}>
                            <Typography className={classes.type}>Gender</Typography>
                            <Typography className={classes.campaignVal}>{campaign.filters.gender}</Typography>
                        </div>
                    </div>
                </TabPanel>


                <div className={classes.dialogButtons}>
                    {currentStatus === 'pendingApproval' ? (
                        loading ? (
                            <Loading />
                        ) : (
                            <>
                                <Button
                                    disabled={currentStatus !== 'pendingApproval'}
                                    onClick={reject}
                                    style={{
                                        marginRight: 12,
                                        background: theme.palette.error.main,
                                        color: theme.palette.background.paper,
                                    }}
                                    variant="contained"
                                    startIcon={<MdClose />}
                                >
                                    Reject
                                </Button>
                                <Button
                                    disabled={currentStatus !== 'pendingApproval'}
                                    onClick={approve}
                                    style={{
                                        background: theme.palette.success.main,
                                        color: theme.palette.background.paper,
                                    }}
                                    variant="contained"
                                    startIcon={<MdCheck />}
                                >
                                    Accept
                                </Button>
                            </>
                        )
                    ) : currentStatus === 'approved' ? (
                        <Typography className={classes.approved}>Approved</Typography>
                    ) : (
                        <Typography className={classes.rejected}>Rejected</Typography>
                    )}
                </div>
            </Dialog>
        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    container: { position: 'relative', height: 0, width: 42, cursor: 'pointer' },
    marker: { position: 'absolute', bottom: -8, right: 21 },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '24px 16px',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    info: {
        marginBottom: 24,
        marginLeft: 16,
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 500,
    },
    unit: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 16,
        marginLeft: 16,
    },
    type: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.hint,
        marginBottom: 4,
    },
    campaignVal: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
        marginRight: 16,
    },
    tableContainer: {
        width: 800,
    },
    dialog: {
        width: '100%',
    },

    markerDialogZindex: {
        width: '100%',
        zIndex: 1299,
    },
    emptyVideo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 400
    },

    CubePreviewContainer: {
        height: 120,
        width: 120,
        marginTop: 8,
        cursor: 'grab',

    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    gift: {
        marginTop: 4,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    region: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    tabPanel: {
        padding: 16,
        minHeight: 400
    },
    divider: {
        margin: 8
    },
    coords: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    imagesContainer: {

        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    imgContainer: {
        borderRadius: 4,
        display: 'flex'
    },
    img: {
        maxWidth: 160,
        maxHeight: 160,
        borderRadius: 4,
        margin: '0px 16px'
    },
    cubeImage: {
        width: 120,
        height: 120,
    },
    code: {
        fontWeight: 400,
        fontSize: 12,
        marginLeft: 8,
        color: theme.palette.text.hint,
    },
    titleCode: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerInfoAvatar: { display: 'flex' },
    headerInfo: { marginLeft: 16 },
    phoneNumber: {
        marginBottom: 16,
    },
    CubePreview: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingTop: 88,
        cursor: 'grab',
    },
    CubePreviewImage: {
        width: 120,
        height: 120,
        objectFit: 'cover',
        borderRadius: 4,
    },
    CubePreviewText: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.hint,
        marginTop: 8,
    },
    approved: {
        color: theme.palette.success.main,
        fontWeight: 500,
        fontSize: 14,
    },
    rejected: {
        color: theme.palette.error.main,
        fontWeight: 500,
        fontSize: 14,
    },
    video: {
        width: 400,
        height: 400,
        objectFit: 'cover',
        borderRadius: 4,
    },
    body: {
        height: 'calc(100% - 128px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginTop: 24,
    },
    gridContainer: {
        display: 'grid',
        gridGap: `40px 40px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(3, auto)',
    },
    addContainer: {
        width: 202,
        height: 140,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: theme.transitions.create(['border-color']),
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& $add': {
                color: theme.palette.primary.main,
            },
            borderColor: theme.palette.primary.main,
        },
    },
    addContent: { display: 'flex', flexDirection: 'column', alignItems: 'center' },

    add: { fontSize: 20, marginBottom: 22, transition: theme.transitions.create(['color']) },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    // approved: { color: theme.palette.success.main, fontWeight: 500 },
    // rejected: { color: theme.palette.error.main, fontWeight: 500 },
}))
