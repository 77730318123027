import { Button, CircularProgress, FormControl, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import { MdClose } from 'react-icons/md'
import * as yup from 'yup'
import { admins } from '../../API/admins'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'
import { CreateAdminResponse } from '../../shared/types/adminTypes'
import { GetRegionResponse } from '../../shared/types/regionTypes'
import { countryCodeRegex, phoneRegex } from '../Users/Login'

interface Props {
    loadedRegions: GetRegionResponse
    setClosed: () => void
    setResult: (res: CreateAdminResponse) => void
}

export default React.memo(CreateAdminDialog)

function CreateAdminDialog(props: Props) {
    const { loadedRegions, setClosed, setResult } = props

    const alert = useAlert()

    const classes = useStyles()

    const [loading, setLoading, stopLoading] = useBoolean()

    const form = useFormik({
        initialValues: { name: '', phoneNumber: '', regionId: '', countryCode: '+90' },
        validationSchema: adminSchema,

        onSubmit: async (values) => {
            setLoading()
            const { countryCode, phoneNumber, name, regionId } = values
            const PN = countryCode + phoneNumber
            const [err, res] = await admins.addAdmin({
                phoneNumber: PN,
                name,
                regionId,
            })

            if (err || !res) {
                stopLoading()
                alert('error', 'Unknown Error')
                return
            }

            if (res.msg === 'alreadyExists') {
                stopLoading()
                alert('error', 'User Already Exists')
                return
            }

            alert('success', 'Admin Added Successfully')
            setResult(res)

            stopLoading()
            setClosed()
        },
    })

    const setCode = useCallback(
        (code: string) => {
            form.setFieldValue('countryCode', code)
        },
        [form]
    )

    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>Add Admin</Typography>
                <IconButton onClick={setClosed}>
                    <MdClose />
                </IconButton>
            </div>

            <form onSubmit={form.handleSubmit}>
                <div className={classes.textFields}>
                    <TextField
                        disabled={loading}
                        label="Full Name"
                        onChange={form.handleChange('name')}
                        variant="filled"
                        className={classes.phoneNumber}
                        value={form.values.name}
                        error={form.touched.name && Boolean(form.errors.name)}
                        helperText={form.touched.name && form.errors.name}
                    />
                    <div className={classes.phoneInput}>
                        <CountrySearchMenu setCode={setCode} loading={loading} />
                        <TextField
                            disabled={loading}
                            label="Phone Number"
                            onChange={form.handleChange('phoneNumber')}
                            variant="filled"
                            className={classes.phoneNumber}
                            value={form.values.phoneNumber}
                            error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                            helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                        />
                    </div>

                    <FormControl variant="filled" className={classes.phoneNumber}>
                        <InputLabel>Region</InputLabel>
                        <Select
                            disabled={loading}
                            error={form.touched.regionId && Boolean(form.errors.regionId)}
                            value={form.values.regionId}
                            onChange={form.handleChange('regionId')}
                        >
                            {loadedRegions?.regions.map((x) => {
                                return (
                                    <MenuItem key={x._id} value={x._id}>
                                        {x.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.dialogButtons}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={setClosed} className={classes.cancelButton} variant="text" color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                            >
                                Add Admin
                            </Button>
                        </>
                    )}
                </div>
            </form>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    gridContainer: {
        display: 'grid',
        gridGap: `40px 20px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(auto-fill, 264px)',
        margin: '40px 24px',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.default,
        padding: '0px 16px',
        height: 72,
    },
    dialogButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
        flex: 1,
    },
    placeholder: {
        minHeight: 48,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))

const adminSchema = yup.object({
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),

    name: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Full name is required'),
    phoneNumber: yup.string().matches(phoneRegex, 'Phone number is not valid').required('Phone Number is required'),
    regionId: yup.string().required('Region is required'),
})
