import { Button, IconButton, Typography, useTheme } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import Drawer from '@material-ui/core/Drawer'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import GoogleMapReact from 'google-map-react'
import { orderBy } from 'lodash'
import { default as React, useCallback, useEffect, useState } from 'react'
import { MdAddLocation, MdClose, MdFormatListBulleted, MdTune } from 'react-icons/md'
import { PointType } from 'shared/Interfaces'
import { AddPointArgs } from 'shared/types/pointTypes'
import * as yup from 'yup'
import { points as pointsApi } from '../../API/points'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import { useUser } from '../../containers/UserProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import AllPoints from './Components/AllPoints'
import CreatePointDialog from './Components/CreatePointDialog'
import LocationMarker from './Components/LocationMarker'
import MapMarker from './Components/MapMarker'
import PointTypeSelectDialog from './Components/PointTypeSelectDialog'
import SearchBox from './Components/SearchBox'
import { categoriesList } from './data/categoriesList'

const isAdvertiser = process.env.REACT_APP_PORTAL === 'advertisers'

interface Props {}

export default React.memo(Points)

function Points(props: Props) {
    const {} = props
    const theme = useTheme()
    const alert = useAlert()
    const { user, loading: loadingUser } = useUser()
    const classes = useStyles()

    const [pointType, setPointType] = useState<'select' | 'generalPoint' | 'advertiser'>('select')
    const [gotLocation, setGotLocation] = useState<boolean>(false)
    const [points, setPoints] = useState([] as PointType[])
    const [categoriesChecked, setCategoriesChecked] = useState(categoriesList)
    const [position, setPosition] = useState<GeolocationCoordinates>({
        accuracy: 7093,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: 37.0311562,
        longitude: 37.3292757,
        speed: null,
    })
    const [center, setCenter] = useState({ lat: position.latitude, lng: position.longitude })

    const currentCoords = { lng: position.longitude, lat: position.latitude }

    const [open, setOpen, setClosed] = useBoolean()
    const [drawer, openDrawer, closeDrawer] = useBoolean()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selection, _, setNoSelection, toggleSelection] = useBoolean()
    const [allPoints, openAllPoints, closeAllPoints] = useBoolean()
    const [loading, setLoading, stopLoading] = useBoolean()

    const region = user?.regionId
    const [loadedPoints] = useAsync(() => pointsApi.getByRegion({ region: region }), [!region])
    useEffect(() => {
        if (loadedPoints) {
            setPoints(loadedPoints.points.map((x) => x))
        }
    }, [loadedPoints])

    const formik = useFormik<AddPointArgs>({
        initialValues: {
            code: '',
            enc: undefined,
            category: '',
            region: region,
            title: '',
            advertiserId: isAdvertiser ? user?._id : undefined,
            avatar: isAdvertiser ? user?.avatar : undefined,
            belongTo: isAdvertiser ? 'advertiser' : 'generalPoint',
        },
        validationSchema: pointSchema,

        onSubmit: async (values) => {
            submitCB(values)
        },
    })

    const handleClose = useCallback(() => {
        setClosed()
        setPointType('select')
    }, [setClosed])

    const setSearchCenter = useCallback((lat: number, lng: number) => {
        setCenter({ lat, lng })
    }, [])

    const submitCB = useCallback(
        async (values) => {
            setLoading()

            const [err, res] = await pointsApi.add(values)

            if (!res) {
                console.error(err)
                stopLoading()

                return
            }

            if (res.msg === 'success') {
                alert('success', 'Point added successfully')
                setPoints((_points) => [..._points, res.values!])

                formik.resetForm()
            } else {
                alert('error', 'Error adding point!')
            }
            stopLoading()
            formik.resetForm()
            setClosed()
        },
        [alert, formik, setClosed, setLoading, stopLoading]
    )

    const addPoint = useCallback(
        async (e: GoogleMapReact.ClickEventValue) => {
            const [err, res] = await pointsApi.check({ lat: e.lat, lng: e.lng })
            if (err) {
                alert('error', 'Something Went Wrong')
                return
            }
            if (res?.msg === 'notAllowed') {
                alert('error', 'Forbidden Location')
                return
            }
            formik.values.enc = res?.enc
            setNoSelection()
            if (isAdvertiser) {
                formik.values.region = user?.regionId
                setPointType('advertiser')
            }
            setOpen()
        },
        [alert, formik.values, setNoSelection, setOpen, user?.regionId]
    )

    const pointRemover = useCallback((idx: number) => {
        setPoints((_points) => [..._points.slice(0, idx), ..._points.slice(idx + 1)])
    }, [])

    const handleCategories = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (categoriesChecked.includes(event.target.name)) {
                setCategoriesChecked(categoriesChecked.filter((f) => f !== event.target.name))
            } else {
                setCategoriesChecked([...categoriesChecked, event.target.name])
            }
        },
        [categoriesChecked]
    )

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((p) => {
            setPosition(p.coords)
            setGotLocation(true)
        })
    }, [])

    if (!position || loadingUser || !loadedPoints) {
        return <Loading />
    }

    console.log({ pointType })

    return (
        <div style={{ cursor: selection ? 'crosshair' : 'pointer' }} className={classes.container}>
            {allPoints ? <AllPoints theme={theme} points={points} state={allPoints} onPress={closeAllPoints} /> : null}

            <Dialog onClose={handleClose} open={open} classes={{ paper: classes.dialog }}>
                {pointType === 'select' ? (
                    <PointTypeSelectDialog setPointType={setPointType} handleClose={handleClose} />
                ) : null}
                {pointType !== 'select' ? (
                    <CreatePointDialog
                        isAdvertiserPoint={pointType === 'advertiser'}
                        formik={formik}
                        handleClose={handleClose}
                        loading={loading}
                    />
                ) : null}
            </Dialog>

            <div id="map" />
            <Drawer anchor="right" open={drawer} onClose={closeDrawer} style={{ zIndex: 1301 }}>
                <div style={{ width: 320 }}>
                    <div className={classes.drawerHeader}>
                        <Typography className={classes.dialogTitle}>Filter</Typography>
                        <IconButton onClick={closeDrawer}>
                            <MdClose />
                        </IconButton>
                    </div>
                    <div className={classes.categories}>
                        <Typography className={classes.filtersTitles}>Category</Typography>
                        <div className={classes.filterItems}>
                            {categoriesList.map((c, idx) => {
                                return (
                                    <div className={classes.filterItem} key={idx}>
                                        <Checkbox
                                            checked={categoriesChecked.includes(c)}
                                            onChange={handleCategories}
                                            name={c}
                                        />
                                        <Typography className={classes.filterItemText}>{c}</Typography>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </Drawer>

            <div className={classes.topButtons}>
                <SearchBox center={setSearchCenter} />
                <IconButton className={classes.iconButton} onClick={openDrawer} disabled={loading}>
                    <MdTune className={classes.tune} />
                </IconButton>
                <Button
                    className={classes.points}
                    onClick={openAllPoints}
                    variant="contained"
                    startIcon={<MdFormatListBulleted />}
                    disabled={loading}
                >
                    <Typography className={classes.buttonTexts}>All Points</Typography>
                </Button>
                <Button
                    disabled={loading}
                    className={classes.addPoint}
                    onClick={toggleSelection}
                    color="primary"
                    variant="contained"
                    startIcon={<MdAddLocation />}
                >
                    <Typography className={classes.buttonTexts}>Add Point</Typography>
                </Button>
            </div>
            <GoogleMapReact
                center={center}
                style={{ cursor: selection ? 'crosshair !important' : 'pointer' }}
                bootstrapURLKeys={{ key: 'AIzaSyBgvyMxoLgmvzth-WHq2OubjI-DbQDCbto' }}
                defaultCenter={{ lat: position!.latitude, lng: position!.longitude }}
                defaultZoom={14}
                onClick={selection ? addPoint : undefined}
                options={{
                    fullscreenControl: false,
                    mapTypeControl: true,
                    mapTypeControlOptions: false,
                    clickableIcons: true,
                    draggableCursor: selection ? 'url(/cursor.cur) 18 32, default' : '',
                }}
            >
                {gotLocation ? <LocationMarker {...currentCoords} /> : null}
                {orderBy(points, (x) => x.lat, 'desc')
                    .filter((f) => {
                        return categoriesChecked.includes(f.category)
                    })
                    .map((p, idx) => {
                        const coords = { lat: p.lat, lng: p.lng }
                        return (
                            <MapMarker
                                {...coords}
                                point={p}
                                key={idx}
                                theme={theme}
                                idx={idx}
                                // eslint-disable-next-line react/jsx-no-bind
                                pointRemover={() => pointRemover(idx)}
                            />
                        )
                    })}
            </GoogleMapReact>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: { width: '100%', height: '100%', position: 'relative' },
    topButtons: {
        position: 'absolute',
        top: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'row',
    },
    addPoint: {
        zIndex: 999,
        color: theme.palette.background.paper,
        borderRadius: 50,
        height: 40,
    },

    points: {
        marginRight: 16,
        zIndex: 999,
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: 50,
        height: 40,

        '&:hover': {
            background: theme.palette.background.default,
        },
    },
    iconButton: {
        marginRight: 24,

        padding: 0,
        height: 'fit-content',
        zIndex: 999,
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderRadius: 50,
        '&:hover': {
            background: theme.palette.background.default,
        },
    },
    tune: {
        fontSize: 20,
        padding: 10,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: 16,
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: `16px 24px`,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },

    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    buttonTexts: {
        fontWeight: 500,
        fontSize: 14,
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.default,
        height: 72,
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    categories: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
    },
    filtersTitles: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.hint,
    },
    filterItems: {
        marginTop: 12,
        marginLeft: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    filterItem: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.primary,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterItemText: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.primary,
    },
}))

const pointArgs = {
    code: yup.string().required('Code is required'),

    category: yup.string().required('Category is required'),
    region: yup.string().required('Region is required'),
    title: yup.string().required('Region is required'),
}
const pointSchema = yup.object({
    ...pointArgs,
})
