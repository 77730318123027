import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import noop from 'lodash/noop'
import React from 'react'
import { IconType } from 'react-icons'
import { MdArrowBack } from 'react-icons/md'
import SearchBar from './SearchBar'

interface Props {
    title?: string
    Icon?: IconType
    actions?: { title: string; Icon?: IconType; onClick: () => void; hide?: boolean }[]
    value?: string
    onChange?: (val: string) => void
    onBackPress?: () => void
    additionalButtons?: JSX.Element[]
}

export default React.memo(Header)

function Header(props: Props) {
    const { Icon, title, value, onChange, actions, onBackPress, additionalButtons } = props

    const classes = useStyles()

    return (
        <div className={classes.header}>
            <div className={classes.titleContainer}>
                {onBackPress ? (
                    <IconButton onClick={onBackPress} className={classes.backButton}>
                        <MdArrowBack className={classes.backIcon} />
                    </IconButton>
                ) : null}

                {Icon ? <Icon className={classes.icon} /> : null}
                <Typography className={classes.title}>{title ?? ''}</Typography>
            </div>

            <div className={classes.searchContainer}>
                {onChange ? <SearchBar value={value ?? ''} onChange={onChange ?? noop} /> : null}
                {additionalButtons ? additionalButtons.map((elment) => elment) : null}

                {(actions ?? []).map((act, idx) => {
                    const last = idx === (actions ?? [])?.length - 1
                    if (act.hide) {
                        return null
                    }

                    return (
                        <Button
                            className={classNames({ [classes.action]: true, [classes.lastAction]: last })}
                            onClick={act.onClick}
                            variant="contained"
                            color="primary"
                            key={idx}
                            startIcon={act.Icon ? <act.Icon /> : undefined}
                            classes={{ text: classes.ButtonText }}
                        >
                            {act.title}
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    header: {
        width: 'calc(100% - 48px)',
        height: 64,
        minHeight: 64,
        maxHeight: 64,
        padding: '0px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: 22,
        marginRight: 16,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 20,
    },
    action: {
        marginLeft: 16,
        color: '#FFF',
    },
    lastAction: {
        marginLeft: 24,
    },
    backButton: {
        padding: 0,
        marginRight: 8,
        height: 40,
        width: 40,
    },
    backIcon: {
        color: theme.palette.text.primary,
        fontSize: 22,
    },
    ButtonText: { color: '#FFF' },
}))
