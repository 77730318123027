import { IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiArrowLeft } from '@mdi/js'
import React from 'react'
import { mdiIcon } from '../../utils/mdiIcon'

interface Props {
    back: () => void
}

export default React.memo(AdvertiserHeader)

function AdvertiserHeader(props: Props) {
    const { back } = props

    const classes = useStyles()
    const Arrow = mdiIcon(mdiArrowLeft)

    return (
        <div className={classes.header}>
            <div className={classes.headerActions}>
                <div className={classes.backContainer}>
                    <IconButton onClick={back}>
                        <Arrow className={classes.arrow} />
                    </IconButton>

                    <Typography className={classes.back}>Back</Typography>
                </div>
                <div />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    header: {
        padding: '24px 0px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    headerActions: {
        width: 680,
        display: 'flex',

        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    backContainer: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
    arrow: { color: theme.palette.background.paper },
    back: { color: theme.palette.background.paper, fontWeight: 500, fontSize: 14, zIndex: 9999 },
}))
