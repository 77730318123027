import {
    AddPackageArgs,
    AddPackageResponse,
    DeletePackageArgs,
    DeletePackageResponse,
    GetPacksArgs,
    GetPacksResponse,
    GetSavedCardsArgs,
    GetSavedCardsResponse,
    PaySavedCardArgs,
    PaySavedCardResponse,
    SubscribeArgs,
    SubscribeResponse,
} from 'shared/types/packageTypes'
import {
    AddPlanArgs,
    AddPlanResponse,
    DeletePlanArgs,
    DeletePlanResponse,
    GetPlansArgs,
    GetPlansResponse,
} from 'shared/types/planTypes'
import {
    CancelSubscriptionArgs,
    CancelSubscriptionResponse,
    ChangeSavedCardArgs,
    ChangeSavedCardResponse,
    ChangeToNewCardArgs,
    ChangeToNewCardResponse,
    DeleteSavedCardArgs,
    DeleteSavedCardResponse,
    DeReActivateSubArgs,
    DeReActivateSubResponse,
    GetOffersCountArgs,
    GetOffersCountResponse,
    GetSubscriptionsArgs,
    GetSubscriptionsResponse,
} from 'shared/types/subscriptionTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const subscriptions = {
    buy: (args: SubscribeArgs) =>
        sendRequest<SubscribeResponse>({ endpoint: serverEndPoints.subscriptions.buy, args, method: 'post' }),
    buySavedCard: (args: PaySavedCardArgs) =>
        sendRequest<PaySavedCardResponse>({
            endpoint: serverEndPoints.subscriptions.buySavedCards,
            args,
            method: 'post',
        }),

    getSubscriptions: (args: GetSubscriptionsArgs) =>
        sendRequest<GetSubscriptionsResponse>({ endpoint: serverEndPoints.subscriptions.get, args, method: 'get' }),
    getSavedCards: (args: GetSavedCardsArgs) =>
        sendRequest<GetSavedCardsResponse>({ endpoint: serverEndPoints.subscriptions.savedCards, args, method: 'get' }),
    DeReActivateSub: (args: DeReActivateSubArgs) =>
        sendRequest<DeReActivateSubResponse>({ endpoint: serverEndPoints.subscriptions.active, args, method: 'put' }),
    ChangeSavedCard: (args: ChangeSavedCardArgs) =>
        sendRequest<ChangeSavedCardResponse>({
            endpoint: serverEndPoints.subscriptions.changeSaved,
            args,
            method: 'put',
        }),
    ChangeToNewCard: (args: ChangeToNewCardArgs) =>
        sendRequest<ChangeToNewCardResponse>({
            endpoint: serverEndPoints.subscriptions.changeNew,
            args,
            method: 'put',
        }),
    GetOffersCount: (args: GetOffersCountArgs) =>
        sendRequest<GetOffersCountResponse>({
            endpoint: serverEndPoints.subscriptions.offersCount,
            args,
            method: 'get',
        }),

    cancelSubcription: (args: CancelSubscriptionArgs) =>
        sendRequest<CancelSubscriptionResponse>({
            endpoint: serverEndPoints.subscriptions.cancel,
            args,
            method: 'put',
        }),
    deleteSavedCard: (args: DeleteSavedCardArgs) =>
        sendRequest<DeleteSavedCardResponse>({
            endpoint: serverEndPoints.subscriptions.deleteCard,
            args,
            method: 'delete',
        }),

    plans: {
        add: (args: AddPlanArgs) => sendRequest<AddPlanResponse>({ endpoint: serverEndPoints.plans.add, args }),

        get: (args: GetPlansArgs) =>
            sendRequest<GetPlansResponse>({ endpoint: serverEndPoints.plans.get, args, method: 'get' }),
        delete: ({ _id }: DeletePlanArgs) =>
            sendRequest<DeletePlanResponse>({
                endpoint: serverEndPoints.plans.delete.replace(':id', _id),
                args: {},
                method: 'delete',
            }),
    },
    packs: {
        add: (args: AddPackageArgs) => sendRequest<AddPackageResponse>({ endpoint: serverEndPoints.packs.add, args }),

        get: (args: GetPacksArgs) =>
            sendRequest<GetPacksResponse>({ endpoint: serverEndPoints.packs.get, args, method: 'get' }),
        delete: ({ _id }: DeletePackageArgs) =>
            sendRequest<DeletePackageResponse>({
                endpoint: serverEndPoints.packs.delete.replace(':id', _id),
                args: {},
                method: 'delete',
            }),
    },
}
