import React from 'react'
import { Switch } from 'react-router-dom'
import isAdmin from '../../config/isAdmin'
import AdminCampaignsList from '../../features/AdminCampaigns/CampaignsList'
import AdminsList from '../../features/Admins/AdminsList'
import AdvertisersList from '../../features/Advertisers/AdvertisersList'
import AgenciesList from '../../features/Agencies/AgenciesList'
import AgentsList from '../../features/Agencies/AgentsList'
import CampaignsList from '../../features/Campaigns/CampaignsList'
import CreateCampaign from '../../features/Campaigns/CreateCampaign'
import EndUsersList from '../../features/EndUsers/EndUsersList'
import Points from '../../features/Points/Points'
import RedeemCode from '../../features/Redeem/RedeemCode'
import Regions from '../../features/Regions/Regions'
import AdvertiserSubscriptions from '../../features/Subscriptions/AdvertiserSubscriptions'
import ErrorMessage from '../../features/Subscriptions/Payments/ErrorMessage'
import SuccessMessage from '../../features/Subscriptions/Payments/SuccessMessage'
import SubscriptionsView from '../../features/Subscriptions/SubscriptionsView'
import AdvertiserSignup from '../../features/Users/AdvertiserSignup'
import ForgotPassword from '../../features/Users/ForgotPassword'
import Login from '../../features/Users/Login'
import VideoAdsList from '../../features/VideoAds/VideoAdsList'
import LoginRedirect from '../../pages/LoginRedirect'
import Layout from '../Layout/Layout'
import { RouteObj } from './RouteObj'
import { useRenderRoute } from './useRenderRoute'

export default React.memo(Router)

export const getRoutes = () => (isAdmin ? getAdminRoutes() : getAdvertiserRoutes())

function Router() {
    const els = useRenderRoute(getRoutes())

    return <Switch>{els}</Switch>
}

const getAdminRoutes = () => {
    return [
        { path: '/forgot-password', component: ForgotPassword, auth: 'unauthenticated' },
        { path: '/login', component: Login, auth: 'unauthenticated' },
        { path: '', component: LoginRedirect, auth: 'unauthenticated' },
        {
            path: '/',
            component: Layout,
            auth: 'authenticated',
            children: [
                { path: '/regions', component: Regions, key: 'regions' },
                { path: '/agencies', component: AgenciesList, key: 'agencies' },
                { path: '/agents', component: AgentsList, key: 'agents' },
                { path: '/admins', component: AdminsList, key: 'admins' },
                { path: '/points', component: Points, key: 'points' },
                { path: '/campaigns', component: AdminCampaignsList, key: 'campaigns' },
                { path: '/endUsers', component: EndUsersList, key: 'endUsers' },
                { path: '/subscriptions', component: SubscriptionsView, key: 'subscriptions' },
                { path: '/advertisers', component: AdvertisersList, key: 'advertisers' },
                { path: '/video-ads', component: VideoAdsList, key: 'video-ads' },
            ],
        },
    ] as RouteObj[]
}

const getAdvertiserRoutes = () => {
    return [
        { path: '/signup', component: AdvertiserSignup, auth: 'unauthenticated' },
        { path: '/forgot-password', component: ForgotPassword, auth: 'unauthenticated' },
        { path: '/login', component: Login, auth: 'unauthenticated' },
        { path: '', component: LoginRedirect, auth: 'unauthenticated' },
        {
            path: '/',
            component: Layout,
            auth: 'authenticated',
            children: [
                { path: '/campaigns', component: CampaignsList, key: 'campaigns' },
                { path: '/create-campaigns', component: CreateCampaign, key: 'createCampaigns', hideNavMenu: true },
                { path: '/subscriptions', component: AdvertiserSubscriptions, key: 'subscriptions' },
                { path: '/points', component: Points, key: 'points' },
                { path: '/redeem-code', component: RedeemCode, key: 'redeemCode' },
                { path: '/payment-error', component: ErrorMessage, key: 'paymentError' },
                { path: '/payment-success', component: SuccessMessage, key: 'paymentSuccess' },
            ],
        },
    ] as RouteObj[]
}
