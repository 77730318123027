import { Button, CircularProgress, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { advertisers } from '../../API/advertisers'
import { campaigns } from '../../API/campaigns'
import { offerRedeem } from '../../API/offerRedeem'
import { useAlert } from '../../containers/AlertProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'

interface Props {
    close: () => void
    code: string
    openSuccess: () => void
}

export default React.memo(RedeemDialog)

function RedeemDialog(props: Props) {
    const { close, openSuccess, code } = props
    const [loading, setLoading, stopLoading] = useBoolean()
    const classes = useStyles()

    const alert = useAlert()

    const [data, isLoading] = useAsync(() => offerRedeem.checkRedeem({ code }))

    const [campaignQuery] = useAsync(() => campaigns.getById({ id: data!.offer!.campaignId! }), [!data])

    const [advertiser] = useAsync(() => advertisers.getById({ id: data!.offer!.advertiserId! }), [!data])

    const imagesList = campaignQuery && campaignQuery.campaign ? campaignQuery.campaign.images : []

    const confirmForm = useFormik({
        initialValues: {},
        onSubmit: async () => {
            setLoading()
            const redeemId = data?.offer?._id
            if (!redeemId) {
                return
            }

            const [err, res] = await offerRedeem.updateRedeem({ redeemId, redeemed: true })

            if (err || res?.status === 'error') {
                alert('error', res?.msg ?? 'Something Want Wrong')
                stopLoading()
                return
            }

            stopLoading()
            close()
            openSuccess()
        },
    })
    return (
        <div className={classes.container}>
            {/* @ts-ignore */}
            <Carousel
                activeIndicatorIconButtonProps={{ className: classes.activeIndicator }}
                indicatorIconButtonProps={{ className: classes.indicator }}
                indicatorContainerProps={{
                    style: {
                        position: 'absolute',
                        bottom: 24,
                    },
                }}
            >
                {imagesList.map((x, idx) => {
                    return (
                        <div
                            style={{ width: 800, height: 312, backgroundImage: `url(${x})`, backgroundSize: 'cover' }}
                            key={idx}
                        />
                    )
                })}
            </Carousel>
            <div className={classes.actionsContainer}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <div className={classes.content}>
                        <div className={classes.header}>
                            <img className={classes.avatar} src={advertiser?.advertiser?.avatar} />
                            <div className={classes.text}>
                                <Typography className={classes.name}>{advertiser?.advertiser?.name}</Typography>
                                <Typography className={classes.type}>{campaignQuery?.campaign?.title}</Typography>
                            </div>
                        </div>
                        <Typography className={classes.description}>{campaignQuery?.campaign?.description}</Typography>
                    </div>
                )}
                <div className={classes.buttonsContainer}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={close} className={classes.discardButton} variant="text" color="primary">
                                Discard
                            </Button>
                            <Button
                                onClick={confirmForm.submitForm}
                                className={classes.confirmButton}
                                variant="contained"
                                color="primary"
                            >
                                Redeem
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    activeIndicator: {
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },
    indicator: {
        '&:hover': {
            color: theme.palette.primary.dark,
        },
    },

    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        justifyContent: 'space-between',
        height: 280,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginInlineStart: 16,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginInlineStart: 16,
    },
    name: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    type: {
        marginTop: 8,
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    description: {
        marginTop: 16,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    discardButton: {
        marginInlineEnd: 16,
    },
    confirmButton: {
        color: theme.palette.background.paper,
    },
    avatar: {
        height: 54,
        width: 54,
        borderRadius: 8,
    },
}))
