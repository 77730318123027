import { Button, FormControl, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormikProps } from 'formik'
import React, { useCallback, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { UserType } from 'shared/Interfaces'
import { AddPointArgs } from 'shared/types/pointTypes'
import Loading from '../../../containers/Layout/Loading'
import { categoriesList } from '../data/categoriesList'
import AdvertiserSelectInput from './AdvertiserSelectInput'

interface Props {
    handleClose: () => void
    formik: FormikProps<AddPointArgs>
    isAdvertiserPoint: boolean
    loading: boolean
}

export default React.memo(CreatePointDialog)

function CreatePointDialog(props: Props) {
    const { handleClose, formik, isAdvertiserPoint, loading } = props

    const classes = useStyles()
    const [value, setValue] = useState<UserType | undefined>()
    const onChange = useCallback(
        (newValue: UserType) => {
            setValue(newValue)
            formik.handleChange('advertiserId')(newValue?._id)

            formik.handleChange('name')(newValue?.name)

            if (newValue?.avatar) {
                formik.handleChange('avatar')(newValue?.avatar)
            }
        },
        [formik]
    )

    const { values, errors } = formik
    console.log({ values, errors })

    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>Add Point</Typography>
                <IconButton onClick={handleClose} disabled={loading}>
                    <MdClose />
                </IconButton>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className={classes.textFields}>
                    <TextField
                        disabled={loading}
                        label="Title"
                        onChange={formik.handleChange('title')}
                        variant="filled"
                        className={classes.phoneNumber}
                        value={formik.values.title}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                    />

                    {isAdvertiserPoint ? (
                        <AdvertiserSelectInput
                            onChange={onChange}
                            value={value}
                            loading={loading}
                            error={formik.touched.advertiserId && Boolean(formik.errors.advertiserId)}
                            // helperText={formik.touched.advertiserId && formik.errors.advertiserId}
                        />
                    ) : null}

                    <TextField
                        disabled={loading}
                        label="Code"
                        onChange={formik.handleChange('code')}
                        variant="filled"
                        className={classes.phoneNumber}
                        value={formik.values.code}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                    />
                    <FormControl variant="filled" className={classes.phoneNumber}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            disabled={loading}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            value={formik.values.category}
                            onChange={formik.handleChange('category')}
                        >
                            {categoriesList.map((x, idx) => {
                                return (
                                    <MenuItem key={idx} value={x}>
                                        {x}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.dialogButtons}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Button
                                onClick={handleClose}
                                className={classes.cancelButton}
                                variant="text"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                                onClick={formik.submitForm}
                            >
                                Add Point
                            </Button>
                        </>
                    )}
                </div>
            </form>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    addPoint: {
        position: 'absolute',
        right: 16,
        top: 16,
        zIndex: 999,
        color: theme.palette.background.paper,
        borderRadius: 50,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.default,
        height: 72,
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: `16px 24px`,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
}))
