import { Button, ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsVertical, mdiKeyOutline } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { UserType } from 'shared/Interfaces'
import { PermissionType } from 'shared/permissions'
import { admins } from '../../../API/admins'
import Avatar from '../../../components/Avatar'
import { useAlert } from '../../../containers/AlertProvider'
import useBoolean from '../../../hooks/useBoolean'
import { mdiIcon } from '../../../utils/mdiIcon'
import GenericDialog from '../../Subscriptions/GenericDialog'
import PermissionsDialog from './PermissionsDialog'

const Key = mdiIcon(mdiKeyOutline)

interface Props {
    user: UserType
    regionName: string | undefined
    isPermitted: boolean
}

export default React.memo(AdminCard)
const Dots = mdiIcon(mdiDotsVertical)

function AdminCard(props: Props) {
    const { user, regionName, isPermitted } = props
    const { name, avatar, phoneNumber } = user
    const [permissions, setPermissions] = useState(user.permissions)
    const alert = useAlert()
    const classes = useStyles()

    const [open, setOpen, setClose] = useBoolean(false)
    const [toggleState, openToggle, closeToggle] = useBoolean()
    const [status, setStatus] = useState(user.active!)

    const setPermissionsCB = useCallback((perms: PermissionType[]) => {
        setPermissions(perms)
    }, [])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const toggleAdmin = useCallback(async () => {
        const [err, res] = await admins.toggle({ _id: user._id, status })
        if (err || res?.msg === 'failed') {
            alert('error', `Failed to ${status ? 'Deactivate' : 'Recativate'} Account`)
            closeToggle()
            handleCloseMenu()
            return
        }
        if (res?.msg === 'success') {
            closeToggle()
            handleCloseMenu()
            setTimeout(() => {
                setStatus(!status)
                alert('success', `Account ${status ? 'Dectivated' : 'Recativated'} Successfully`)
            }, 500)
        }
    }, [alert, closeToggle, handleCloseMenu, status, user._id])

    const isSuperAdmin = user.superAdmin
    return (
        <>
            <Dialog onClose={setClose} open={open} classes={{ paper: classes.dialog }}>
                <PermissionsDialog
                    admin={user}
                    close={setClose}
                    permissions={permissions}
                    setPermissions={setPermissionsCB}
                />
            </Dialog>
            <GenericDialog
                handleClose={closeToggle}
                action={status ? 'Deactivate' : 'Recativate'}
                description={`Are you sure you want to ${status ? 'deactivate' : 'recativate'} this account?`}
                additionalInfo={`The account user won't be able to sign in again unless reactivated!`}
                handleConfirm={toggleAdmin}
                open={toggleState}
            />
            {isPermitted ? (
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                    <MenuItem onClick={openToggle}>
                        {status ? <Typography>Deactivate</Typography> : <Typography>Reactivate</Typography>}
                    </MenuItem>
                </Menu>
            ) : null}
            <div className={classes.container}>
                <ButtonBase onClick={handleClick} className={classes.dots}>
                    <Dots />
                </ButtonBase>
                <div className={classes.content}>
                    <Avatar name={name} src={avatar} className={classes.avatar} />
                    <Typography className={classes.fullName}>{name}</Typography>
                    <Typography className={classes.phoneNumber}>{phoneNumber}</Typography>
                    <Typography className={classes.region}>{regionName}</Typography>
                    {status ? null : <Typography className={classes.inactive}>{'(Inactive)'}</Typography>}
                </div>

                <Button
                    disabled={isSuperAdmin || !status || !isPermitted}
                    onClick={setOpen}
                    variant="outlined"
                    color="primary"
                    startIcon={<Key />}
                    className={classes.button}
                >
                    Permissions
                </Button>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 560,
    },
    container: {
        width: 240,
        maxWidth: 240,
        height: 320,
        maxHeight: 320,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    content: {
        marginTop: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1,
    },
    fullName: {
        fontWeight: 500,
        fontSize: 14,
    },
    phoneNumber: {
        fontSize: 12,
        color: theme.palette.text.hint,
        marginBottom: 8,
    },
    region: {
        marginTop: 4,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
    },
    button: {
        width: 'calc(100% - 32px)',
        marginBottom: 16,
    },
    avatar: {
        height: 128,
        width: 128,
        marginBottom: 16,
    },
    main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    dots: {
        position: 'absolute',
        right: 16,
        top: 16,
    },
    statusName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    inactive: {
        fontSize: 12,
        color: theme.palette.error.main,
    },
}))
