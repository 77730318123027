import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { auth } from '../../API/auth'
import OnBoardingBackground from '../../assets/OnBoardingBackground'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import { countryCodeRegex, phoneRegex } from './Login'

const EyeOn = mdiIcon(mdiEyeOutline)
const EyeOff = mdiIcon(mdiEyeOffOutline)

type Step = 'phoneNumber' | 'code' | 'newPassword'

export default React.memo(ForgotPassword)

function ForgotPassword() {
    const classes = useStyles()
    const history = useHistory()
    const alert = useAlert()

    const [step, setStep] = useState<Step>('phoneNumber')

    const [visiblePassword, setVisiblePassword, setPasswordHidden] = useBoolean(false)
    const [loading, setLoading, stopLoading] = useBoolean()

    const phoneForm = useFormik({
        initialValues: { phoneNumber: '', countryCode: '+90' },
        validationSchema: phoneSchema,

        onSubmit: async (values) => {
            setLoading()
            const PN = values.countryCode + values.phoneNumber

            const [checkErr, checkExistResponse] = await auth.checkIsUserExist({ phoneNumber: PN })

            if (!checkExistResponse || checkErr) {
                alert('error', 'An unknown error has occured')
                stopLoading()
                return
            }

            if (checkExistResponse.msg === 'notExist') {
                alert('error', 'This phone number is not registered')
                stopLoading()
                return
            }

            const [err, res] = await auth.sendCode({ phoneNumber: PN })

            if (!res || !res.token) {
                console.error(err)
                alert('error', 'An unknown error has occured')
                stopLoading()

                return
            }

            codeForm.values.token = res.token
            codeForm.values.phoneNumber = PN
            stopLoading()
            setStep('code')
        },
    })

    const codeForm = useFormik({
        initialValues: { code: '', token: '', phoneNumber: '' },
        validationSchema: codeSchema,

        onSubmit: async () => {
            setLoading()

            const [err, res] = await auth.verifyCode(codeForm.values)

            if (!res) {
                console.error(err)
                alert('error', 'An unknown error has occured')
                stopLoading()

                return
            }

            if (res.msg === 'success') {
                passwordForm.values.phoneNumber = codeForm.values.phoneNumber
                setStep('newPassword')
            } else {
                alert('error', 'Code is not correct')
            }
            stopLoading()
        },
    })

    const passwordForm = useFormik({
        initialValues: { password: '', phoneNumber: '' },
        validationSchema: passwordSchema,

        onSubmit: async () => {
            setLoading()

            const [err, res] = await auth.changePassword(passwordForm.values)

            if (!res) {
                console.error(err)
                alert('error', 'An unknown error has occured')
                stopLoading()

                return
            }

            alert('success', 'Successfully changed your password')
            stopLoading()
            setTimeout(() => {
                history.push('/')
            }, 500)
        },
    })

    const formSubmit =
        step === 'phoneNumber'
            ? phoneForm.handleSubmit
            : step === 'code'
            ? codeForm.handleSubmit
            : passwordForm.handleSubmit

    const setPhoneNumber = useCallback(() => setStep('phoneNumber'), [])
    const setCode = useCallback(
        (code: string) => {
            phoneForm.setFieldValue('countryCode', code)
        },
        [phoneForm]
    )
    return (
        <div className={classes.container}>
            <OnBoardingBackground />

            <div className={classes.flex}>
                <div className={classes.body}>
                    <Typography className={classes.title}>Reset Password</Typography>

                    <form onSubmit={formSubmit}>
                        <div className={classes.textFields}>
                            {step === 'phoneNumber' ? (
                                <div>
                                    <Typography className={classes.description}>
                                        {'Type in your phone number to receive a verification code'}
                                    </Typography>

                                    <div className={classes.phoneInput}>
                                        <CountrySearchMenu setCode={setCode} loading={loading} />
                                        <TextField
                                            disabled={loading}
                                            label="Phone Number"
                                            onChange={phoneForm.handleChange('phoneNumber')}
                                            variant="filled"
                                            className={classes.phoneNumber}
                                            value={phoneForm.values.phoneNumber}
                                            error={
                                                phoneForm.touched.phoneNumber && Boolean(phoneForm.errors.phoneNumber)
                                            }
                                            helperText={phoneForm.touched.phoneNumber && phoneForm.errors.phoneNumber}
                                        />
                                    </div>

                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Send Code
                                        </Button>
                                    )}
                                </div>
                            ) : null}

                            {step === 'code' ? (
                                <div>
                                    <Typography className={classes.secondDescription}>
                                        {`A text message with a 6-digital verification code was just sent to ${phoneForm.values.phoneNumber}`}
                                    </Typography>

                                    <Button
                                        onClick={setPhoneNumber}
                                        variant="text"
                                        color="primary"
                                        className={classes.changeButton}
                                    >
                                        Wrong Phone Number?
                                    </Button>

                                    <TextField
                                        disabled={loading}
                                        label="Verification Code"
                                        onChange={codeForm.handleChange('code')}
                                        variant="filled"
                                        className={classes.phoneNumber}
                                        value={codeForm.values.code}
                                        error={codeForm.touched.code && Boolean(codeForm.errors.code)}
                                        helperText={codeForm.touched.code && codeForm.errors.code}
                                    />
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Verify Code
                                        </Button>
                                    )}
                                </div>
                            ) : null}

                            {step === 'newPassword' ? (
                                <div>
                                    <Typography className={classes.description}>
                                        {`Enter your new password below:`}
                                    </Typography>

                                    <TextField
                                        disabled={loading}
                                        className={classes.passwordField}
                                        label="Password"
                                        type={visiblePassword ? 'text' : 'password'}
                                        variant="filled"
                                        onChange={passwordForm.handleChange('password')}
                                        value={passwordForm.values.password}
                                        error={passwordForm.touched.password && Boolean(passwordForm.errors.password)}
                                        helperText={passwordForm.touched.password && passwordForm.errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={visiblePassword ? setPasswordHidden : setVisiblePassword}
                                                >
                                                    {visiblePassword ? <EyeOn /> : <EyeOff />}
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                    {loading ? (
                                        <Loading />
                                    ) : (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                        >
                                            Reset Password
                                        </Button>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
    },
    body: {
        width: 400,
    },
    flex: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',

        flex: 2,
    },
    button: {
        color: theme.palette.background.paper,
        width: '100%',
        marginTop: 16,
    },
    textFields: {
        marginBottom: 40,
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
        flex: 1,
        width: '100%',
    },
    passwordField: {
        marginBottom: 8,
        width: '100%',
    },
    forgotPassword: {
        fontWeight: 400,
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    title: {
        fontWeight: 400,
        fontSize: 24,
        color: theme.palette.text.primary,
    },
    description: {
        fontSize: 12,
        color: theme.palette.text.hint,
        marginBottom: 40,
    },
    secondDescription: {
        fontSize: 12,
        color: theme.palette.text.hint,
        marginBottom: 8,
    },
    changeButton: {
        marginBottom: 40,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))

const phoneSchema = yup.object({
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),

    phoneNumber: yup.string().matches(phoneRegex, 'Phone number is not valid').required('Phone Number is required'),
})
const codeSchema = yup.object({
    code: yup.string().length(6, 'The code should be 6 numbers in length').required('Password is required'),
})
const passwordSchema = yup.object({
    password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
})
