/* eslint-disable react/no-multi-comp */
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiArrowLeft } from '@mdi/js'
import valid from 'card-validator'
import Cleave from 'cleave.js/react'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import { SubscriptionType } from 'shared/Interfaces'
import * as yup from 'yup'
import { subscriptions } from '../../../API/subscriptions'
import { useAlert } from '../../../containers/AlertProvider'
import { mdiIcon } from '../../../utils/mdiIcon'
import { currencySymbol } from '../PlanCard'

interface Props {
    type: 'plan' | 'package'
    price: number | undefined
    currency: 'USD' | 'TRY' | 'EUR' | undefined
    goToOld: () => void
    _id: string
    setUrl: (url: string) => void
    cardsLength: boolean
    current?: SubscriptionType
    closeSelf: () => void
}
const Arrow = mdiIcon(mdiArrowLeft)
export default React.memo(NewPaymentInfo)

function NewPaymentInfo(props: Props) {
    const { type, price, currency, goToOld, _id, setUrl, cardsLength, current, closeSelf } = props
    const alert = useAlert()
    const [focus, setFocus] = useState<'name' | 'number' | 'expiry' | 'cvc'>()

    const handleInputFocus = useCallback((e: any) => {
        setFocus(e.target.name)
    }, [])
    const formik = useFormik({
        initialValues: {
            authorised: false,
            saveCard: false,
            cvc: '',
            expiry: '',
            name: '',
            number: '',

            // authorised: true,
            // saveCard: false,
            // cvc: '000',
            // expiry: '12/26',
            // name: 'ZİRAAT BANKASI',
            // number: '4546711234567894',
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            if (current) {
                const { expiry, name, number } = values
                const [err, res] = await subscriptions.ChangeToNewCard({
                    cardType: valid.creditCardType.default(values.number)[0].type.toLowerCase(),
                    expiry,
                    name,
                    number,
                    subId: current._id,
                })
                if (!res) {
                    console.error(err)
                    return
                }

                if (res.msg) {
                    formik.resetForm()
                    alert('success', 'Card Changed Successfully!')
                    closeSelf()
                } else {
                    alert('error', 'Error changing card!')
                }
                return
            }
            const [err, res] = await subscriptions.buy({
                authorised: values.authorised ? 'true' : 'false',
                saveCard: type === 'plan' ? 'true' : values.saveCard ? 'true' : 'false',
                cvc: values.cvc,
                expiry: values.expiry,
                name: values.name,
                number: values.number,
                cardType: valid.creditCardType.default(values.number)[0].type.toLowerCase(),
                _id,
                type,
                active: '',
            })
            if (!res) {
                console.error(err)
                return
            }

            if (res.msg != 'pending-transaction') {
                const url = res.msg.replace('amp;', '')
                setUrl(url)
                formik.resetForm()
            } else {
                if (res.msg == 'pending-transaction') {
                    alert('error', 'Pending transaction under process!')
                } else {
                    alert('error', 'Error buying package!')
                }
            }
            return
        },
    })
    const classes = useStyles()

    const changeBox = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const name = event.target.name
            const value = name == 'authorised' ? formik.values.authorised : formik.values.saveCard
            formik.setFieldValue(name, !value)
        },
        [formik]
    )

    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <Cards
                    cvc={formik.values.cvc}
                    expiry={formik.values.expiry}
                    name={formik.values.name}
                    number={formik.values.number}
                    preview
                    focused={focus}
                />
                <div className={classes.textFields}>
                    <TextField
                        InputProps={{
                            inputComponent: CreditCardField,
                        }}
                        id="creditcard"
                        label="Card Number"
                        name="number"
                        onFocus={handleInputFocus}
                        onChange={formik.handleChange('number')}
                        value={formik.values.number}
                        variant="filled"
                        className={classes.number}
                        error={formik.touched.number && Boolean(formik.errors.number)}
                        helperText={formik.touched.number && formik.errors.number}
                    />
                    <TextField
                        label="Card Holder Name"
                        name="name"
                        onFocus={handleInputFocus}
                        onChange={formik.handleChange('name')}
                        value={formik.values.name}
                        variant="filled"
                        className={classes.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />
                    <div className={classes.expiryCvc}>
                        <TextField
                            InputProps={{
                                inputComponent: ExpiryField,
                            }}
                            label="Valid Thru"
                            name="expiry"
                            onFocus={handleInputFocus}
                            onChange={formik.handleChange('expiry')}
                            value={formik.values.expiry}
                            variant="filled"
                            className={classes.expiry}
                            error={formik.touched.expiry && Boolean(formik.errors.expiry)}
                            helperText={formik.touched.expiry && formik.errors.expiry}
                        />
                        <TextField
                            label="CVC/CVV"
                            name="cvc"
                            onFocus={handleInputFocus}
                            onChange={formik.handleChange('cvc')}
                            value={formik.values.cvc}
                            variant="filled"
                            className={classes.cvc}
                            error={formik.touched.cvc && Boolean(formik.errors.cvc)}
                            helperText={formik.touched.cvc && formik.errors.cvc}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.options}>
                <FormControlLabel
                    className={classes.authoriseForm}
                    name="authorised"
                    control={<Checkbox checked={formik.values.authorised} onChange={changeBox} color="primary" />}
                    label={
                        <Typography className={classes.authorise}>
                            {type == 'plan'
                                ? `I authorise Param & Carrot to automatically charge me the recurring payment and applicable tax
                                until I cancel.`
                                : `I authorise Param & Carrot to charge me once for my payment.`}
                        </Typography>
                    }
                />

                {type === 'plan' ? null : (
                    <FormControlLabel
                        className={classes.saveCardForm}
                        name="saveCard"
                        control={<Checkbox checked={formik.values.saveCard} onChange={changeBox} color="primary" />}
                        label={
                            <Typography className={classes.saveCard}>
                                Save payment account for future purchases.
                            </Typography>
                        }
                    />
                )}
            </div>
            <div className={classes.footer}>
                {cardsLength ? (
                    <div />
                ) : (
                    <div className={classes.goToOld} onClick={goToOld}>
                        <Arrow className={classes.arrow} />
                        <Typography className={classes.back}>Back to saved cards</Typography>
                    </div>
                )}
                <div className={classes.totalPay}>
                    {current ? null : <Typography className={classes.total}>Total</Typography>}
                    {current ? null : (
                        <Typography className={classes.price}>
                            {currencySymbol(currency!)}
                            {price}
                        </Typography>
                    )}
                    <Button
                        className={classes.payButton}
                        variant="contained"
                        color="primary"
                        disabled={!formik.values.authorised}
                        onClick={formik.submitForm}
                    >
                        {current ? `Change` : `Pay Now`}
                    </Button>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        userSelect: 'none',
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 24,
        width: '100%',
        justifyContent: 'space-between',
    },
    textFields: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 8,
    },
    expiryCvc: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    number: {
        marginBottom: 16,
    },
    name: {
        marginBottom: 16,
    },
    expiry: {
        width: 180,
    },
    cvc: {
        width: 120,
    },
    options: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    authoriseForm: {
        marginTop: 8,
        width: 'fit-content',
    },
    saveCardForm: {
        width: 'fit-content',
        marginTop: 8,
    },
    authorise: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        width: 270,
        marginTop: 12,
    },
    saveCard: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        width: 280,
    },
    footer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        userSelect: 'none',
    },
    goToOld: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
            '& $arrow': {
                color: theme.palette.primary.dark,
            },
            '& $back': {
                color: theme.palette.primary.dark,
            },
        },
    },
    arrow: {
        height: 20,
        color: theme.palette.primary.main,
        marginInlineEnd: 8,
        transition: theme.transitions.create('color'),
    },
    back: {
        transition: theme.transitions.create('color'),
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500,
    },
    totalPay: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    total: {
        color: theme.palette.text.hint,
        fontSize: 16,
        fontWeight: 500,
        marginInlineEnd: 8,
    },
    price: {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 500,
        marginInlineEnd: 16,
    },

    payButton: {
        color: theme.palette.background.paper,
    },
}))
const schema = yup.object({
    name: yup.string().min(3, 'The name should be at least 3 characters').required('Name is required'),
    number: yup
        .string()
        .test('test-number', 'Card number is invalid', (value) => valid.number(value).isValid)
        .required('Credit card number is required'),
    expiry: yup
        .string()
        .test('test-expiry', 'Expiry date is invalid', (value) => valid.expirationDate(value).isValid)
        .required('Expiry date is required'),
    cvc: yup
        .number()
        // .test('test-cvc', 'Invalid CVC/CVV', (value) => valid.cvv(value).isValid)
        .required('CVC/CVV is required')
        .typeError('Invalid format'),
})

function CreditCardField(props: any) {
    const { inputRef, ...other } = props
    return <Cleave {...other} ref={inputRef} options={{ creditCard: true }} />
}
function ExpiryField(props: any) {
    const { inputRef, ...other } = props
    return <Cleave {...other} ref={inputRef} options={{ date: true, datePattern: ['m', 'y'] }} />
}
