import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'
import { RouteObj } from './RouteObj'
import useAuthMode from './useAuthMode'

export function useRenderRoute(routes: RouteObj[]) {
    const authMode = useAuthMode()

    const renderRoute = (route: RouteObj, index: number) => {
        if (route.auth && route.auth !== authMode) {
            return null
        }

        return (
            <Route key={route.key ?? index} path={route.path} component={route.component} exact={route.exact}>
                <Switch>{route.children?.map(renderRoute)}</Switch>
            </Route>
        )
    }

    return routes.map(renderRoute)
}
