import { IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import ArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import LocationIcon from '@material-ui/icons/LocationOnRounded'
import AdvertiserIcon from '@material-ui/icons/Store'
import React, { useCallback } from 'react'

interface Props {
    handleClose: () => void
    setPointType: (type: 'generalPoint' | 'advertiser') => void
}

export default React.memo(PointTypeSelectDialog)

function PointTypeSelectDialog(props: Props) {
    const { handleClose, setPointType } = props

    const classes = useStyles()

    const onGeneral = useCallback(() => setPointType('generalPoint'), [setPointType])
    const onAdvertiser = useCallback(() => setPointType('advertiser'), [setPointType])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography className={classes.title}>Select Point Type</Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>

            <div className={classes.item} onClick={onGeneral}>
                <div className={classes.info}>
                    <LocationIcon />
                    <Typography className={classes.itemText}>General Point</Typography>
                </div>

                <ArrowIcon />
            </div>

            <div className={classes.item} onClick={onAdvertiser}>
                <div className={classes.info}>
                    <AdvertiserIcon />
                    <Typography className={classes.itemText}>Advertiser Point</Typography>
                </div>

                <ArrowIcon />
            </div>

            <div className={classes.placeholder} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 72,
        padding: '0px 16px',
        background: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 24,
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    item: {
        height: 56,
        margin: '0px 24px',
        marginBottom: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 8px',
        cursor: 'pointer',
        transition: theme.transitions.create(['background']),
        '&:hover': { background: theme.palette.action.hover },
    },
    placeholder: {
        minHeight: 32,
    },
    itemText: {
        marginLeft: 16,
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
}))
