import { Button, CircularProgress, Dialog, TextField, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import React, { useCallback } from 'react'
import * as yup from 'yup'
import { offerRedeem } from '../../API/offerRedeem'
import useBoolean from '../../hooks/useBoolean'
import RedeemDialog from './RedeemDialog'
import SuccessDialog from './SuccessDialog'
interface Props {}

export default React.memo(RedeemCode)

function RedeemCode(props: Props) {
    const {} = props
    const classes = useStyles()
    const [loading, setLoading, stopLoading] = useBoolean()
    const [redeemDialog, openRedeem, closeRedeem] = useBoolean()
    const [successDialog, openSuccess, closeSuccess] = useBoolean()

    const codeForm = useFormik({
        initialValues: { code: '' },
        validationSchema: schema,
        onSubmit: async () => {
            setLoading()
            setTimeout(() => {
                openRedeem()
                stopLoading()
            }, 500)
        },
    })

    const onCodeChange = useCallback(
        (x) => {
            const isDeleting = x.target.value.length < codeForm.values.code.length

            if (x.target.value.length === 23) {
                codeForm.setFieldTouched('code', true)
            }

            const dashNumbers = isDeleting ? [] : [5, 11, 17]
            const newText = dashNumbers.includes(x.target.value.length)
                ? x.target.value.toUpperCase() + '-'
                : x.target.value.toUpperCase()

            codeForm.setFieldValue('code', newText)
        },
        [codeForm]
    )

    const onCheckPress = useCallback(async () => {
        const [err, res] = await offerRedeem.checkRedeem({ code: codeForm.values.code })

        if (err || res?.status == 'error') {
            codeForm.setFieldError('code', 'invalid Redeem Code')

            return
        }

        if (res && isBooleanTrue(res.offer?.redeemed)) {
            codeForm.setFieldError('code', 'This Redeem Code is Already used')
            return
        }
        openRedeem()
    }, [codeForm, openRedeem])

    return (
        <div className={classes.container}>
            <Dialog onClose={closeRedeem} open={redeemDialog} classes={{ paper: classes.dialog }}>
                <RedeemDialog close={closeRedeem} code={codeForm.values.code} openSuccess={openSuccess} />
            </Dialog>
            <Dialog onClose={closeSuccess} open={successDialog} classes={{ paper: classes.successDialog }}>
                <SuccessDialog close={closeSuccess} />
            </Dialog>
            <div className={classes.content}>
                <Typography className={classes.redeemCode}>Redeem Code</Typography>
                <Typography className={classes.description}>
                    Make sure the code format is like the following :
                </Typography>
                <Typography className={classes.descriptionCode}>XXXXX-XXXXX-XXXXX-XXXXX</Typography>
                <div className={classes.form}>
                    <TextField
                        disabled={loading}
                        label="Redeem Code"
                        onChange={onCodeChange}
                        variant="filled"
                        className={classes.codeInput}
                        value={codeForm.values.code}
                        error={codeForm.touched.code && !!codeForm.errors.code}
                        helperText={codeForm.touched.code && codeForm.errors.code}
                        inputProps={{ maxLength: 23 }}
                    />
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Button
                            className={classes.checkButton}
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={onCheckPress}
                        >
                            Check
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    content: {
        maxWidth: 400,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    redeemCode: {
        fontWeight: 400,
        fontSize: 24,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    description: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.hint,
        textAlign: 'center',
    },
    descriptionCode: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.hint,
        marginBottom: 40,
        textAlign: 'center',
    },
    form: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    codeInput: {
        width: '100%',
        marginBottom: 40,
    },
    checkButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 800,
        maxWidth: 800,
        height: 640,
    },
    successDialog: {
        width: 400,
        maxWidth: 400,
        height: 240,
    },
}))

const codeRegex = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/
const schema = yup.object({
    code: yup.string().matches(codeRegex, 'Code format should be XXXXX-XXXXX-XXXXX-XXXXX').required('Code is required'),
})

const isBooleanTrue = (value: any) => {
    return typeof value === 'boolean' && value === true
}
