import { IconButton, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { PointType, UserType } from 'shared/Interfaces'
import { AddPointArgs } from 'shared/types/pointTypes'
import * as yup from 'yup'
import { advertisers } from '../../../API/advertisers'
import { points as pointsApi } from '../../../API/points'
import { useAlert } from '../../../containers/AlertProvider'
import Loading from '../../../containers/Layout/Loading'
import { useUser } from '../../../containers/UserProvider'
import useAsync from '../../../hooks/useAsync'
import useBoolean from '../../../hooks/useBoolean'
import { categoriesList } from '../data/categoriesList'

interface Props {
    handleClose: () => void
    open: boolean
    point: PointType
    onEditSuccess: (point: PointType) => void
}

export default React.memo(EditDialog)

function EditDialog(props: Props) {
    const { handleClose, open, point } = props

    const classes = useStyles()
    const alert = useAlert()

    const user = useUser()
    const region = user.user?.regionId

    const [users] = useAsync(() => advertisers.getAdvertisers({ region: region }), [!region])

    const selectedUser = users?.advertisers.find((x) => x._id == point.advertiserId)

    const [value, setValue] = useState<UserType | undefined>(selectedUser)

    const [loading, setLoading, stopLoading] = useBoolean()

    const formik = useFormik<AddPointArgs>({
        initialValues: {
            code: point.code,
            enc: undefined,
            lat: point.lat,
            lng: point.lng,
            category: point.category,
            region: point.region,
            title: point.title,
            advertiserId: point.advertiserId,
            avatar: point.avatar,
            belongTo: point.belongTo,
        },
        validationSchema: advPointSchema,

        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await pointsApi.edit(values, point._id)

            if (!res) {
                console.error(err)
                stopLoading()

                return
            }

            if (res.msg === 'success') {
                alert('success', 'Point edited successfully')
                props.onEditSuccess({ ...point, ...values})
            } else {
                alert('error', 'Error editing point!')
            }
            stopLoading()
            handleClose()
        },
    })

    useEffect(() => {
        if (selectedUser) {
            setValue(selectedUser)
        }
    }, [selectedUser])

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                className={classes.phoneNumber}
                label="Advertiser"
                variant="filled"
                error={formik.touched.advertiserId && Boolean(formik.errors.advertiserId)}
                helperText={formik.touched.advertiserId && formik.errors.advertiserId}
            />
        ),
        [classes.phoneNumber, formik.errors.advertiserId, formik.touched.advertiserId]
    )

    const onChange = useCallback(
        (_, newValue: any) => {
            setValue(newValue)
            formik.handleChange('advertiserId')(newValue?._id)
            formik.handleChange('name')(newValue?.name)
            if (newValue?.avatar) {
                formik.handleChange('avatar')(newValue?.avatar)
            }
        },
        [formik]
    )

    const getOptionLabel = useCallback((x) => x.name ?? '', [])

    const isAdvertiser = !!point.advertiserId

    if (!users) {
        return <Loading />
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <div className={classes.dialogTitleContainer}>
                <Typography className={classes.dialogTitle}>Edit Point</Typography>
                <IconButton onClick={handleClose} disabled={loading}>
                    <MdClose />
                </IconButton>
            </div>

            <form onSubmit={formik.handleSubmit}>
                <div className={classes.textFields}>
                    <TextField
                        label="Title"
                        onChange={formik.handleChange('title')}
                        variant="filled"
                        className={classes.phoneNumber}
                        value={formik.values.title}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        disabled={loading}
                    />

                    {isAdvertiser && !!value && users ? (
                        <Autocomplete
                            getOptionLabel={getOptionLabel}
                            value={value}
                            onChange={onChange}
                            options={users.advertisers}
                            renderInput={renderInput}
                            disabled={loading}
                        />
                    ) : null}

                    <TextField
                        label="Code"
                        onChange={formik.handleChange('code')}
                        variant="filled"
                        className={classes.phoneNumber}
                        value={formik.values.code}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        disabled={loading}
                    />
                    <FormControl variant="filled" className={classes.phoneNumber}>
                        <InputLabel>Category</InputLabel>
                        <Select
                            disabled={loading}
                            error={formik.touched.category && Boolean(formik.errors.category)}
                            value={formik.values.category}
                            onChange={formik.handleChange('category')}
                        >
                            {categoriesList.map((x, idx) => {
                                return (
                                    <MenuItem key={idx} value={x}>
                                        {x}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.dialogButtons}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Button
                                onClick={handleClose}
                                className={classes.cancelButton}
                                variant="text"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                            >
                                Edit Point
                            </Button>
                        </>
                    )}
                </div>
            </form>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    addPoint: {
        position: 'absolute',
        right: 16,
        top: 16,
        zIndex: 999,
        color: theme.palette.background.paper,
        borderRadius: 50,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.default,
        height: 72,
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: `16px 24px`,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
}))
const pointArgs = {
    code: yup.string().required('Code is required'),
    lat: yup.number().required('Region is required'),
    lng: yup.number().required('Region is required'),
    category: yup.string().required('Category is required'),
    region: yup.string().required('Region is required'),
    title: yup.string().required('Region is required'),
}

const advPointSchema = yup.object({
    ...pointArgs,
    advertiserId: yup.string().required('Advertiser is required'),
})
