import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormikProps } from 'formik'
import React from 'react'
import isNumInput from '../../../utils/isNumInput'
import { CreateCampaignForm } from '../CreateCampaign'

interface Props {
    form: FormikProps<CreateCampaignForm>
    loading: boolean
}

export default React.memo(EditTargetedAudience)

function EditTargetedAudience(props: Props) {
    const { form, loading } = props

    const classes = useStyles()
    isNumInput('min-age')
    isNumInput('max-age')
    return (
        <div className={classes.container}>
            <div className={classes.formContainer}>
                <InputLabel className={classes.ageLabel}>Age Between</InputLabel>
                <div className={classes.topFormInputs}>
                    <TextField
                        disabled={loading}
                        className={classes.titleInput}
                        variant="filled"
                        label="Min Age"
                        id="min-age"
                        type='number'

                        color="primary"
                        required
                        onChange={form.handleChange(`filters['ageFrom']`)}
                        value={form.values.filters.ageFrom ?? ''}
                    />
                    <div className={classes.divider}>
                        <b className={classes.andText}>And</b>
                    </div>

                    <TextField
                        disabled={loading}
                        className={classes.titleInput}
                        label="Max Age"
                        variant="filled"
                        id="max-age"
                        type='number'
                        required
                        color="primary"
                        onChange={form.handleChange(`filters['ageUntil']`)}
                        value={form.values.filters.ageUntil ?? ''}
                    />
                </div>

                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel required id="demo-simple-select-label">
                        Sex
                    </InputLabel>

                    <Select
                        disabled={loading}
                        value={form.values.filters.gender}
                        onChange={form.handleChange(`filters['gender']`)}
                        variant="filled"
                        required
                        label="Type"
                        className={classes.select}
                    >
                        <MenuItem value="male">Male</MenuItem>

                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="both">Both</MenuItem>
                    </Select>
                </FormControl>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
    formContainer: {},
    titleInput: {
        display: 'flex',
        flex: 1,
        background: 'transparent',
    },
    topFormInputs: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'space-between',
    },
    select: {
        display: 'flex',
        minWidth: 200,
    },
    formControl: {
        minWidth: 200,
        display: 'flex',

        marginTop: 24,
    },
    descriptionInput: {
        marginTop: 24,
        flex: 1,
        display: 'flex',
    },
    textField: {
        flex: 1,
        display: 'flex',
    },
    collectTimeContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 24,
    },
    divider: {
        display: 'flex',
        minWidth: 40,
        alignItems: 'center',
        justifyContent: 'center',
    },
    smallImageSelectorContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8,
        margin: '0px 16px',
    },
    imagesSelectContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    inputRoot: {
        backgroundColor: '#FFF',
    },
    ageLabel: {
        color: theme.palette.text.primary,
        marginBottom: 12,
        fontWeight: 500,
    },
    andText: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 14,
    },
}))
