import { Divider, IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { PlanType, SubscriptionType } from 'shared/Interfaces'
import { subscriptions } from '../../../API/subscriptions'
import Loading from '../../../containers/Layout/Loading'
import useAsync from '../../../hooks/useAsync'
import useBoolean from '../../../hooks/useBoolean'
import NewPaymentInfo from './NewPaymentInfo'
import PlanInfo from './PlanInfo'
import SavedPaymentInfo from './SavedPaymentInfo'
interface Props {
    close: () => void
    plan: PlanType
    current: SubscriptionType
}

export default React.memo(PaymentDialog)

function PaymentDialog(props: Props) {
    const { close, plan, current } = props
    const classes = useStyles()
    const [paymentState, oldPayment, newPayment] = useBoolean()
    const [loadedCards, cardsLoading] = useAsync(() => subscriptions.getSavedCards({}))
    const cardsLength = loadedCards?.data.length == 0 || loadedCards?.data.length == undefined
    useEffect(() => {
        if (cardsLength) {
            newPayment()
        } else {
            oldPayment()
        }
    }, [cardsLength, newPayment, oldPayment])
    const [url, setUrl] = useState<string | undefined>(undefined)
    const eventListener = useCallback((node: HTMLElement, done: () => void) => {
        node.addEventListener('transitionend', done, false)
    }, [])
    
    const { _id, cost, currency } = plan
    if (cardsLoading) {
        return <Loading />
    }
    return (
        <div className={classes.container}>
            <div className={classes.sideBar}>
                <PlanInfo plan={plan!} />
            </div>
            <div className={classes.payment}>
                <div className={classes.header}>
                    <div className={classes.titleClose}>
                        <Typography className={classes.title}>{`Change Payment Method`}</Typography>
                        <IconButton onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography className={classes.descriptions}>
                        {`We won't recharge you now for changing the payment method. You will be charged when your payment
                        time comes.`}
                    </Typography>
                </div>
                <Divider style={{ marginTop: 24 }} />
                <SwitchTransition>
                    <CSSTransition key={paymentState.toString()} addEndListener={eventListener} classNames="fade">
                        {url ? (
                            <iframe src={url} height="100%" frameBorder="none" id="iframe-id" />
                        ) : paymentState ? (
                            <SavedPaymentInfo
                                goToNew={newPayment}
                                type={'plan'}
                                price={cost}
                                currency={currency}
                                _id={_id!}
                                setUrl={setUrl}
                                savedCards={loadedCards!.data}
                                current={current}
                                closeSelf={close}
                            />
                        ) : (
                            <NewPaymentInfo
                                type={'plan'}
                                price={cost}
                                currency={currency}
                                goToOld={oldPayment}
                                _id={_id!}
                                setUrl={setUrl}
                                cardsLength={cardsLength}
                                current={current}
                                closeSelf={close}
                            />
                        )}
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        userSelect: 'none',
    },

    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        width: 256,
        background: theme.palette.background.default,
        height: '100%',
    },
    payment: { display: 'flex', flexDirection: 'column', padding: 24, width: '100%' },
    header: { width: '100%' },
    titleClose: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    descriptions: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        width: 400,
        lineHeight: '16px',
    },
}))
