import { Button, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormikProps } from 'formik'
import { sortBy } from 'lodash'
import React, { useMemo } from 'react'
import { MdEdit } from 'react-icons/md'
import { points } from '../../../API/points'
import Loading from '../../../containers/Layout/Loading'
import { useUser } from '../../../containers/UserProvider'
import useAsync from '../../../hooks/useAsync'
import useBoolean from '../../../hooks/useBoolean'
import { CreateCampaignForm } from '../CreateCampaign'
import EditPointsDialog from './EditPointsDialog'
import PointViewItem from './PointViewItem'

interface Props {
    form: FormikProps<CreateCampaignForm>
    loading: boolean
    totalCount?: number | 'unlimited'
    chosenOffers: number
}

export default React.memo(EditCampaignPoints)

function EditCampaignPoints(props: Props) {
    const { form, loading, totalCount, chosenOffers } = props

    const classes = useStyles()
    const user = useUser()

    const userRegion = user.user?.regionId

    const [mapVisible, setVisible, setHidden] = useBoolean()

    const [loadedPoints] = useAsync(() => points.getByRegion({ region: userRegion }), [!userRegion])

    const sortedPoints = useMemo(
        () => sortBy(loadedPoints?.points ?? [], (x) => x.title).reverse(),
        [loadedPoints?.points]
    )
    const validOffers = totalCount! < chosenOffers ? false : true
    const validNumber = totalCount === 'unlimited' ? true : validOffers

    return (
        <div className={classes.container}>
            <EditPointsDialog setHidden={setHidden} visible={mapVisible} form={form} />

            {!loadedPoints ? (
                <Loading />
            ) : (
                <>
                    <div className={classes.header}>
                        <h2>Points</h2>

                        <Button
                            disabled={loading}
                            className={classes.ediButton}
                            onClick={setVisible}
                            variant="contained"
                            color="primary"
                            startIcon={<MdEdit />}
                        >
                            Edit Points
                        </Button>
                    </div>
                    <div>
                        <Typography className={classes.availableOffers}>Available Offers: {totalCount}</Typography>
                        <Typography className={validNumber ? classes.availableOffers : classes.invalidOffers}>
                            Amount Of Chosen Offers: {chosenOffers}
                        </Typography>
                    </div>
                    <div>
                        {sortedPoints.map((point) => {
                            return <PointViewItem point={point} key={point._id} form={form} />
                        })}
                    </div>
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
    ediButton: {
        color: '#FFF',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    availableOffers: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        margin: '8px 0px'
    },
    invalidOffers: {
        color: theme.palette.error.main,
        fontWeight: 500,
    },
}))
