/* eslint-disable react/no-multi-comp */
import { Accordion, AccordionDetails, AccordionSummary, Button, Switch, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import { MdArrowDropDown, MdClose } from 'react-icons/md'
import { UserType } from 'shared/Interfaces'
import { PermissionType } from 'shared/permissions'
import { agencies } from '../../API/agencies'
import Avatar from '../../components/Avatar'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'

interface Props {
    agent: UserType
    close: () => void
    permissions: PermissionType[]
    setPermissions: (perms: PermissionType[]) => void
}

export default React.memo(PermissionsDialog)

function PermissionsDialog(props: Props) {
    const { close, agent, permissions, setPermissions } = props

    const classes = useStyles()
    const alert = useAlert()

    const [expanded, setExpanded] = useState<string | false>(false)

    const [loading, setLoading, closeLoading] = useBoolean()

    const formik = useFormik<Pick<UserType, 'permissions'>>({
        initialValues: { permissions: permissions },

        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await agencies.changePermssions({ _id: agent._id, permissions: values.permissions })
            if (res?.msg === 'success') {
                setPermissions(values.permissions)
            }
            if (err) {
                closeLoading()
                alert('error', 'Error changing permissions')

                return
            }

            closeLoading()
            alert('success', 'Changed permissions successfully')
            close()
        },
    })

    const handleChange = useCallback(
        (panel: string) => (_: any, isExpanded: boolean) => setExpanded(isExpanded ? panel : false),
        []
    )
    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <div className={classes.adminInfo}>
                    <Avatar name={agent?.name} src={agent?.avatar} className={classes.miniAvatar} />

                    <div className={classes.adminNameRegion}>
                        <Typography className={classes.dialogName}>{agent?.name}</Typography>
                        <Typography className={classes.dialogRegion}>{agent?.regionId}</Typography>
                    </div>
                </div>

                <IconButton onClick={close}>
                    <MdClose />
                </IconButton>
            </div>

            <div className={classes.dialogContent}>
                <PermsAccordion expanded={expanded} handleChange={handleChange('End Users')} title={'End Users'}>
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Manage End Users"
                        desc="Manage End Users"
                        perm="manageEndUsers"
                    />
                </PermsAccordion>
                <PermsAccordion expanded={expanded} handleChange={handleChange('User')} title={'User'}>
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Manage Agency Users"
                        desc="Manage Agency Users"
                        perm="manageAgencyUsers"
                    />
                    <PermissionTemplate
                        title="Manage Advertiser Users"
                        desc="Manage Advertiser Users"
                        perm="manageAdvertiserUsers"
                        formik={formik}
                        permissions={formik.values.permissions}
                    />
                </PermsAccordion>
                <PermsAccordion expanded={expanded} handleChange={handleChange('Campaign')} title={'Campaign'}>
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Manage Campaigns"
                        desc="Manage Campaigns"
                        perm="manageCampaigns"
                    />
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Approve Campaigns"
                        desc="Approve Campaigns"
                        perm="approveCampaigns"
                    />
                    <PermissionTemplate
                        title="Toggle Active Campaign"
                        desc="Toggle Active Campaign"
                        perm="toggleActiveCampaign"
                        formik={formik}
                        permissions={formik.values.permissions}
                    />
                </PermsAccordion>
                <PermsAccordion expanded={expanded} handleChange={handleChange('Point')} title={'Point'}>
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Manage Points"
                        desc="Manage Points"
                        perm="managePoints"
                    />
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Add Points"
                        desc="Add Points"
                        perm="addPoints"
                    />
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Toggle Active Points"
                        desc="Toggle Active Points"
                        perm="toggleActivePoints"
                    />
                </PermsAccordion>
                <PermsAccordion expanded={expanded} handleChange={handleChange('Advertiser')} title={'Advertiser'}>
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Add Advertisers"
                        desc="Add Advertisers"
                        perm="addAdvertisers"
                    />
                    <PermissionTemplate
                        title="Toggle Active Advertisers"
                        desc="Toggle Active Advertisers"
                        perm="toggleActiveAdvertisers"
                        formik={formik}
                        permissions={formik.values.permissions}
                    />
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Approve Advertisers"
                        desc="Approve Advertisers"
                        perm="approveAdvertisers"
                    />
                </PermsAccordion>
                <PermsAccordion
                    expanded={expanded}
                    handleChange={handleChange('Subscriptions & Packages')}
                    title={'Subscriptions & Packages'}
                >
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Manage Subscriptions"
                        desc="Manage Subscriptions"
                        perm="manageSubscriptions"
                    />
                    <PermissionTemplate
                        permissions={formik.values.permissions}
                        formik={formik}
                        title="Add Subs./Pack."
                        desc="Add Subs./Pack."
                        perm="addSubsPack"
                    />
                    <PermissionTemplate
                        title="Toggle Active Subs./Pack."
                        desc="Toggle Active Subs./Pack."
                        perm="toggleActiveSubsPack"
                        formik={formik}
                        permissions={formik.values.permissions}
                    />
                </PermsAccordion>
            </div>

            <div className={classes.dialogButtons}>
                <form onSubmit={formik.handleSubmit}>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <>
                            <Button onClick={close} className={classes.cancelButton} variant="text" color="primary">
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                className={classes.addRegionButton}
                                variant="contained"
                                color="primary"
                            >
                                Save Changes
                            </Button>
                        </>
                    )}
                </form>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 240,
        height: 320,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,

        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fullName: {
        marginTop: 16,
        fontWeight: 500,
        fontSize: 14,
    },
    region: {
        marginTop: 4,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
    },
    button: {
        width: '100%',
    },
    avatar: {
        height: 128,
        width: 128,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: 16,
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    adminInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    adminNameRegion: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogName: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 14,
    },
    dialogRegion: {
        color: theme.palette.text.secondary,
        fontWeight: 400,
        fontSize: 12,
    },
    miniAvatar: {
        height: 40,
        width: 40,
        marginInlineEnd: 16,
    },
    dialogContent: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    lol: {
        backgroundColor: theme.palette.background.default,
    },
    xd: {
        backgroundColor: theme.palette.background.default,
        padding: '0px 24px 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    permContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    permText: {
        display: 'flex',
        flexDirection: 'column',
    },
    permTitle: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    permDesc: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    aa: {
        boxShadow: 'none',
        borderRadius: 9,
    },
}))

// eslint-disable-next-line react/no-multi-comp
function PermsAccordion(props: {
    expanded: string | false
    handleChange: (event: React.ChangeEvent<{}>, expanded: boolean) => void
    title: string
    children?: React.ReactNode
}) {
    const classes = useStyles()

    return (
        <Accordion
            expanded={props.expanded === props.title}
            onChange={props.handleChange}
            classes={{ root: classes.aa }}
        >
            <AccordionSummary expandIcon={<MdArrowDropDown />} className={classes.lol}>
                <Typography>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.xd}>{props.children}</AccordionDetails>
        </Accordion>
    )
}

function PermissionTemplate(props: {
    title: string
    desc: string
    perm: PermissionType
    permissions: PermissionType[] | undefined
    formik: any
}) {
    const classes = useStyles()

    const perm = props.perm
    const permissions = props.permissions

    const checked = permissions?.includes(perm)
    const changeVal = useCallback(() => {
        props.formik.setValues({
            permissions: checked ? permissions?.filter((x) => x !== perm) : [...(permissions ?? []), perm],
        })
    }, [checked, perm, permissions, props.formik])

    return (
        <div className={classes.permContainer}>
            <div className={classes.permText}>
                <Typography className={classes.permTitle}> {props.title}</Typography>
                <Typography className={classes.permDesc}> {props.desc}</Typography>
            </div>
            <Switch color="primary" checked={checked} onChange={changeVal} />
        </div>
    )
}
