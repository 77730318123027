import { ButtonBase, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsHorizontal } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import { SavedCardsType } from 'shared/Interfaces'
import { subscriptions } from '../../../API/subscriptions'
import { useAlert } from '../../../containers/AlertProvider'
import useBoolean from '../../../hooks/useBoolean'
import { mdiIcon } from '../../../utils/mdiIcon'
import DeleteDialog from '../../Points/Components/DeleteDialog'
import getCardIssuer from './getCardIssuer'

interface Props {
    card: SavedCardsType
    setDeleteCards: (idx: number) => void
    idx: number
}

export default React.memo(CreditCardItem)
const Dots = mdiIcon(mdiDotsHorizontal)

function CreditCardItem(props: Props) {
    const { card, setDeleteCards, idx } = props
    const alert = useAlert()

    const classes = useStyles()
    const [deleteState, openDelete, closeDelete] = useBoolean()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const issuer = getCardIssuer(card.cardType)
    const deleteCard = useCallback(async () => {
        const [err, res] = await subscriptions.deleteSavedCard({ _id: card._id })
        if (err || !res || res.msg === 'error') {
            closeDelete()
            setAnchorEl(null)

            alert('error', 'Error deleting card!')
        }
        if (res?.msg === 'success') {
            closeDelete()
            setAnchorEl(null)

            alert('success', 'Succesfully deleted card!')
            setDeleteCards(idx)
        }
    }, [alert, card._id, closeDelete, idx, setDeleteCards])

    return (
        <div>
            <div className={classes.cardLabel}>
                <DeleteDialog handleClose={closeDelete} itemName="card" open={deleteState} handleConfirm={deleteCard} />
                <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                    <MenuItem onClick={openDelete}>
                        <MdDelete className={classes.delete} />
                        <Typography>Delete</Typography>
                    </MenuItem>
                </Menu>
                <img height={32} src={issuer.logo} />
                <div className={classes.cardDescription}>
                    <Typography className={classes.info}>Card ending with {card.last4Digits}</Typography>
                    <Typography className={classes.type}>{issuer.name}</Typography>
                </div>
                <ButtonBase onClick={handleClick} className={classes.dots}>
                    <Dots />
                </ButtonBase>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    cardLabel: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
        border: `1px solid ${theme.palette.background.default}`,
        borderRadius: 8,
        padding: 8,
        transition: theme.transitions.create(['background']),
        '&:hover': {
            background: theme.palette.background.default,
        },
    },
    cardDescription: { marginInlineStart: 16, display: 'flex', flexDirection: 'column' },
    info: { color: theme.palette.text.hint, fontSize: 14, fontWeight: 500 },
    type: { color: theme.palette.text.primary, fontSize: 12, fontWeight: 400 },
    dots: {
        borderRadius: 4,
        position: 'absolute',
        right: 8,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
}))
