import { useEffect, useMemo, useState } from 'react'
import { AsyncErrorOrValue } from '../API/utils/sendRequest'

const empty: boolean[] = []

export default function useAsync<E, R>(fn: () => AsyncErrorOrValue<E, R>, waitFor?: boolean[]) {
    const [value, setValue] = useState<R | undefined>()
    const [error, setError] = useState<E | undefined>()
    const [loading, setLoading] = useState(true)

    const waitList = useMemo(() => (waitFor && waitFor.length ? waitFor : empty), [waitFor])
    const dontExec = useMemo(() => !!waitList.length && waitList.some((x) => x), [waitList])

    useEffect(() => {
        if (dontExec) {
            return
        }

        fn()
            .then((res) => {
                const [err, result] = res

                if (err || !result) {
                    console.error(err)
                    setError(err)
                    return
                }

                setValue(result)
            })
            .catch((e) => {
                console.error(e)
                setError(e)
            })
            .finally(() => setLoading(false))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dontExec])

    return [value, loading, error, setValue] as const
}
