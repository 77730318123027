import { Button, Dialog, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { BsCreditCard } from 'react-icons/bs'
import { subscriptions } from '../../API/subscriptions'
import assets from '../../assets/assets'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import Loading from '../../containers/Layout/Loading'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import PackageCard from './PackageCard'
import ManageCards from './Payments/ManageCards'
import PlanCard from './PlanCard'

export default React.memo(AdvertiserSubscriptions)

function AdvertiserSubscriptions() {
    const classes = useStyles()
    const [loadedPacks, packsLoading] = useAsync(() => subscriptions.packs.get({}))
    const [loadedPlans, plansLoading] = useAsync(() => subscriptions.plans.get({}))
    const [loadedSubs, subsLoading] = useAsync(() => subscriptions.getSubscriptions({}))

    const [cardsState, openCards, closeCards] = useBoolean()

    return (
        <div className={classes.container}>
            <Dialog open={cardsState} onClose={closeCards} classes={{ paper: classes.dialog }}>
                <ManageCards close={closeCards} />
            </Dialog>

            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <BsCreditCard className={classes.creditIcon} />
                    <Typography className={classes.headerTitle}>Subscriptions</Typography>
                </div>

                <Button
                    className={classes.addButton}
                    startIcon={<BsCreditCard className={classes.mdAdd} />}
                    variant="contained"
                    color="primary"
                    onClick={openCards}
                >
                    Manage Cards
                </Button>
            </div>
            <div className={classes.body}>
                <div className={classes.plans}>
                    <div className={classes.headers}>
                        <Typography className={classes.title}>Plans</Typography>
                        <div />
                    </div>
                    {loadedPlans?.data?.length == 0 ? (
                        <GenericPlaceHolder
                            description="There are no plans here yet."
                            svg={assets.placeHolders.Plans}
                            customWidth={260}
                            customFS={16}
                        />
                    ) : (
                        <div className={classes.gridFather}>
                            <div className={classes.plansGridContainer}>
                                {plansLoading || subsLoading ? (
                                    <Loading />
                                ) : (
                                    loadedPlans?.data?.map((p, i) => {
                                        const current = loadedSubs?.subs.find((x) => {
                                            return x.typeId == p._id
                                        })

                                        return <PlanCard key={i} plan={p} current={current} />
                                    })
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.packages}>
                    <div className={classes.headers}>
                        <Typography className={classes.title}>Packages</Typography>
                        <div />
                    </div>
                    {loadedPacks?.data?.length == 0 ? (
                        <GenericPlaceHolder
                            description="There are no packages here yet."
                            svg={assets.placeHolders.Packages}
                            customWidth={260}
                            customFS={16}
                        />
                    ) : (
                        <div className={classes.gridFather}>
                            <div className={classes.packsGridContainer}>
                                {packsLoading || subsLoading ? (
                                    <Loading />
                                ) : (
                                    loadedPacks?.data?.map((p, i) => {
                                        const current = loadedSubs?.subs.find((x) => {
                                            return x.typeId == p._id
                                        })

                                        return <PackageCard key={i} pack={p} current={current} />
                                    })
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    creditIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    headerTitle: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    body: {
        width: 880,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        marginTop: 40,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    plans: {
        display: 'flex',
        flexDirection: 'column',
    },
    packages: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
    },
    headers: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: {
        marginLeft: 40,
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    addContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&:hover': {
            '& $plus': { color: theme.palette.primary.dark },
            '& $addText': { color: theme.palette.primary.dark },
            cursor: 'pointer',
        },
    },
    plus: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.primary.main,
        marginInlineEnd: 8,
        transition: theme.transitions.create(['color']),
    },
    addText: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.primary.main,
        transition: theme.transitions.create(['color']),
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    plansGridContainer: {
        display: 'grid',
        gridGap: `40px 20px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(auto-fill, 264px)',
        margin: '40px 40px',
        width: '100%',
        minHeight: 386,
    },
    packsGridContainer: {
        display: 'grid',
        gridGap: `40px 40px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(auto-fill, 378px)',
        margin: '40px 40px',
        width: '100%',
        minHeight: 264,
    },

    dialog: {
        width: 400,
    },

    plansCards: {
        marginTop: 16,
        height: 386,
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
    },
    packagesCards: {
        marginTop: 40,
        height: 264,
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'scroll',
    },
}))
