import {
    Button,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import React from 'react'
import { PackageType } from 'shared/Interfaces'
import { AddPackageArgs } from 'shared/types/packageTypes'
import * as yup from 'yup'
import { subscriptions } from '../../API/subscriptions'
import { useAlert } from '../../containers/AlertProvider'
import useBoolean from '../../hooks/useBoolean'
import AdvertisersSelect from './AdvertisersSelect'

interface Props {
    close: () => void
    add: (pack: PackageType) => void
}

export default React.memo(AddPackageDialog)

function AddPackageDialog(props: Props) {
    const { close, add } = props
    const alert = useAlert()
    const classes = useStyles()
    const [loading, setLoading, stopLoading] = useBoolean()

    const formik = useFormik<AddPackageArgs>({
        initialValues: {
            cost: undefined,
            currency: 'USD',
            numberOfOffers: undefined,
            type: 'public',
            advertiserId: undefined,
            title: '',
            periodNumber: undefined,
            periodUnit: 'Months',
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await subscriptions.packs.add(values)

            if (!res) {
                console.error(err)
                stopLoading()
                return
            }

            if (res.msg === 'success') {
                alert('success', 'Package added successfully')

                add(res.values!)
                formik.resetForm()
            } else {
                alert('error', 'Error adding point!')
            }
            stopLoading()
            close()
        },
    })
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Typography className={classes.title}>Add Package</Typography>
                <IconButton onClick={close}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.body}>
                <FormControl variant="filled" className={classes.textfield}>
                    <InputLabel>Type</InputLabel>
                    <Select value={formik.values.type} label="Type" onChange={formik.handleChange('type')}>
                        <MenuItem value={'public'}>Public</MenuItem>
                        <MenuItem value={'private'}>Private</MenuItem>
                    </Select>
                </FormControl>

                {formik.values.type === 'private' ? <AdvertisersSelect formik={formik} /> : null}
                <TextField
                    disabled={loading}
                    label="Title"
                    onChange={formik.handleChange('title')}
                    variant="filled"
                    className={classes.textfield}
                    value={formik.values.title}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                />
                <TextField
                    label="Offers"
                    onChange={formik.handleChange('numberOfOffers')}
                    variant="filled"
                    className={classes.offersField}
                    disabled={loading}
                    value={formik.values.numberOfOffers}
                    error={formik.touched.numberOfOffers && Boolean(formik.errors.numberOfOffers)}
                    helperText={formik.touched.numberOfOffers && formik.errors.numberOfOffers}
                />

                <div className={classes.paymentFields}>
                    <FormControl variant="filled" className={classes.currency}>
                        <InputLabel>Currency</InputLabel>
                        <Select value={formik.values.currency} displayEmpty onChange={formik.handleChange('currency')}>
                            <MenuItem value={'TRY'}>TRY</MenuItem>
                            <MenuItem value={'USD'}>USD</MenuItem>
                            <MenuItem value={'EUR'}>EUR</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Amount"
                        onChange={formik.handleChange('cost')}
                        variant="filled"
                        className={classes.amount}
                        disabled={loading}
                        value={formik.values.cost}
                        error={formik.touched.cost && Boolean(formik.errors.cost)}
                        helperText={formik.touched.cost && formik.errors.cost}
                    />
                </div>

                <div className={classes.durationFields}>
                    <TextField
                        label="After (number)"
                        onChange={formik.handleChange('periodNumber')}
                        variant="filled"
                        className={classes.amount}
                        disabled={loading}
                        value={formik.values.periodNumber}
                        error={formik.touched.periodNumber && Boolean(formik.errors.periodNumber)}
                        helperText={formik.touched.periodNumber && formik.errors.periodNumber}
                    />
                    <FormControl variant="filled" className={classes.due}>
                        <InputLabel>Unit</InputLabel>
                        <Select
                            value={formik.values.periodUnit}
                            label="Type"
                            onChange={formik.handleChange('periodUnit')}
                        >
                            <MenuItem value={'Days'}>Days</MenuItem>
                            <MenuItem value={'Months'}>Months</MenuItem>
                            <MenuItem value={'Years'}>Years</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.buttons}>
                    <form onSubmit={formik.handleSubmit}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button onClick={close} className={classes.cancelButton} variant="text" color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" className={classes.addButton} variant="contained" color="primary">
                                    Add Package
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 72,
        padding: '0px 16px',
        background: theme.palette.background.default,
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: 16,
    },
    title: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    body: { padding: 24, display: 'flex', flexDirection: 'column' },
    textfield: { marginBottom: 24 },
    offersField: { marginBottom: 8 },
    checkContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    checkText: {
        display: 'flex',
        flexDirection: 'column',
    },
    checkTitle: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    checkDesc: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    paymentFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16,
    },
    currency: { width: 120, marginInlineEnd: 16 },
    due: { width: 160, marginInlineStart: 16 },
    amount: { width: 456 },
    buttons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    durationFields: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 40,

        marginTop: 16,
    },
}))

const schema = yup.object({
    type: yup.string().required('Type is required'),
    numberOfOffers: yup.number().typeError('You must specify a number').required('Offers are required'),
    cost: yup.number().typeError('You must specify a number').required('Title is required'),
    periodNumber: yup.number().typeError('You must specify a number').required('Title is required'),
    currency: yup.string().required('Currency is required'),
    advertiserId: yup.string().when('type', { is: 'private', then: yup.string().required('Advertiser is required') }),
})
