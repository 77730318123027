import { Button, CircularProgress, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormikProps } from 'formik'
import React, { useCallback, useState } from 'react'
import Cube from 'react-3d-cube'
import AdImageUploadButton from '../../../components/AdImageUploadButton'
import { CreateCampaignForm } from '../CreateCampaign'

interface Props {
    form: FormikProps<CreateCampaignForm>
    loading: boolean
    editing?: boolean
}

export default React.memo(EditCampaignCbe3dImage)

function EditCampaignCbe3dImage(props: Props) {
    const { form, loading, editing } = props
    const [loadingCube, setLoadingCube] = useState(false)

    const classes = useStyles()

    const onChange = useCallback(
        (e) => {
            form.setFieldValue('cube3dImage', undefined)
            setLoadingCube(true)

            setTimeout(() => {
                form.setFieldValue('cube3dImage', e)
                setLoadingCube(false)
            }, 2000)
        },
        [form]
    )

    const image = form.values.cube3dImage && !editing ? URL.createObjectURL(form.values.cube3dImage as any as Blob) : form.values.cube3dImage

    const clear = useCallback(() => form.setFieldValue('cube3dImage', undefined), [form])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h2 className={classes.tabTitle}>3D Cube Image</h2>
            </div>

            <div className={classes.browseFileContainer}>
                <AdImageUploadButton onImage={onChange} loading={loading} />
                <div className={classes.uploadHintContainer}>
                    <Typography className={classes.hintTitle}>Add Image</Typography>
                    <Typography className={classes.hintDescription}>Minimum 512x512 size 1x1 aspect ratio</Typography>
                    {image ? (
                        <Button onClick={clear} className={classes.clear} variant="contained" color="primary">
                            Clear
                        </Button>
                    ) : null}
                </div>
            </div>

            <div className={classes.preview}>
                <Typography className={classes.previewTitle}>Preview</Typography>

                <div className={classes.previewContainer}>
                    {loadingCube ? <CircularProgress size={24} /> : null}

                    {image ? (
                        <div className={classes.CubePreviewContainer}>
                            {/* @ts-ignore */}
                            <Cube size={240} index="front">
                                <img className={classes.cubeImage} src={image} />
                                <img className={classes.cubeImage} src={image} />

                                <img className={classes.cubeImage} src={image} />

                                <img className={classes.cubeImage} src={image} />

                                <img className={classes.cubeImage} src={image} />

                                <img className={classes.cubeImage} src={image} />
                            </Cube>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 24,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    previewContainer: {
        backgroundColor: '#F9F9FA',
        width: 800,
        height: 440,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 12,
        borderRadius: 8,
    },
    tabTitle: {
        color: theme.palette.text.primary,
    },
    videoContainer: {
        marginTop: 24,
        backgroundColor: '#F9F9FA',
        cursor: 'pointer',
        border: '1px dashed #DEE1E3',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: 740,
        height: 420,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropDescription: {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginTop: 12,
    },
    browseFile: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        marginTop: 16,
    },
    uploadLabel: {},
    video: {
        width: '100%',
    },

    browseFileContainer: {
        display: 'flex',
        flexDirection: 'row',
    },

    uploadHintContainer: {
        marginTop: 64,
        padding: '0px 16px',
    },
    hintTitle: {
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    hintDescription: {
        color: theme.palette.text.hint,
        marginTop: 16,
        maxWidth: 180,
    },

    CubePreviewContainer: {
        height: 240,
        width: 240,
        marginTop: 8,
        cursor: 'grab',
    },
    preview: {
        marginTop: 40,
    },
    previewTitle: {
        color: theme.palette.text.primary,
    },
    cubeImage: {
        width: 200,
        height: 200,
    },

    fileInput: {
        color: 'rgba(0, 0, 0, 0)',
        backgroundColor: theme.palette.primary.main,
    },
    clear: {
        color: '#FFF',
        width: 120,
        marginTop: 16,
    },
}))
