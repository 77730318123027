import { Avatar, Dialog, IconButton, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core'
import { mdiDotsHorizontal, mdiGiftOutline } from '@mdi/js'
import { default as React, useCallback, useState } from 'react'
import { MdClose, MdDelete, MdEdit, MdStop } from 'react-icons/md'
import { PointType } from 'shared/Interfaces'
import { points } from '../../../API/points'
import { useAlert } from '../../../containers/AlertProvider'
import useBoolean from '../../../hooks/useBoolean'
import { backgroundSelector } from '../../../utils/backgroundSelector'
import { colorSelector } from '../../../utils/colorSelector'
import { mdiIcon } from '../../../utils/mdiIcon'

import { campaigns } from '../../../API/campaigns'
import useAsync from '../../../hooks/useAsync'
import CampaignCard from '../../AdminCampaigns/CampaignCard'
import DeactivateDialog from './DeactivateDialog'
import DeleteDialog from './DeleteDialog'
import EditDialog from './EditDialog'
import SlideTransition from './SlideTransition'

interface Props {
    originalPoint: PointType
    theme: Theme
    onPress: () => void
    pointRemover?: () => void
    state: any
}

export default React.memo(PointDialog)
const Dots = mdiIcon(mdiDotsHorizontal)
const Gift = mdiIcon(mdiGiftOutline)

function PointDialog(props: Props) {
    const { originalPoint, onPress, state, pointRemover } = props

    const alert = useAlert()
    const classes = useStyles()

    const point = originalPoint

    const [pointCopyState, setPointCopyState] = useState<PointType>(point)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const [deleteState, openDelete, closeDelete] = useBoolean()

    const [deactivateState, openDeactivate, closeDeactivate] = useBoolean()
    const [editState, openEdit, closeEdit] = useBoolean()

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const deletePoint = useCallback(async () => {
        await points.delete({ _id: point._id })
        closeDelete()
        pointRemover!()
        // TODO Must not be optional!!!!!!!
        alert('success', 'Point Deleted Successfully')
        onPress()
    }, [alert, closeDelete, onPress, point._id, pointRemover])

    const [campaignsResponse] = useAsync(() => campaigns.getByPointId({ pointId: point._id }), [])

    const campaignsList = campaignsResponse ? campaignsResponse.campaigns : []

    const deletable = campaignsList.length === 0

    const toggleActiveStatus = useCallback(async () => {
        await points.edit({ active: !pointCopyState.active }, point._id)
        setPointCopyState({ ...pointCopyState, active: !pointCopyState.active })
        closeDeactivate()
        
        alert('success', 'Point Deactivated Successfully')
    }, [alert, closeDeactivate, point._id, pointCopyState])

    return (
        <div className={classes.container}>
            {deleteState ? (
                <DeleteDialog
                    open={deleteState}
                    handleClose={closeDelete}
                    handleConfirm={deletePoint}
                    itemName={'Point'}
                />
            ) : null}

            {deactivateState ? (
                <DeactivateDialog
                    open={deactivateState}
                    handleClose={closeDeactivate}
                    handleConfirm={toggleActiveStatus}
                    itemName={'Point'}
                />
            ) : null}

            {editState ? (
                <EditDialog onEditSuccess={setPointCopyState} open={editState} handleClose={closeEdit} point={point} />
            ) : null}
            <div className={classes.container}>
                <Dialog
                    onClose={onPress}
                    open={state}
                    classes={{
                        paper: classes.dialog,
                        root: classes.markerDialogZindex,
                    }}
                    TransitionComponent={SlideTransition}
                    fullScreen
                >
                    <div className={classes.dialogTitleContainer}>
                        <div className={classes.headerInfoAvatar}>
                            <div
                                className={classes.avatarContainer}
                                style={{ background: colorSelector(pointCopyState.category) }}
                            >
                                {pointCopyState.avatar ? (
                                    <Avatar src={pointCopyState.avatar} />
                                ) : (
                                    <Gift className={classes.gift} />
                                )}
                            </div>
                            <div className={classes.headerInfo}>
                                <div className={classes.titleCode}>
                                    <Typography className={classes.dialogTitle}>{pointCopyState.title}</Typography>
                                    <Typography className={classes.code}>· {pointCopyState.code}</Typography>
                                    {!pointCopyState.active ? (
                                        <div className={classes.inactiveState}>
                                            <span className={classes.inactiveStateText}>Inactive</span>
                                        </div>
                                    ) : null}
                                </div>

                                <Typography className={classes.region}>{pointCopyState.region}</Typography>
                                <Typography className={classes.coords}>
                                    Latitude: {pointCopyState.lat}, Longtitude: {pointCopyState.lng}
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.headerRight}>
                            <div className={classes.headerActions}>
                                <IconButton onClick={handleClick}>
                                    <Dots />
                                </IconButton>
                                <IconButton onClick={onPress}>
                                    <MdClose />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                >
                                    {deletable ? (
                                        <MenuItem onClick={openDelete}>
                                            <MdDelete className={classes.delete} />
                                            <Typography>Delete</Typography>
                                        </MenuItem>
                                    ) : null}
                                    <MenuItem onClick={openEdit}>
                                        <MdEdit className={classes.delete} />
                                        <Typography>Edit</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={pointCopyState.active ? openDeactivate : toggleActiveStatus}>
                                        <MdStop className={classes.delete} />
                                        <Typography>{pointCopyState.active ? 'Deactivate' : 'Activate'}</Typography>
                                    </MenuItem>
                                </Menu>
                            </div>
                            <div
                                className={classes.categoryBG}
                                style={{ backgroundColor: backgroundSelector(pointCopyState.category) }}
                            >
                                <Typography
                                    className={classes.category}
                                    style={{ color: colorSelector(pointCopyState.category) }}
                                >
                                    {pointCopyState.category}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <div className={classes.body}>
                        <div className={classes.pointCampaigns}>
                            {campaignsList.map((campaign: any) => {
                                return <CampaignCard campaign={campaign} key={campaign.status} />
                            })}
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: { position: 'relative', height: 0, width: 42, cursor: 'pointer' },
    marker: { position: 'absolute', bottom: -8, right: 21 },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '24px 16px',
    },
    dialogContainer: {},
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
        marginRight: 16,
    },
    tableContainer: {
        width: 800,
    },
    dialog: {
        width: '100%',
        overflow: 'hidden',
    },

    markerDialogZindex: {
        width: '100%',
        zIndex: 1299,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    pointCampaigns: {
        display: 'flex',
        minHeight: 340,
        flexDirection: 'column',
    },
    gift: {
        color: theme.palette.background.paper,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    region: {
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    coords: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.hint,
    },
    code: {
        fontWeight: 400,
        fontSize: 12,
        marginLeft: 8,
        color: theme.palette.text.hint,
    },
    titleCode: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerInfoAvatar: { display: 'flex' },
    headerInfo: { marginLeft: 16 },
    phoneNumber: {
        marginBottom: 16,
    },
    body: {
        height: 'calc(100% - 128px)',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 24,
    },
    gridContainer: {
        display: 'grid',
        gridGap: `40px 40px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(3, auto)',
    },
    addContainer: {
        width: 202,
        height: 140,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: theme.transitions.create(['border-color']),
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& $add': {
                color: theme.palette.primary.main,
            },
            borderColor: theme.palette.primary.main,
        },
    },
    addContent: { display: 'flex', flexDirection: 'column', alignItems: 'center' },

    add: { fontSize: 20, marginBottom: 22, transition: theme.transitions.create(['color']) },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    avatarContainer: {
        height: 50,
        width: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 50,
    },
    headerRight: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end' },
    categoryBG: { borderRadius: 4, marginRight: 16, marginTop: 4 },
    category: { padding: '4px 12px', fontWeight: 400, fontSize: 14 },
    inactiveState: {
        // width: ,
        borderRadius: 4,
        border: `1px solid ${theme.palette.error.main}`,
        margin: '0px 8px',
    },
    inactiveStateText: {
        padding: '8px 12px',
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.error.main,
    },
}))
