import { IconType } from 'react-icons'
import { AiOutlineGlobal, AiOutlineShop } from 'react-icons/ai'
import { BsCreditCard } from 'react-icons/bs'
import { FaGift } from 'react-icons/fa'
import { GoLocation } from 'react-icons/go'
import { HiOutlineUsers } from 'react-icons/hi'
import { MdNotificationsNone, MdOndemandVideo } from 'react-icons/md'
import { RiAdminLine } from 'react-icons/ri'
import { PermissionType } from 'shared/permissions'

export interface MenuTab {
    title: string
    path: string
    icon: IconType
    roles: PermissionType[]
    key: string
}

export const ADVERTISER_LAYOUT_ROUTES: MenuTab[] = [
    // {
    //     title: 'Home',
    //     path: '/dashboard',
    //     icon: VscHome,
    //     roles: ['advertiser'],
    //     key: '/dashboard',
    // },
    {
        title: 'Campaigns',
        path: '/campaigns',
        icon: MdNotificationsNone,
        roles: ['advertiser'],
        key: '/campaigns',
    },
    {
        title: 'Subscriptions',
        path: '/subscriptions',
        icon: BsCreditCard,
        roles: ['advertiser'],
        key: '/subscriptions',
    },
    {
        title: 'Redeem Code',
        path: '/redeem-code',
        icon: FaGift,
        roles: ['advertiser'],
        key: '/redeem-code',
    },
    {
        title: 'Points',
        path: '/points',
        icon: GoLocation,
        roles: ['advertiser'],
        key: '/points',
    },
]

export const ADMIN_LAYOUT_ROUTES: MenuTab[] = [
    // {
    //     title: 'Home',
    //     path: '/dashboard',
    //     icon: VscHome,
    //     roles: [],
    //     key: '/dashboard',
    // },
    {
        title: 'Admins',
        path: '/admins',
        icon: RiAdminLine,
        roles: ['superAdmin', 'manageAdminUsers'],
        key: '/admins',
    },
    {
        title: 'Agencies',
        path: '/agencies',
        icon: HiOutlineUsers,
        roles: ['superAdmin', 'viewAgencies'],
        key: '/agencies',
    },
    {
        title: 'Agents',
        path: '/agents',
        icon: HiOutlineUsers,
        roles: ['manageAgencyUsers', 'manageAgencyUsers'],
        key: '/agents',
    },
    {
        title: 'Advertisers',
        path: '/advertisers',
        icon: AiOutlineShop,
        roles: ['superAdmin', 'manageAdvertiserUsers'],
        key: '/advertiser',
    },

    {
        title: 'End Users',
        path: '/endUsers',
        icon: HiOutlineUsers,
        roles: ['superAdmin', 'viewFullEndUserData', 'manageEndUsers'],
        key: '/endUsers',
    },
    {
        title: 'Campaigns',
        path: '/campaigns',
        icon: MdNotificationsNone,
        roles: ['superAdmin', 'manageCampaigns'],
        key: '/campaigns',
    },
    {
        title: 'Subscriptions',
        path: '/subscriptions',
        icon: BsCreditCard,
        roles: ['superAdmin', 'manageSubscriptions', 'addSubsPack'],
        key: '/subscriptions',
    },
    {
        title: 'Points',
        path: '/points',
        icon: GoLocation,
        roles: ['superAdmin', 'managePoints'],
        key: '/points',
    },
    {
        title: 'Regions',
        path: '/regions',
        icon: AiOutlineGlobal,
        roles: ['superAdmin', 'manageRegions'],
        key: '/regions',
    },
    {
        title: 'Video Ads',
        path: '/video-ads',
        icon: MdOndemandVideo,
        roles: ['superAdmin', 'manageVideoAds'],
        key: '/video-ads',
    },
]

export const SUPER_ADMIN_LAYOUT_ROUTES: MenuTab[] = [
    {
        title: 'Admins',
        path: '/admins',
        icon: RiAdminLine,
        roles: ['superAdmin', 'manageAdminUsers'],
        key: '/admins',
    },
    {
        title: 'Regions',
        path: '/regions',
        icon: AiOutlineGlobal,
        roles: ['superAdmin', 'manageRegions'],
        key: '/regions',
    },
]
