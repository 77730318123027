import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiAccountSupervisor } from '@mdi/js'
import { useFormik } from 'formik'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import * as yup from 'yup'
import { agencies } from '../../API/agencies'
import { regions } from '../../API/regions'
import assets from '../../assets/assets'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import { useUser } from '../../containers/UserProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import { countryCodeRegex, phoneRegex } from '../Users/Login'
import AgentCard from './AgentCard'

const AccountIcon = mdiIcon(mdiAccountSupervisor)

export default React.memo(AgentsList)

function AgentsList() {
    const alert = useAlert()
    const classes = useStyles()

    const [loadedAgents, , , setValue] = useAsync(() => agencies.getAgents({}))
    const [loadedRegions] = useAsync(() => regions.get({}))
    const currentUser = useUser().user
    const isPermitted = currentUser!.permissions.includes('manageAgencyUsers') || currentUser?.agencyAdmin



    const [open, setOpen, setClosed] = useBoolean()
    const [loading, setLoading, stopLoading] = useBoolean()

    const sortedAgents = useMemo(() => sortBy(loadedAgents?.data ?? [], (x) => x.name), [loadedAgents?.data])

    const form = useFormik({
        initialValues: { name: '', phoneNumber: '', countryCode: '+90' },
        validationSchema: agentSchema,

        onSubmit: async (values) => {
            setLoading()
            const { countryCode, name, phoneNumber } = values
            const [err, res] = await agencies.addAgent({ name, phoneNumber: countryCode + phoneNumber })

            if (err || !res) {
                stopLoading()
                console.error(err)
                alert('error', 'Unknown Error')
                return
            }

            if (res.msg === 'userAlreadyAnAdminOrAgent') {
                stopLoading()
                alert('error', 'User Already Exists')
                return
            }

            alert('success', 'Agent Added Successfully')

            setValue({ data: [...sortedAgents, res.data!] })

            stopLoading()
            setClosed()
        },
    })
    const setCode = useCallback(
        (code: string) => {
            form.setFieldValue('countryCode', code)
        },
        [form]
    )
    if (!loadedRegions || !loadedAgents) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <Dialog onClose={setClosed} open={open} classes={{ paper: classes.dialog }}>
                <div className={classes.dialogTitleContainer}>
                    <Typography className={classes.dialogTitle}>Add Agent</Typography>
                    <IconButton onClick={setClosed}>
                        <MdClose />
                    </IconButton>
                </div>

                <form onSubmit={form.handleSubmit}>
                    <div className={classes.textFields}>
                        <TextField
                            disabled={loading}
                            label="Full Name"
                            onChange={form.handleChange('name')}
                            variant="filled"
                            className={classes.phoneNumber}
                            value={form.values.name}
                            error={form.touched.name && Boolean(form.errors.name)}
                            helperText={form.touched.name && form.errors.name}
                        />
                        <div className={classes.phoneInput}>
                            <CountrySearchMenu setCode={setCode} loading={loading} />
                            <TextField
                                disabled={loading}
                                label="Phone Number"
                                onChange={form.handleChange('phoneNumber')}
                                variant="filled"
                                className={classes.phoneNumber}
                                value={form.values.phoneNumber}
                                error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                                helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                            />
                        </div>
                    </div>

                    <div className={classes.dialogButtons}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button
                                    onClick={setClosed}
                                    className={classes.cancelButton}
                                    variant="text"
                                    color="primary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.addRegionButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    Add Agent
                                </Button>
                            </>
                        )}
                    </div>
                </form>
            </Dialog>

            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <AccountIcon className={classes.accountIcon} />
                    <Typography className={classes.title}>Agents</Typography>
                </div>

                <Button
                    className={classes.addButton}
                    startIcon={<MdAdd className={classes.mdAdd} />}
                    variant="contained"
                    color="primary"
                    onClick={setOpen}
                >
                    Add Agent
                </Button>
            </div>

            <div className={classes.body}>
                {sortedAgents.length <= 1 ? (
                    <GenericPlaceHolder
                        description="There are no agents here yet."
                        svg={assets.placeHolders.Agents}
                        customWidth={320}
                        customFS={20}
                        action={setOpen}
                        actionName="Add Agent"
                    />
                ) : (
                    <div className={classes.gridFather}>
                        <div className={classes.gridContainer}>
                            {sortedAgents.map((user) => {
                                const regionName = loadedRegions?.regions.find((r) => user.regionId === r._id)?.name

                                return (
                                    <AgentCard
                                        key={user._id}
                                        user={user}
                                        region={regionName ?? user.regionId}
                                        isPermitted={!!isPermitted}
                                    />
                                )
                            })}
                        </div>

                        <div className={classes.placeholder} />
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    gridContainer: {
        display: 'grid',
        gridGap: `40px 20px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(auto-fill, 264px)',
        margin: '40px 24px',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.default,
        padding: '0px 16px',
        height: 72,
    },
    dialogButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
        flex: 1,
    },
    placeholder: {
        minHeight: 48,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))

const agentSchema = yup.object({
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),

    name: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Full name is required'),
    phoneNumber: yup.string().matches(phoneRegex, 'Phone number is not valid').required('Phone Number is required'),
})
