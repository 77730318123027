import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useCallback } from 'react'
import { BiImageAdd } from 'react-icons/bi'

interface Props {
    onImage: (img: File) => void
    loading: boolean
}

export default React.memo(AdImageUploadButton)

function AdImageUploadButton(props: Props) {
    const { onImage, loading } = props

    const classes = useStyles()

    const onChange = useCallback(
        (e) => {
            onImage(e.target.files[0])
            e.target.value = null
        },
        [onImage]
    )

    return (
        <>
            <input hidden accept="image/*" type="file" id="upload-file" onChange={onChange} disabled={loading} />

            <label htmlFor={'upload-file'} className={classes.uploadLabel}>
                <div className={classes.container}>
                    <BiImageAdd className={classes.BiImageAdd} size={64} />
                </div>
            </label>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 24,
        width: '240px',
        userSelect: 'none',
        cursor: 'pointer',
        height: 240,
        background: '#F9F9FA',
        border: '1px dashed #DEE1E3',
        boxSizing: 'border-box',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    BiImageAdd: {
        color: '#DADADA',
        marginBottom: 8,
    },
    description: {
        color: theme.palette.text.hint,
        fontSize: 11,
    },
    uploadLabel: {
        width: '240px',
    },
}))
