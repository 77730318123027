/* eslint-disable react/jsx-no-bind */
import { Button, Dialog, TextField, Theme, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiGiftOutline } from '@mdi/js'
import { default as React, useCallback, useState } from 'react'
import { AiOutlineGift } from 'react-icons/ai'
import { BsCheck } from 'react-icons/bs'
import { IoMdClose } from 'react-icons/io'
import { PointType } from 'shared/Interfaces'
import useBoolean from '../../../hooks/useBoolean'
import isNumInput from '../../../utils/isNumInput'
import { mdiIcon } from '../../../utils/mdiIcon'
import SlideTransition from '../../Points/Components/SlideTransition'

export type PointsValuesState = {
    [pointId: string]: { type: 'gifts' | 'offers'; pointId: string; amount: string }
}

interface Props {
    point: PointType
    theme: Theme
    onChangeSave: (values: PointsValuesState) => void
    originalAmount?: string
}

export default React.memo(MapMarker)
const Gift = mdiIcon(mdiGiftOutline)

function MapMarker(props: Props) {
    const { theme, point, onChangeSave, originalAmount } = props

    const classes = useStyles()

    const [pointsValues, setPointsValues] = useState<PointsValuesState>({})

    const [open, setOpen, setClosed] = useBoolean()

    const save = useCallback(() => {
        onChangeSave(pointsValues)
        setClosed()
        return
    }, [pointsValues, onChangeSave, setClosed])
    isNumInput('num-input')
    return (
        <div className={classes.container}>
            {open ? (
                <Dialog
                    onClose={setClosed}
                    open={open}
                    classes={{ paper: classes.dialog, root: classes.markerDialogZindex }}
                    TransitionComponent={SlideTransition}
                >
                    <div className={classes.header}>
                        <div className={classes.headerIconContainer}>
                            <div className={classes.iconContainer}>
                                <AiOutlineGift style={{ color: '#FFF' }} />
                            </div>

                            <div className={classes.headerOptionsAndInfo}>
                                <Typography className={classes.title}>{point.title}</Typography>

                                <Typography className={classes.country}>{point.region}</Typography>
                                <Typography className={classes.location}>
                                    23 Nisan, Üniversite Blv., 27070 Şahinbey/Gaziantep
                                </Typography>
                                <TextField
                                    className={classes.numberInput}
                                    label="Number Of Offers/Gifts"
                                    variant="filled"
                                    color="primary"
                                    id="num-input"
                                    type={'number'}
                                    value={(pointsValues[point._id] ?? {}).amount ?? originalAmount}
                                    onChange={(value) => {
                                        setPointsValues({
                                            ...pointsValues,
                                            [point._id]: {
                                                amount: value.target.value,
                                                pointId: point._id,
                                                type: 'offers',
                                            },
                                        })
                                    }}
                                />
                                <div className={classes.headerButtonsContainer}>
                                    <Button
                                        classes={{ root: classes.ediButton, fullWidth: classes.ediButton }}
                                        onClick={save}
                                        variant="contained"
                                        color="primary"
                                        style={{ color: '#FFF' }}
                                    >
                                        <BsCheck size={18} />
                                    </Button>

                                    <Button
                                        classes={{ root: classes.ediButton, fullWidth: classes.ediButton }}
                                        onClick={setClosed}
                                    >
                                        <IoMdClose />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            ) : null}
            <svg
                className={classes.marker}
                onClick={setOpen}
                width="42"
                height="59"
                viewBox="0 0 42 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_f)">
                    <ellipse cx="21" cy="53" rx="8" ry="4" fill="#374047" fillOpacity="0.16" />
                </g>
                <path
                    d="M33.1529 37.5109C38.2147 33.7791 41.5 27.7732 41.5 21C41.5 9.67816 32.3218 0.5 21 0.5C9.67816 0.5 0.5 9.67816 0.5 21C0.5 27.7732 3.78529 33.7791 8.84713 37.511C13.935 41.3687 17.8775 46.5399 20.2497 52.4705C20.5206 53.1478 21.4794 53.1478 21.7503 52.4705C24.1226 46.5399 28.065 41.3687 33.1529 37.5109Z"
                    fill={theme.palette.primary.main}
                    stroke="url(#paint0_linear)"
                />
                <g>
                    <rect x="5" y="5" width="32" height="32" rx="16" fill="#374047" fillOpacity="0.16" />
                </g>
                <defs>
                    <filter
                        id="filter0_f"
                        x="11"
                        y="47"
                        width="20"
                        height="12"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                        <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur" />
                    </filter>
                    <linearGradient
                        id="paint0_linear"
                        x1="21"
                        y1="1"
                        x2="21"
                        y2="52.4785"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0.35" />
                    </linearGradient>
                </defs>
            </svg>
            <Gift className={classes.gift} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: { position: 'relative', height: 0, width: 42, cursor: 'pointer' },
    marker: { position: 'absolute', bottom: -8, right: 21 },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: theme.palette.divider,
        background: theme.palette.background.default,
        padding: '24px 16px',
    },
    numberInput: {
        margin: '12px 0px',
        width: '100%',
    },

    dialogButtons: {
        display: 'flex',
        flexDirection: 'column',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    tableContainer: {
        width: 800,
    },
    dialog: {
        width: 500,
    },

    markerDialogZindex: {
        width: '100%',
        zIndex: 1299,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    gift: {
        color: theme.palette.background.paper,
        top: -41,
        left: -12,
        height: 21,
        position: 'absolute',
        pointerEvents: 'none',
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    headerActions: {
        display: 'flex',
        alignItems: 'center',
    },
    regionCoords: {
        fontWeight: 400,
        fontSize: 12,
    },

    titleCode: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerInfo: {},
    phoneNumber: {
        marginBottom: 16,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        padding: 12,
        boxSizing: 'border-box',
        backgroundColor: '#F9F9FA',
        width: '100%',
    },

    headerIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    iconContainer: {
        width: 40,
        height: 40,
        borderRadius: 50,
        backgroundColor: '#673AB7',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '8px 12px',
    },
    ediButton: {
        width: 30,
        height: 30,
    },
    headerButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    headerOptionsAndInfo: { width: 400 },
    title: {
        color: theme.palette.text.primary,
        fontSize: 14,
    },
    country: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    location: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },

    titleTypography: {
        color: theme.palette.text.primary,
        fontSize: 14,
    },
    descriptionTypography: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    giftsLength: {
        width: 24,
        height: 16,
        borderRadius: 50,
        backgroundColor: '#D14343',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 12px',
    },
    lengthNumber: {
        color: '#FFF',
        fontSize: 10,
    },
}))
