import alipay from './alipay.svg'
import amex from './amex.svg'
import diners from './diners.svg'
import discover from './discover.svg'
import elo from './elo.svg'
import generic from './generic.svg'
import hiper from './hiper.svg'
import hipercard from './hipercard.svg'
import jcb from './jcb.svg'
import maestro from './maestro.svg'
import mastercard from './mastercard.svg'
import mir from './mir.svg'
import paypal from './paypal.svg'
import unionpay from './unionpay.svg'
import visa from './visa.svg'

const issuers = {
    alipay,
    amex,
    generic,
    diners,
    discover,
    hiper,
    hipercard,
    jcb,
    maestro,
    mastercard,
    mir,
    paypal,
    unionpay,
    visa,
    elo,
}

export default issuers
