import { Tab, Tabs } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { RegionType, UserType } from 'shared/Interfaces'
import { campaigns } from '../../API/campaigns'
import useAsync from '../../hooks/useAsync'
import AdvertiserCampaignCard from './AdvertiserCampaignCard'
import AdvertiserHeader from './AdvertiserHeader'
import AdvertiserInfo from './AdvertiserInfo'
import InfoCard from './InfoCard'

interface Props {
    back: () => void
    advertiser: UserType
    regions: RegionType[] | undefined
}

export default React.memo(AdvertiserView)

function AdvertiserView(props: Props) {
    const { back, advertiser, regions } = props
    const classes = useStyles()

    const [value, setValue] = useState(0)
    const handleTabsChange = useCallback((event, newValue) => setValue(newValue), [])

    const [loadedCampaigns] = useAsync(() => campaigns.getByUserId({ id: advertiser._id }))

    const sortedCampaigns = useMemo(
        () => sortBy(loadedCampaigns?.campaign ?? [], (x) => x.title).reverse(),
        [loadedCampaigns?.campaign]
    )
    const regionName = regions?.find((r) => advertiser.regionId === r._id)?.name

    return (
        <div className={classes.container}>
            <div className={classes.headerColor} />

            <div className={classes.contentContainer}>
                <div className={classes.content}>
                    <AdvertiserHeader back={back} />

                    <Tabs
                        value={value}
                        onChange={handleTabsChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className={classes.tabs}
                    >
                        <Tab label="Info" classes={{ root: classes.tabRoot }} className={classes.tab} />
                        <Tab label="Campaigns" classes={{ root: classes.tabRoot }} className={classes.tab} />
                        {/* <Tab label="Payments" /> */}
                    </Tabs>

                    {value === 0 ? (
                        <div className={classes.tabContent}>
                            <AdvertiserInfo campaignsCount={loadedCampaigns?.campaign?.length} />
                        </div>
                    ) : null}

                    {value === 1 ? (
                        <div className={classes.tabContent} hidden={value !== 1}>
                            {sortedCampaigns?.map((x, idx) => {
                                return <AdvertiserCampaignCard campaign={x} key={idx} />
                            })}

                            <div style={{ minHeight: 48 }} />
                        </div>
                    ) : null}
                </div>

                <InfoCard advertiser={advertiser} className={classes.infoCard} regionName={regionName} />
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        justifyContent: 'center',
        position: 'relative',
    },
    headerColor: {
        background: theme.palette.text.primary,
        width: '100%',
        height: 96,
        position: 'absolute',
    },
    contentContainer: {
        width: 'calc(100% - 48px)',
        maxWidth: 960,
        display: 'flex',
    },
    tabRoot: {
        minWidth: 40,
    },
    tab: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    dots: { color: theme.palette.background.paper },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    cards: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoCard: {
        height: 480,
        width: 240,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 8,
        background: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 24,
        zIndex: 9999,
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
}))
