import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsHorizontal, mdiEyeOutline } from '@mdi/js'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { MdCheck, MdClose, MdDelete, MdVideoLibrary } from 'react-icons/md'
import { AdVideoType } from 'shared/Interfaces'
import { mdiIcon } from '../../utils/mdiIcon'

interface Props {
    video: AdVideoType
    setOpenDelete: (event: any) => void
    setCloseVid: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default React.memo(VideoCardDialog)

const EyeOn = mdiIcon(mdiEyeOutline)
const Dots = mdiIcon(mdiDotsHorizontal)

function VideoCardDialog(props: Props) {
    const { video, setOpenDelete, setCloseVid } = props

    const classes = useStyles()
    const complete = video.status == 'complete'
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const date = useMemo(() => moment(video.createdAt).format('DD/MM/YYYY hh:mm'), [video.createdAt])

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }, [])

    const handleCloseMenu = useCallback(() => setAnchorEl(null), [])

    return (
        <>
            <div className={classes.dialogHeader}>
                <div className={classes.dialogInfo}>
                    <MdVideoLibrary className={classes.videoLibrary} />
                    <div className={classes.dialogInfoText}>
                        <Typography className={classes.dialogTitle}>{video?.title}</Typography>
                        <Typography className={classes.dialogDate}>{date}</Typography>
                    </div>
                </div>

                <div className={classes.dialogActions}>
                    <div className={classes.dialogViewsContainer}>
                        {complete ? (
                            <MdCheck className={classes.checkDialog} />
                        ) : (
                            <EyeOn className={classes.eyeOnDialog} />
                        )}
                        <Typography className={classes.dialogViews}>
                            {video?.currentViews} / {video?.views}
                        </Typography>
                    </div>

                    <IconButton onClick={handleClick} className={classes.button}>
                        <Dots className={classes.dots} />
                    </IconButton>
                    <IconButton onClick={setCloseVid} className={classes.button}>
                        <MdClose className={classes.dots} />
                    </IconButton>

                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                        <MenuItem onClick={setOpenDelete}>
                            <MdDelete className={classes.delete} />
                            <Typography>Delete</Typography>
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <video className={classes.videoElm} src={video.video} controls />
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create(['background']),
        userSelect: 'none',
        borderRadius: 4,
        '&:hover': {
            background: theme.palette.action.hover,
        },
        background: '#000',
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    avatar: {
        height: 24,
        width: 24,
        marginInlineEnd: 8,
    },
    people: {
        fontSize: 24,
        color: theme.palette.primary.main,
    },

    agencyTitle: {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    region: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    name: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    members: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    nameAvatar: {
        display: 'flex',
        alignItems: 'center',
    },
    memberCount: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 8,
        marginInlineStart: 16,
    },
    date: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginInlineEnd: 12,
    },
    video: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },

    code: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
        padding: 2,
        borderRadius: 4,
        marginInlineStart: 8,
    },
    views: {
        fontWeight: 700,
        fontSize: 12,
        color: theme.palette.background.paper,
    },
    codeTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    viewsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        padding: '2px 4px',
    },
    check: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        fontSize: 16,
    },
    eyeOn: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        height: 16,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    videoDialog: {
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
    },
    dialogHeader: {
        zIndex: 1300,
        position: 'absolute',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: 'rgba(55, 64, 71, 0.4)',
        height: 90,
        minHeight: 90,
        maxHeight: 90,
    },
    dialogInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 24,
    },
    dialogInfoText: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 24,
    },
    videoLibrary: {
        background: theme.palette.text.primary,
        color: theme.palette.background.paper,
        fontSize: 20,
        padding: 10,
        borderRadius: 4,
        marginInlineEnd: 16,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.background.paper,
    },
    dialogDate: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.background.paper,
    },
    dialogViewsContainer: {
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4,
        padding: '8px 12px',
    },
    dialogViews: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.background.paper,
    },
    checkDialog: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        fontSize: 20,
    },
    eyeOnDialog: {
        color: theme.palette.background.paper,
        marginInlineEnd: 4,
        height: 20,
    },
    button: {
        marginInlineStart: 16,
        borderRadius: 4,
        width: 40,
        height: 40,
    },
    dots: {
        color: theme.palette.background.paper,
        margin: 0,
    },
    videoElm: {
        height: 'calc(100% - 90px)',
        marginTop: 90,
        background: '#000',
    },
}))
