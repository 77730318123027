import { Button, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiAccountSupervisor } from '@mdi/js'
import { sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { MdAdd } from 'react-icons/md'
import { admins } from '../../API/admins'
import { regions } from '../../API/regions'
import assets from '../../assets/assets'
import GenericPlaceHolder from '../../components/GenericPlaceHolder'
import Loading from '../../containers/Layout/Loading'
import { useUser } from '../../containers/UserProvider'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { CreateAdminResponse } from '../../shared/types/adminTypes'
import { mdiIcon } from '../../utils/mdiIcon'
import AdminCard from './components/AdminCard'
import CreateAdminDialog from './CreateAdminDialog'

const AccountIcon = mdiIcon(mdiAccountSupervisor)

export default React.memo(AdminsList)

function AdminsList() {
    const classes = useStyles()

    const [result, , , setResult] = useAsync(() => admins.getAll({}))
    const [loadedRegions] = useAsync(() => regions.get({}))

    const [open, setOpen, setClosed] = useBoolean()
    const currentUser = useUser().user
    const isPermitted = currentUser!.permissions.includes('manageAdminUsers') || currentUser?.superAdmin

    const onResult = useCallback(
        (res: CreateAdminResponse) => {
            setResult({ users: [...result!.users!, res.user!] })
        },
        [result, setResult]
    )

    const sortedUsers = useMemo(() => sortBy(result?.users ?? [], (x) => x.name).reverse(), [result?.users])
    if (!loadedRegions || !result) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <Dialog onClose={setClosed} open={open} classes={{ paper: classes.dialog }}>
                <CreateAdminDialog setResult={onResult} loadedRegions={loadedRegions} setClosed={setClosed} />
            </Dialog>

            <div className={classes.header}>
                <div className={classes.titleContainer}>
                    <AccountIcon className={classes.accountIcon} />
                    <Typography className={classes.title}>Admins</Typography>
                </div>

                <Button
                    className={classes.addButton}
                    startIcon={<MdAdd className={classes.mdAdd} />}
                    variant="contained"
                    color="primary"
                    onClick={setOpen}
                >
                    Add Admin
                </Button>
            </div>

            <div className={classes.body}>
                {sortedUsers.length <= 1 ? (
                    <GenericPlaceHolder
                        description="There are no admins here yet."
                        svg={assets.placeHolders.Admins}
                        actionName="Add Admin"
                        customWidth={320}
                        customFS={20}
                        action={setOpen}
                    />
                ) : (
                    <div className={classes.gridFather}>
                        <div className={classes.gridContainer}>
                            {sortedUsers.map((user) => {
                                const regionName = loadedRegions.regions.find((x) => {
                                    return x._id === user.regionId
                                })?.name
                                if (user.superAdmin) {
                                    return null
                                }
                                return (
                                    <AdminCard
                                        key={user._id}
                                        user={user}
                                        regionName={regionName}
                                        isPermitted={!!isPermitted}
                                    />
                                )
                            })}
                        </div>

                        <div className={classes.placeholder} />
                    </div>
                )}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 16px',
        borderBottom: `1px solid ${theme.palette.divider}`,
        height: 72,
        minHeight: 72,
        paddingRight: 24,
    },
    gridFather: {
        maxWidth: 880,
        width: '100%',
    },
    gridContainer: {
        display: 'grid',
        gridGap: `40px 20px`,
        height: `fit-content`,
        gridTemplateColumns: 'repeat(auto-fill, 264px)',
        margin: '40px 24px',
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        background: theme.palette.primary.main,
        borderRadius: 4,
        padding: 8,
        color: theme.palette.background.paper,
        marginInlineEnd: 16,
    },
    title: {
        fontWeight: 700,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    mdAdd: {
        color: theme.palette.background.paper,
    },
    addButton: {
        color: theme.palette.background.paper,
    },
    dialog: {
        width: 560,
    },
    select: {
        marginTop: 40,
        marginBottom: 40,
        width: 512,
        alignSelf: 'center',
    },
    dialogTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: theme.palette.background.default,
        padding: '0px 16px',
        height: 72,
    },
    dialogButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        margin: 24,
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    body: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    textFields: {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
        flex: 1,
    },
    placeholder: {
        minHeight: 48,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))
