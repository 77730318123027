import {
    ChangePasswordArgs,
    ChangePasswordConfirmArgs,
    ChangePasswordConfirmResponse,
    ChangePasswordResponse,
    LoginArgs,
    LoginResponse,
    SendCodeArgs,
    SendCodeResponse,
    SignupArgs,
    SignupResponse,
    VerifyCodeArgs,
    VerifyCodeResponse,
} from 'shared/types/authTypes'
import { GetUserArgs, GetUserResponse } from 'shared/types/userTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'
export const auth = {
    login: (args: LoginArgs) => sendRequest<LoginResponse>({ endpoint: serverEndPoints.auth.login, args, auth: false }),
    sendCode: (args: SendCodeArgs) =>
        sendRequest<SendCodeResponse>({ endpoint: serverEndPoints.auth.sendCode, args, auth: false }),
    verifyCode: (args: VerifyCodeArgs) =>
        sendRequest<VerifyCodeResponse>({ endpoint: serverEndPoints.auth.verifyCode, args, auth: false }),
    changePassword: (args: ChangePasswordArgs) =>
        sendRequest<ChangePasswordResponse>({ endpoint: serverEndPoints.auth.changePassword, args, auth: false }),
    changePasswordConfirm: (args: ChangePasswordConfirmArgs) =>
        sendRequest<ChangePasswordConfirmResponse>({
            endpoint: serverEndPoints.profile.changePassword,
            args,
            auth: true,
            method: 'post',
        }),
    signup: (args: SignupArgs) =>
        sendRequest<SignupResponse>({ endpoint: serverEndPoints.auth.signup, args, auth: false }),

    getUser: ({ id }: GetUserArgs) =>
        sendRequest<GetUserResponse>({
            endpoint: serverEndPoints.auth.getUserById.replace(':id', id),
            args: {},
            method: 'get',
        }),
    checkIsUserExist: (args: { phoneNumber: string }) =>
        sendRequest<{ msg: 'exist' | 'notExist' }>({
            endpoint: serverEndPoints.auth.checkExist,
            args: args,
            method: 'post',
        }),
}
