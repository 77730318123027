import { Avatar, Button, Divider, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiCameraPlus } from '@mdi/js'
import { useFormik } from 'formik'
import React, { useCallback, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { advertisers } from '../../../API/advertisers'
import useBoolean from '../../../hooks/useBoolean'
import { mdiIcon } from '../../../utils/mdiIcon'
import { useAlert } from '../../AlertProvider'
import { useUser } from '../../UserProvider'

interface Props {
    close: () => void
    openPassword: () => void
    setNewAvatar: (newAvatar: string) => void
    currentAvatar: string | undefined
}

export default React.memo(ProfileDialog)

const Camera = mdiIcon(mdiCameraPlus)

function ProfileDialog(props: Props) {
    const { close, openPassword, setNewAvatar, currentAvatar } = props
    const user = useUser().user
    const classes = useStyles()
    const alert = useAlert()
    const [avatar, setAvatar] = useState<any>()
    const [avatarSrc, setAvatarSrc] = useState<any>(currentAvatar)

    const onAvatarChange = useCallback((e) => {
        setAvatar(e.target.files[0])
        setAvatarSrc(window.URL.createObjectURL(e.target.files[0]))
    }, [])

    const [loading, setLoading, closeLoading] = useBoolean()

    const openChangePassword = useCallback(() => {
        openPassword()
        close()
    }, [close, openPassword])

    const formik = useFormik({
        initialValues: {},

        onSubmit: async () => {
            setLoading()

            const [err, res] = await advertisers.UpdateAvatar({ avatar })
            if (err) {
                closeLoading()
                alert('error', 'Error changing permissions')
                return
            }

            closeLoading()
            if (res?.msg == 'success') {
                setNewAvatar(avatarSrc)
                alert('success', 'Avatar changed successfully')
            }
            close()
        },
    })
    const isThereLogo = !!avatar
    return (
        <>
            <div className={classes.dialogTitleContainer}>
                <div />
                <IconButton onClick={close}>
                    <MdClose />
                </IconButton>
            </div>

            <div className={classes.dialogContent}>
                <div className={classes.adminInfo}>
                    <div className={classes.logoName}>
                        <div className={classes.logoInnerContainer}>
                            <div className={isThereLogo ? classes.logoDimensions : classes.placeHolderContainer}>
                                {isThereLogo ? (
                                    <img className={classes.logo} src={avatarSrc} />
                                ) : (
                                    <Avatar
                                        src={currentAvatar ?? user?.avatar}
                                        className={classes.miniAvatar}
                                        classes={{ fallback: classes.fallback }}
                                    />
                                )}
                            </div>
                            <label htmlFor={'upload-logo'} className={classes.addLogoContainer}>
                                <Camera className={classes.addLogoIcon} />
                            </label>
                        </div>
                        <Typography className={classes.dialogName}>{user?.name}</Typography>
                    </div>
                    <input
                        hidden
                        accept="image/*"
                        type="file"
                        id="upload-logo"
                        onChange={onAvatarChange}
                        disabled={loading}
                    />

                    <Divider className={classes.divider} />

                    <div style={{ marginTop: 16 }}>
                        <Typography className={classes.password}>Phone Number</Typography>
                        <Typography className={classes.stars}>{user?.phoneNumber ?? '...'}</Typography>
                    </div>

                    <Typography className={classes.security}>Security</Typography>

                    <div className={classes.passwordContainer}>
                        <div>
                            <Typography className={classes.password}>Password</Typography>
                            <Typography className={classes.stars}>********</Typography>
                        </div>
                        <Button
                            onClick={openChangePassword}
                            className={classes.changeButton}
                            variant="outlined"
                            color="primary"
                        >
                            Change
                        </Button>
                    </div>
                </div>

                <div className={classes.dialogButtons}>
                    <form onSubmit={formik.handleSubmit}>
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <>
                                <Button onClick={close} className={classes.cancelButton} variant="text" color="primary">
                                    Cancel
                                </Button>
                                <Button
                                    disabled={avatar == undefined}
                                    type="submit"
                                    className={classes.addRegionButton}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 240,
        height: 320,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,

        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    content: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fullName: {
        marginTop: 16,
        fontWeight: 500,
        fontSize: 14,
    },
    region: {
        marginTop: 4,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.secondary.main,
    },
    button: {
        width: '100%',
    },
    avatar: {
        height: 128,
        width: 128,
    },
    dialogTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    dialogButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cancelButton: {
        marginInlineEnd: 16,
    },
    changeButton: {},
    addRegionButton: {
        color: theme.palette.background.paper,
    },
    dialogTitle: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    adminInfo: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    adminNameRegion: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogName: {
        color: theme.palette.text.primary,
        fontWeight: 400,
        fontSize: 24,
        marginTop: 16,
        textAlign: 'center',
    },
    security: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 12,
        marginTop: 24,
    },
    dialogRegion: {
        color: theme.palette.text.secondary,
        fontWeight: 400,
        fontSize: 12,
    },
    miniAvatar: {
        height: 120,
        width: 120,
        background: theme.palette.text.primary,
    },
    dialogContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    lol: {
        backgroundColor: theme.palette.background.default,
    },
    xd: {
        backgroundColor: theme.palette.background.default,
        padding: '0px 24px 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    permContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: 8,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    permText: {
        display: 'flex',
        flexDirection: 'column',
    },
    permTitle: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    permDesc: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    aa: {
        boxShadow: 'none',
        borderRadius: 9,
    },
    profile: {
        fontWeight: 500,
        fontSize: 20,
        color: theme.palette.text.primary,
    },
    divider: {
        marginTop: 24,
        width: '100%',
    },
    passwordContainer: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    password: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    stars: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.palette.text.disabled,
    },
    fallback: {
        height: 40,
    },
    logoInnerContainer: {
        transition: theme.transitions.create(['border'], {}),
        position: 'relative',
        borderRadius: '50%',
        maxWidth: 122,
    },
    logoDimensions: {
        width: 124,
        height: 124,
    },
    placeHolderContainer: {
        width: 120,
        height: 120,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `solid 1px ${theme.palette.divider}`,
    },
    logo: {
        width: 124,
        height: 124,
        objectFit: 'cover',
        userSelect: 'none',
        borderRadius: '50%',
    },
    addLogoContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 40,
        height: 40,
        background: theme.palette.background.paper,
        borderRadius: '50%',
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: theme.transitions.create(['transform']),
        '&:hover': {
            transform: 'scale(1.1)',
        },
        '&:active': { transform: 'scale(0.97)' },
    },
    addLogoIcon: {
        width: 23,
        height: 21,
    },
    logoName: {
        minWidth: 122,
    },
}))
