import AdminsPH from './AdminsPH.svg'
import AgenciesPH from './AgenciesPH.svg'
import AgentsPH from './AgentsPH.svg'
import CampaignsPH from './CampaignsPH.svg'
import LogoPng from './Carrot.png'
import Logo from './Carrot.svg'
import CircleOut from './CircleOut.svg'
import CircleRight from './CircleRight.svg'
import CircleTop from './CircleTop.svg'
import Packages from './Packages.svg'
import PackagesPH from './PackagesPH.svg'
import Plans from './Plans.svg'
import PlansPH from './PlansPH.svg'
import RectOut from './RectOut.svg'
import RectRight from './RectRight.svg'
import video from './video.svg'
import VideoAdsPH from './VideoAdsPH.svg'

const assets = {
    logo: Logo,
    logoPng: LogoPng,
    CircleOut,
    CircleRight,
    CircleTop,
    RectOut,
    RectRight,
    video,
    Plans,
    Packages,
    placeHolders: {
        Admins: AdminsPH,
        Agencies: AgenciesPH,
        Agents: AgentsPH,
        Campaigns: CampaignsPH,
        Packages: PackagesPH,
        Plans: PlansPH,
        VideoAds: VideoAdsPH,
    },
}

export default assets
