import React from 'react'

interface Props {
    children: any
    value: number
    index: number
}

export default React.memo(TabPanel)

function TabPanel(props: Props) {
    const { children, value, index } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
        >
            {value === index && <div>{children}</div>}
        </div>
    )
}
