import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormikProps } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { AddPackageArgs } from 'shared/types/packageTypes'
import { AddPlanArgs } from 'shared/types/planTypes'
import { advertisers } from '../../API/advertisers'
import useAsync from '../../hooks/useAsync'

interface Props {
    formik: FormikProps<AddPlanArgs> | FormikProps<AddPackageArgs>
}

export default React.memo(AdvertisersSelect)

function AdvertisersSelect(props: Props) {
    const { formik } = props

    const classes = useStyles()

    const [users] = useAsync(() => advertisers.getAdvertisers({}), [])

    const [value, setValue] = useState(users?.advertisers[0])

    useEffect(() => {
        if (users) {
            setValue(users.advertisers[0])
        }
    }, [users])

    const renderInput = useCallback(
        (params) => (
            <TextField
                {...params}
                className={classes.phoneNumber}
                label="Advertiser"
                variant="filled"
                error={formik.touched.advertiserId && Boolean(formik.errors.advertiserId)}
                helperText={formik.touched.advertiserId && formik.errors.advertiserId}
            />
        ),
        [classes.phoneNumber, formik.errors.advertiserId, formik.touched.advertiserId]
    )

    const onChange = useCallback(
        (_, newValue: any) => {
            setValue(newValue)
            formik.handleChange('advertiserId')(newValue?._id)
        },
        [formik]
    )

    const getOptionLabel = useCallback((x) => x.name ?? '', [])

    if (!users) {
        return null
    }

    return (
        <Autocomplete
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
            options={users.advertisers}
            renderInput={renderInput}
        />
    )
}

const useStyles = makeStyles(() => ({
    phoneNumber: {
        marginBottom: 16,
    },
}))
