import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import AlertProvider from './containers/AlertProvider'
import Router from './containers/Routing/Router'
import ThemeProvider from './containers/ThemeProvider'
import UserProvider from './containers/UserProvider'
import './index.css'

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <UserProvider>
                    <AlertProvider>
                        <Router />
                    </AlertProvider>
                </UserProvider>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
