import { IconButton, Paper, Tabs, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Tab from '@material-ui/core/Tab'
import React, { useCallback, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import TabPanel from '../../../components/TabPanel'
import { CampaignType } from 'shared/Interfaces'
import CampaignAudienceView from './CampaignAudienceView'
import CampaignInfoView from './CampaignInfoView'
import CampaignPointsView from './CampaignPointsView'
import CampaignVideoView from './CampaignVideoView'

interface Props {
    campaign: CampaignType | undefined
    close: () => void
}

export default React.memo(ViewCampaignDialog)

function ViewCampaignDialog(props: Props) {
    const { campaign, close } = props

    const classes = useStyles()

    const [value, setValue] = useState(0)

    const handleTabsChange = useCallback((event, newValue) => setValue(newValue), [])

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerBack}>
                    <IconButton onClick={close}>
                        <FaArrowLeft size={16} />
                    </IconButton>

                    <Typography className={classes.offerInfo}>{campaign?.title || '...'}</Typography>
                </div>

                {/* <Button variant="contained" color="primary" className={classes.save}>
                    Edit
                </Button> */}
            </div>

            <div className={classes.content}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleTabsChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Campaign Info" />

                        <Tab label="Points" />
                        <Tab label="AD Video" />
                        <Tab label=" Targeted audience" />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <CampaignInfoView campaign={campaign} />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <CampaignPointsView campaign={campaign} />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <CampaignVideoView campaign={campaign} />
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                        <CampaignAudienceView campaign={campaign} />
                    </TabPanel>
                </Paper>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
    },
    content: {
        display: 'flex',
        width: '100%',
        overflowY: 'auto',
        justifyContent: 'center',
    },
    headerBack: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    header: {
        width: 'calc(100% - 48px)',
        minHeight: 72,
        maxHeight: 72,
        padding: '0px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#F9F9FA',
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    root: {
        border: 'none',
        boxShadow: 'none',
        marginTop: 16,
    },
    save: {
        color: '#FFF',
        width: 120,
    },
    offerInfo: {
        color: theme.palette.text.primary,
        marginLeft: 8,
    },
}))
