import {
    AddAdVideoArgs,
    AddAdVideoResponse,
    DeleteAdVideoArgs,
    DeleteAdVideoResponse,
    GetAdsVideosResponse,
} from 'shared/types/adsVideosTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const adsVideos = {
    add: (args: AddAdVideoArgs) => sendRequest<AddAdVideoResponse>({ endpoint: serverEndPoints.adVideos.add, args }),
    get: () => sendRequest<GetAdsVideosResponse>({ endpoint: serverEndPoints.adVideos.get, args: {}, method: 'get' }),
    delete: ({ _id }: DeleteAdVideoArgs) =>
        sendRequest<DeleteAdVideoResponse>({
            endpoint: serverEndPoints.adVideos.delete.replace(':id', _id),
            args: {},
            method: 'delete',
        }),
}
