const REACT_APP_SERVER_URL = 'https://carrot-beta.op5.me'
export const baseAppUrl = 'http://192.168.50.32'

const dev = false

export const withServerUrl = (end: string) => {
    const path = dev ? `${baseAppUrl}:8346/${end}` : `${REACT_APP_SERVER_URL}/${end}`
    console.log('path', path)
    return path
}

export const serverEndPoints = {
    socket: dev ? `${baseAppUrl}:${8346}` : `${baseAppUrl}`,

    auth: {
        login: 'auth/login',
        sendCode: 'auth/sendCode',
        verifyCode: 'auth/verifyCode',
        changePassword: 'auth/changePass',
        signup: 'auth/signup',
        endUserSignup: 'auth/endUserSignup',
        getUserById: 'user/:id',
        checkExist: 'auth/checkExist',
    },
    admins: {
        getAll: 'admins',
        add: 'admins',
        changePermissions: 'admins/:id',
        toggle: 'admins-toggle',
    },
    agencies: {
        add: 'agencies',
        get: 'agencies',
        toggle: 'agencies/toggle',
    },
    adVideos: {
        add: 'ads-videos',
        get: 'ads-videos',
        delete: 'points/:id',
    },
    agents: {
        get: 'agents',
        add: 'agents',
        toggle: 'agents-toggle',
        changePermissions: 'agents/:id',
    },

    regions: {
        add: 'regions',
        get: 'regions',
        getById: 'getById',
    },

    points: {
        add: 'points',
        get: 'points',
        check: 'checkPoint',
        edit: 'points/:id',
        delete: 'delete-point/:id',
        getById: 'points/:id',
        getByRegion: 'regions/:region/points',
        getPointsByIds: 'points/getPointsByIds',
        archive: 'points/archive/:id',
    },
    advertisers: {
        add: 'advertisers',
        get: 'advertisers',
        getById: 'advertisers/:id',
        getByRegion: 'advertisers/:region',
    },

    campaigns: {
        add: 'campaigns',
        get: 'campaigns',
        update: 'campaigns/:id',
        getById: 'campaign/:id',
        getByUserId: 'campaigns/:id',
        getByPointId: 'campaigns-by-points/:point',
        param: 'param',
        delete: 'delete/:id',
    },
    interests: {
        add: 'interests',
        get: 'interests',
        getById: 'interests/:id',
    },
    pointCampaigns: {
        get: 'pointCampaigns',
    },

    endUsers: {
        get: 'endUsers',
        editProfileAttributes: 'editProfileAttributes',
        editAdvProfile: 'editAdvProfile',
    },
    plans: {
        get: 'plans',
        add: 'plans',
        delete: 'plans/:id',
    },
    packs: {
        get: 'packs',
        add: 'packs',
        delete: 'packs/:id',
    },
    subscriptions: {
        savedCards: 'cards',
        buySavedCards: 'cards',
        buy: 'buy',
        cancel: 'cancel/:id',
        active: 'active',
        get: 'subscriptions',
        offersCount: 'offersCount',
        deleteCard: 'deleteCard',
        changeSaved: 'changeSaved',
        changeNew: 'changeNew',
    },
    profile: {
        changePassword: 'change-password',
        avatar: 'avatar',
    },
    offerRedeem: {
        createOfferRedeem: 'offerRedeem/createOfferRedeem',
        checkRedeem: 'offerRedeem/checkRedeem',
        updateOfferRedeem: 'offerRedeem/update',
        getList: 'offerRedeem/getList',
        getById: 'offerRedeem/getById',
    },
    files: {
        upload: 'files/upload',
    },
} as const
