import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiArrowRight } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { CampaignStatus, CampaignType } from 'shared/Interfaces'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import CampaignDialog from '../AdminCampaigns/CampaignDialog'

interface Props {
    campaign: CampaignType
}

export default React.memo(AdvertiserCampaignCard)
const Arrow = mdiIcon(mdiArrowRight)
function AdvertiserCampaignCard(props: Props) {
    const { campaign } = props
    const classes = useStyles()

    const [currentStatus, setStatus] = useState<CampaignStatus>(campaign.status)

    const [open, setOpen, close] = useBoolean()

    const status = useCallback((statusType: CampaignStatus) => {
        if (statusType === 'pendingApproval') {
            return 'Pending Approval'
        }
        if (statusType === 'approved') {
            return 'Approved'
        }
        if (statusType === 'rejected') {
            return 'Rejected'
        } else {
            return statusType
        }
    }, [])
    const handleCloseApprove = useCallback(() => {
        close()
        setStatus('approved')
    }, [close])
    const handleCloseReject = useCallback(() => {
        close()
        setStatus('rejected')
    }, [close])

    const dateConverter = useCallback((date) => {
        const newDate = new Date(date).toLocaleString()

        return newDate
    }, [])

    return (
        <div>
            <CampaignDialog
                state={open}
                onPress={close}
                approveS={handleCloseApprove}
                rejectS={handleCloseReject}
                campaign={campaign}
                currentStatus={currentStatus}
            />
            <div className={classes.container} onClick={setOpen}>
                <div className={classes.infoContent}>
                    <Typography className={classes.advName}>{status(currentStatus)}</Typography>
                    <Typography className={classes.title}>{campaign.title}</Typography>
                    <div className={classes.dates}>
                        <Typography className={classes.datesText}>{dateConverter(campaign.collectTime)}</Typography>
                        <Arrow className={classes.arrow} />
                        <Typography className={classes.datesText}>{dateConverter(campaign.untilTime)}</Typography>
                    </div>
                    <div className={classes.offersAds}>
                        <Typography className={classes.offersAdsText}>3 offers · 2 ads</Typography>
                    </div>
                </div>
                <div className={classes.costAndDetails}>
                    <Typography className={classes.viewDetails}>View Details</Typography>
                    <div className={classes.cost}>
                        <Typography className={classes.costTitle}>Cost</Typography>
                        <Typography className={classes.costValue}>... $</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        width: 648,
        height: 112,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
        padding: 16,
        marginTop: 24,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: theme.transitions.create(['background']),
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.action.hover,
            '& $viewDetails': {
                opacity: 1,
            },
        },
        '&:active': {
            background: theme.palette.action.selected,
            '& $viewDetails': {
                opacity: 1,
            },
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    storeContainer: {
        width: 54,
        height: 54,
        borderRadius: 50,
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    store: {
        fontSize: 28,
        color: theme.palette.text.disabled,
    },
    infoContent: {
        marginLeft: 4,
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    advName: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.text.secondary,
        marginBottom: 4,
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: theme.palette.text.primary,
        marginBottom: 12,
    },
    datesText: {
        fontSize: 12,
        fontWeight: 400,
        color: theme.palette.text.hint,
    },
    offersAdsText: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.palette.text.secondary,
    },
    arrow: {
        fontSize: 12,
        marginInline: 4,
        color: theme.palette.divider,
    },
    dates: {
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    offersAds: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    costAndDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: '100%',
    },
    viewDetails: {
        color: theme.palette.primary.main,
        opacity: 0,
        fontWeight: 500,
        fontSize: 14,
        transition: theme.transitions.create(['opacity']),
    },
    cost: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    costTitle: { color: theme.palette.text.hint, fontWeight: 400, fontSize: 12 },
    costValue: { color: theme.palette.text.primary, fontWeight: 500, fontSize: 20 },
}))
