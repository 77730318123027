import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { mdiCameraPlus, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { useFormik } from 'formik'
import { orderBy } from 'lodash'
import React, { useCallback, useState } from 'react'
import { MdArrowForward } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { auth } from '../../API/auth'
import { regions } from '../../API/regions'
import OnBoardingBackground from '../../assets/OnBoardingBackground'
import CountrySearchMenu from '../../components/CountrySearchMenu'
import { useAlert } from '../../containers/AlertProvider'
import Loading from '../../containers/Layout/Loading'
import useAsync from '../../hooks/useAsync'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import storeToken from '../../utils/storeToken'
import { countryCodeRegex, phoneRegex } from './Login'

const EyeOn = mdiIcon(mdiEyeOutline)
const EyeOff = mdiIcon(mdiEyeOffOutline)
const Camera = mdiIcon(mdiCameraPlus)

export default React.memo(AdvertiserSignup)

function AdvertiserSignup() {
    const classes = useStyles()
    const alert = useAlert()
    const history = useHistory()

    const [loadedRegions] = useAsync(() => regions.get({}))

    const [step, setStep] = useState<'info' | 'code'>('info')
    const [avatar, setAvatar] = useState<any>()
    const [logoSrc, setLogoSrc] = useState<any>()
    const [visiblePassword, setVisiblePassword] = useState(false)

    const [loading, setLoading, stopLoading] = useBoolean()

    const codeFormik = useFormik({
        initialValues: { code: '', token: '', phoneNumber: '' },
        validationSchema: codeSchema,

        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await auth.verifyCode({
                token: values.token,
                code: values.code,
                phoneNumber: values.phoneNumber,
            })

            if (err || !res) {
                console.error(err)
                alert('error', 'An unknown error has occured')
                stopLoading()

                return
            }

            if (res.msg === 'wrongCode') {
                alert('error', 'Code is not correcrt')
                stopLoading()

                return
            }

            const [error, result] = await auth.signup({
                avatar,
                userType: 'advertiser',
                phoneNumber: infoForm.values.countryCode + values.phoneNumber,
                password: infoForm.values.password,
                name: infoForm.values.name,
                regionId: infoForm.values.region,
            })

            if (error || !result) {
                console.error(error)
                alert('error', 'An unknown error has occured')
                return
            }

            const { token, userId } = result

            if (!token) {
                if (result.msg === 'userExists') {
                    alert('error', 'Account already exists')
                } else {
                    alert('error', 'An unknown error has occured')
                }

                return
            }

            storeToken(token, userId!)
            stopLoading()
            setTimeout(() => {
                location.href = '/'
            }, 500)
        },
    })

    const infoForm = useFormik({
        initialValues: { phoneNumber: '', password: '', name: '', region: '', countryCode: '+90' },
        validationSchema: userSchema,

        onSubmit: async (values) => {
            setLoading()
            const [err, res] = await auth.sendCode({ phoneNumber: values.countryCode + values.phoneNumber })

            if (!res) {
                console.error(err)
                alert('error', 'Unknown Error')
                stopLoading()

                return
            }

            codeFormik.values.token = res.token
            codeFormik.values.phoneNumber = values.countryCode + values.phoneNumber
            stopLoading()
            setStep('code')
        },
    })
    const setCode = useCallback(
        (code: string) => {
            infoForm.setFieldValue('countryCode', code)
        },
        [infoForm]
    )
    const onLogoChange = useCallback((e) => {
        setAvatar(e.target.files[0])
        setLogoSrc(window.URL.createObjectURL(e.target.files[0]))
    }, [])

    const handleVisiblePassword = useCallback(() => setVisiblePassword((x) => !x), [])

    const formSubmit = useCallback(
        (e) => {
            if (step === 'info') {
                return infoForm.handleSubmit(e)
            }
            if (step === 'code') {
                return codeFormik.handleSubmit(e)
            }
        },
        [codeFormik, infoForm, step]
    )

    const isThereLogo = !!avatar

    const goToLogin = useCallback(() => history.push('/login'), [history])

    if (!loadedRegions) {
        return <Loading />
    }

    return (
        <div className={classes.container}>
            <OnBoardingBackground />

            <div className={classes.body}>
                <form className={classes.form} onSubmit={formSubmit}>
                    <Typography className={classes.title}>Sign Up</Typography>

                    {step === 'info' ? (
                        <div className={classes.textFields}>
                            <div className={classes.logoInnerContainer}>
                                <div className={isThereLogo ? classes.logoDimensions : classes.placeHolderContainer}>
                                    {isThereLogo ? (
                                        <img className={classes.logo} src={logoSrc} />
                                    ) : (
                                        <Avatar className={classes.placeHolder} />
                                    )}
                                </div>
                                <label htmlFor={'upload-logo'} className={classes.addLogoContainer}>
                                    <Camera className={classes.addLogoIcon} />
                                </label>
                            </div>
                            <input
                                hidden
                                accept="image/*"
                                type="file"
                                id="upload-logo"
                                onChange={onLogoChange}
                                disabled={loading}
                            />

                            <TextField
                                disabled={loading}
                                label="Full Name"
                                onChange={infoForm.handleChange('name')}
                                variant="filled"
                                className={classes.phoneNumber}
                                value={infoForm.values.name}
                                error={infoForm.touched.name && Boolean(infoForm.errors.name)}
                                helperText={infoForm.touched.name && infoForm.errors.name}
                            />

                            <FormControl variant="filled" className={classes.phoneNumber}>
                                <InputLabel>Region</InputLabel>
                                <Select
                                    disabled={loading}
                                    error={infoForm.touched.region && Boolean(infoForm.errors.region)}
                                    value={infoForm.values.region}
                                    onChange={infoForm.handleChange('region')}
                                >
                                    {orderBy(loadedRegions.regions, (x) => x.name, 'desc').map((x) => {
                                        return (
                                            <MenuItem key={x._id} value={x._id}>
                                                {x.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                            <div className={classes.phoneInput}>
                                <CountrySearchMenu setCode={setCode} loading={loading} />
                                <TextField
                                    style={{ flex: 1 }}
                                    disabled={loading}
                                    label="Phone Number"
                                    onChange={infoForm.handleChange('phoneNumber')}
                                    variant="filled"
                                    className={classes.phoneNumber}
                                    value={infoForm.values.phoneNumber}
                                    error={infoForm.touched.phoneNumber && Boolean(infoForm.errors.phoneNumber)}
                                    helperText={infoForm.touched.phoneNumber && infoForm.errors.phoneNumber}
                                />
                            </div>
                            <TextField
                                disabled={loading}
                                className={classes.passwordField}
                                label="Password"
                                type={visiblePassword ? 'text' : 'password'}
                                variant="filled"
                                onChange={infoForm.handleChange('password')}
                                value={infoForm.values.password}
                                error={infoForm.touched.password && Boolean(infoForm.errors.password)}
                                helperText={infoForm.touched.password && infoForm.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton onClick={handleVisiblePassword}>
                                            {visiblePassword ? <EyeOn /> : <EyeOff />}
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>
                    ) : (
                        <div className={classes.textFields}>
                            <TextField
                                disabled={loading}
                                label="Verification Code"
                                onChange={codeFormik.handleChange('code')}
                                variant="filled"
                                className={classes.codeInput}
                                value={codeFormik.values.code}
                                error={codeFormik.touched.code && Boolean(codeFormik.errors.code)}
                                helperText={codeFormik.touched.code && codeFormik.errors.code}
                            />
                        </div>
                    )}

                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                        Sign Up
                    </Button>

                    {step === 'info' ? (
                        <div className={classes.regContainer}>
                            <Typography className={classes.dontHave}>Already have an account?</Typography>
                            <div className={classes.regNowIcon} onClick={goToLogin}>
                                <Typography className={classes.regNow}>Log In</Typography>
                                <MdArrowForward className={classes.arrow} />
                            </div>
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.background.paper,
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
    },
    body: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        marginTop: 48,
    },
    button: {
        color: theme.palette.background.paper,
        width: '100%',
    },
    textFields: {
        marginBottom: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    phoneNumber: {
        marginBottom: 16,
    },
    passwordField: { marginBottom: 8 },
    forgotPassword: {
        fontWeight: 400,
        color: theme.palette.primary.main,
        fontSize: 12,
    },
    title: {
        fontSize: 24,
        marginBottom: 16,
        marginTop: 64,
    },
    regContainer: {
        marginTop: 62,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 48,
    },
    regNowIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& $arrow': {
                color: theme.palette.primary.dark,
            },
            '& $regNow': {
                color: theme.palette.primary.dark,
            },
        },
    },
    dontHave: {
        fontWeight: 400,
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    regNow: {
        fontWeight: 500,
        color: theme.palette.primary.main,
        fontSize: 14,
        marginInlineEnd: 8,
        transition: theme.transitions.create(['color']),
    },
    arrow: {
        color: theme.palette.primary.main,
        transition: theme.transitions.create(['color']),
    },
    logoInnerContainer: {
        transition: theme.transitions.create(['border'], {}),
        position: 'relative',
        borderRadius: '50%',
        alignSelf: 'center',
        marginBottom: 40,
    },
    logoDimensions: {
        width: 124,
        height: 124,
    },
    logo: {
        width: 124,
        height: 124,
        objectFit: 'cover',
        userSelect: 'none',
        borderRadius: '50%',
    },
    addLogoContainer: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 40,
        height: 40,
        background: theme.palette.background.paper,
        borderRadius: '50%',
        border: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: theme.transitions.create(['transform']),
        '&:hover': {
            transform: 'scale(1.1)',
        },
        '&:active': { transform: 'scale(0.97)' },
    },
    addLogoIcon: {
        width: 23,
        height: 21,
    },
    placeHolderContainer: {
        width: 120,
        height: 120,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `solid 1px ${theme.palette.divider}`,
    },
    placeHolder: {
        width: '100%',
        height: '100%',
        color: theme.palette.background.paper,
        background: theme.palette.background.default,
    },
    codeInput: {
        marginTop: 48,
    },
    phoneInput: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
}))

const userSchema = yup.object({
    name: yup.string().min(3, 'Full name should be of minimum 3 characters length').required('Full name is required'),
    countryCode: yup.string().matches(countryCodeRegex, 'Invalid country code').required('Country code is required'),

    phoneNumber: yup
        .string()

        .matches(phoneRegex, 'Phone number is not valid')
        .required('Phone Number is required'),
    password: yup.string().min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
    region: yup.string().required('Region is required'),
})

const codeSchema = yup.object({
    code: yup.string().length(6, 'The code should be 6 numbers in length').required('Password is required'),
})
