import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { mdiGiftOutline } from '@mdi/js'
import { map, sumBy } from 'lodash'
import React from 'react'
import { CampaignType } from 'shared/Interfaces'
import { points } from '../../../API/points'
import useAsync from '../../../hooks/useAsync'
import { mdiIcon } from '../../../utils/mdiIcon'
const Gift = mdiIcon(mdiGiftOutline)

interface Props {
    campaign: CampaignType | undefined
}

export default React.memo(CampaignPointsView)

function CampaignPointsView(props: Props) {
    const { campaign } = props

    const classes = useStyles()
    const pointsIds =
        map(campaign?.points, (x) => {
            return x.pointId
        }) || []
    const [loadedPoints] = useAsync(() => points.getPointsByIds({ pointsIds }))
    if (!loadedPoints) {
        return (
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <div className={classes.placeholder} />
            <div className={classes.placeholder} />

            {loadedPoints.points.map((point, idx) => {
                const sum = sumBy(loadedPoints.points, (x) => parseInt(campaign?.points?.[x?._id]?.amount ?? '0'))

                return (
                    <div key={idx} className={classes.itemContainer}>
                        <div className={classes.pointInfoHeader}>
                            <div className={classes.infoContainer}>
                                <div className={classes.giftIconContainer}>
                                    <Gift className={classes.giftIcon} />
                                </div>

                                <div className={classes.pointInfo}>
                                    <Typography className={classes.pointTitle}> {point.title}</Typography>
                                    <Typography className={classes.pointRegion}> {point.region}</Typography>
                                </div>
                            </div>

                            <div className={classes.giftsLength}>
                                <Typography className={classes.lengthNumber}>{sum}</Typography>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
    },
    giftsLength: {
        width: 24,
        height: 16,
        borderRadius: 50,
        backgroundColor: '#D14343',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    lengthNumber: {
        color: '#FFF',
        fontSize: 10,
    },
    infoContainer: {
        display: 'flex',
    },
    pointInfoHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },

    pointInfo: {
        margin: '0px 16px',
    },
    pointTitle: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: 14,
    },
    pointRegion: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },

    giftIconContainer: {
        width: 40,
        height: 40,
        backgroundColor: '#673AB7',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    giftIcon: {
        color: '#FFF',
    },

    titleTypography: {
        color: theme.palette.text.primary,
        fontSize: 14,
    },
    descriptionTypography: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },

    placeholder: {
        minHeight: 24,
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}))
