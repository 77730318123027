import { unstable_createMuiStrictModeTheme } from '@material-ui/core'
import { Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { Shadows } from '@material-ui/core/styles/shadows'
import transitions from '@material-ui/core/styles/transitions'
import React, { useMemo } from 'react'

interface Props {
    children: React.ReactNode
}

const direction: 'rtl' | 'ltr' = 'ltr'

export const extraColors = {
    cyan: '#19B6CA',
    purple: '#6422B7',
    orange: '#DD6C2F',
    red: '#C9261B',
    yellow: '#DBB81E',
    green: '#6BC91F',
    pink: '#B422B7',
}
export const extraBackgrounds = {
    cyan: 'rgba(25, 182, 202,0.12)',
    purple: 'rgba(100, 34, 183,0.12)',
    orange: 'rgba(221, 108, 47,0.12)',
    red: 'rgba(201, 38, 27,0.12)',
    yellow: 'rgba(219, 184, 30,0.12)',
    green: 'rgba(107, 201, 31,0.12)',
    pink: 'rgba(180, 34, 183,0.12)',
}

export const useThemeConfig = () => {
    return useMemo(() => {
        const dirHelpers = {
            start: direction === 'ltr' ? 'left' : 'right',
            end: direction === 'ltr' ? 'right' : 'left',
        }

        const originalTheme = unstable_createMuiStrictModeTheme({
            dirHelpers,
            palette: {
                type: 'light',
                grey: {
                    '100': 'rgba(0, 0, 0, 0.09)',
                },
                background: { default: '#ECEEEF', paper: '#FFFFFF' },
                action: {
                    hover: 'rgba(70, 62, 55, 0.06)',
                    selected: 'rgba(70, 62, 55, 0.12)',
                },
                divider: '#DEE1E3',
                primary: { main: '#FF9800', dark: '#F57C00', light: '#FFB74D' },
                secondary: { main: '#673AB7', dark: '#512DA8', light: '#9575CD' },
                success: { main: '#4CAF50', dark: '#388E3C', light: '#81C784' },
                error: { main: '#F44336', dark: '#D32F2F', light: '#D32F2F' },
                info: { main: '#2196F3', dark: '#1976D2', light: '#64B5F6' },

                text: {
                    primary: '#374047',
                    secondary: '#606E79',
                    hint: '#7F8B93',
                    disabled: '#98A1A8',
                },
            },
            overrides: {
                MuiFormHelperText: {
                    root: {
                        marginTop: 0,
                        height: 0,
                    },
                },
                MuiBackdrop: {
                    root: {
                        transition: `${transitions.create(['opacity', 'backdrop-filter'], {
                            duration: 200,
                        })} !important`,
                        backdropFilter: 'blur(6px)',
                        WebkitBackdropFilter: 'blur(6px)',
                    },
                },
                MuiDialog: {
                    paper: {
                        borderRadius: 8,
                    },
                },
                MuiInputBase: {
                    root: {
                        direction: 'unset',
                    },
                    input: { direction: direction },
                },
                MuiInputLabel: {
                    root: {
                        transformOrigin: 'top ' + dirHelpers.start,
                    },
                    formControl: {
                        [dirHelpers.end]: 'initial',
                        [dirHelpers.start]: 0,
                    },
                    shrink: {
                        transformOrigin: 'top ' + dirHelpers.start,
                    },
                    outlined: {
                        transform:
                            direction === 'ltr' ? 'translate(14px, 20px) scale(1)' : 'translate(-14px, 20px) scale(1)',
                        '&$marginDense': {
                            transform:
                                direction === 'ltr'
                                    ? 'translate(14px, 12px) scale(1)'
                                    : 'translate(-14px, 12px) scale(1)',
                        },

                        '&$shrink': {
                            transform:
                                direction === 'ltr'
                                    ? 'translate(14px, -6px) scale(0.75)'
                                    : 'translate(-14px, -6px) scale(0.75)',
                        },
                    },
                },
                MuiOutlinedInput: {
                    notchedOutline: {
                        transition: transitions.create(['border-color']),
                    },
                },
                MuiListItem: {
                    root: {
                        textAlign: dirHelpers.start as any,
                    },
                    secondaryAction: {
                        paddingInlineEnd: 48,
                        paddingRight: undefined,
                    },
                },
                MuiListItemSecondaryAction: {
                    root: {
                        right: direction === 'ltr' ? 16 : undefined,
                        left: (direction as any) === 'rtl' ? 0 : undefined,
                    },
                },

                // @ts-ignore
                PrivateNotchedOutline: {
                    legendLabelled: {
                        textAlign: dirHelpers.start,
                    },
                },
                MuiAutocomplete: {
                    endAdornment: {
                        [dirHelpers.end]: 0,
                        [dirHelpers.start]: 'initial !important',
                    },
                    inputRoot: {
                        paddingRight: '8px !important',
                    },
                },
                MuiButton: {
                    startIcon: {
                        marginInlineStart: -4,
                        marginInlineEnd: 8,
                    },
                },
            },
            typography: {
                allVariants: {},
                body1: { direction },
                body2: { direction },
                caption: { direction },
                h1: { direction },
                h2: { direction },
                h3: { direction },
                h4: { direction },
                h5: { direction },
                h6: { direction },
                subtitle1: { direction },
                subtitle2: { direction },
                button: {
                    direction,
                    textTransform: 'capitalize' as const,
                },
                fontFamily: [
                    'Poppins',
                    '-apple-system',
                    'BlinkMacSystemFont',
                    'Roboto',
                    '"Helvetica Neue"',
                    'DroidKufi',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                ].join(','),
            },
        })

        const shadows = originalTheme.shadows.slice() as Shadows

        shadows[2] = '0px 1px 1px rgba(70, 62, 55, 0.24), 0px 0px 1px rgba(70, 62, 55, 0.32)'
        shadows[4] = '0px 3px 5px rgba(70, 62, 55, 0.16), 0px 0px 1px rgba(70, 62, 55, 0.32)'
        shadows[8] = '0px 8px 12px rgba(70, 62, 55, 0.16), 0px 0px 1px rgba(70, 62, 55, 0.32)'
        shadows[16] = '0px 10px 18px rgba(70, 62, 55, 0.16), 0px 0px 1px rgba(70, 62, 55, 0.32)'
        shadows[24] = '0px 18px 28px rgba(70, 62, 55, 0.16), 0px 0px 1px rgba(70, 62, 55, 0.32)'

        return {
            ...originalTheme,

            sizes: { cards: { user: { width: 240, avatar: 75 } } },
            shadows,
            shape: { borderRadius: 4 },

            breakpoints: {
                ...originalTheme.breakpoints,
                values: { xs: 0, sm: 600, md: 960, lg: 1400, xl: 1920 },
                laptop: originalTheme.breakpoints.between(960, 1400),
            },
        } as Theme
    }, [])
}

export default function ThemeProvider({ children }: Props) {
    const theme = useThemeConfig()

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
}
