import { Divider, IconButton, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CloseIcon from '@material-ui/icons/Close'
import React, { useCallback, useEffect, useState } from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { PackageType, PlanType } from 'shared/Interfaces'
import { subscriptions } from '../../../API/subscriptions'
import Loading from '../../../containers/Layout/Loading'
import useAsync from '../../../hooks/useAsync'
import useBoolean from '../../../hooks/useBoolean'
import NewPaymentInfo from './NewPaymentInfo'
import PackageInfo from './PackageInfo'
import PlanInfo from './PlanInfo'
import SavedPaymentInfo from './SavedPaymentInfo'
interface Props {
    close: () => void
    pack?: PackageType
    plan?: PlanType
}

export default React.memo(PaymentDialog)

function PaymentDialog(props: Props) {
    const { close, plan, pack } = props
    const classes = useStyles()
    const [paymentState, oldPayment, newPayment] = useBoolean()
    const [loadedCards, cardsLoading] = useAsync(() => subscriptions.getSavedCards({}))
    const cardsLength = loadedCards?.data.length == 0 || loadedCards?.data.length == undefined
    useEffect(() => {
        if (cardsLength) {
            newPayment()
        } else {
            oldPayment()
        }
    }, [cardsLength, newPayment, oldPayment])
    const [url, setUrl] = useState<string | undefined>(undefined)
    const eventListener = useCallback((node: HTMLElement, done: () => void) => {
        node.addEventListener('transitionend', done, false)
    }, [])
    const type = plan ? 'plan' : 'package'
    const price = type == 'plan' ? plan?.cost : pack?.cost
    const currency = type == 'plan' ? plan?.currency : pack?.currency
    const _id = type == 'plan' ? plan?._id : pack?._id

    window.addEventListener('message', (event) => {
        if (event.origin.startsWith('https://')) {
            if (event.data == 'done') {
                location.reload()
            }
        } else {
            return
        }
    })

    if (cardsLoading) {
        return <Loading />
    }
    return (
        <div className={classes.container}>
            <div className={classes.sideBar}>
                {type == 'plan' ? <PlanInfo plan={plan!} /> : <PackageInfo pack={pack!} />}
            </div>
            <div className={classes.payment}>
                <div className={classes.header}>
                    <div className={classes.titleClose}>
                        <Typography className={classes.title}>Enter Payment Info</Typography>
                        <IconButton onClick={close}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Typography className={classes.descriptions}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat amet feugiat ut diam tellus
                        urna, tincidunt.
                    </Typography>
                </div>
                <Divider style={{ marginTop: 24 }} />
                <SwitchTransition>
                    <CSSTransition key={paymentState.toString()} addEndListener={eventListener} classNames="fade">
                        {url ? (
                            <iframe src={url} height="100%" frameBorder="none" id="iframe-id" />
                        ) : paymentState ? (
                            <SavedPaymentInfo
                                goToNew={newPayment}
                                type={type}
                                price={price}
                                currency={currency}
                                _id={_id!}
                                setUrl={setUrl}
                                savedCards={loadedCards!.data}
                                closeSelf={close}
                            />
                        ) : (
                            <NewPaymentInfo
                                type={type}
                                price={price}
                                currency={currency}
                                goToOld={oldPayment}
                                _id={_id!}
                                setUrl={setUrl}
                                cardsLength={cardsLength}
                                closeSelf={close}
                            />
                        )}
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        userSelect: 'none',
    },

    title: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    sideBar: {
        display: 'flex',
        flexDirection: 'column',
        width: 256,
        background: theme.palette.background.default,
        height: '100%',
    },
    payment: { display: 'flex', flexDirection: 'column', padding: 24, width: '100%' },
    header: { width: '100%' },
    titleClose: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    descriptions: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        width: 400,
        lineHeight: '16px',
    },
}))
