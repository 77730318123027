import {
    AddCampaignArgs,
    AddCampaignResponse,
    GetCampaignArgs,
    GetCampaignByIdResponse,
    GetCampaignResponse,
    GetCampaignsArgs,
    GetCampaignsByIdArgs,
    GetCampaignsByPointId,
    GetCampaignsByPointIdResponse,
    GetCampaignsByUserArgs,
    GetCampaignsResponse,
    UpdateCampaignArgs,
    UpdateCampaignResponse,
} from 'shared/types/campaignTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const campaigns = {
    add: (args: AddCampaignArgs) => sendRequest<AddCampaignResponse>({ endpoint: serverEndPoints.campaigns.add, args }),
    param: (args: any) => sendRequest({ endpoint: serverEndPoints.campaigns.param, args }),

    get: (args: GetCampaignsArgs) =>
        sendRequest<GetCampaignsResponse>({ endpoint: serverEndPoints.campaigns.get, args, method: 'get' }),
    getById: ({ id }: GetCampaignsByIdArgs) =>
        sendRequest<GetCampaignByIdResponse>({
            endpoint: serverEndPoints.campaigns.getById.replace(':id', id),
            args: {},
            method: 'get',
        }),

    getByPointId: ({ pointId }: GetCampaignsByPointId) => {
        return sendRequest<GetCampaignsByPointIdResponse>({
            endpoint: serverEndPoints.campaigns.getByPointId.replace(':point', pointId),
            args: {},
            method: 'get',
        })
    },
    getByUserId: ({ id }: GetCampaignsByUserArgs) =>
        sendRequest<GetCampaignsResponse>({
            endpoint: serverEndPoints.campaigns.getByUserId.replace(':id', id),
            args: {},
        method: 'get',
        }),

    update: (args: UpdateCampaignArgs) =>
        sendRequest<UpdateCampaignResponse>({
            endpoint: serverEndPoints.campaigns.update.replace(':id', args.id),
            args,
            method: 'put',
        }),
}
