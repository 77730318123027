import { Button, ButtonBase, Dialog, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { mdiDotsHorizontal, mdiEarth } from '@mdi/js'
import React, { useCallback, useState } from 'react'
import { MdDelete, MdLockOutline, MdStar } from 'react-icons/md'
import { PackageType, SubscriptionType } from 'shared/Interfaces'
import { subscriptions } from '../../API/subscriptions'
import assets from '../../assets/assets'
import { useAlert } from '../../containers/AlertProvider'
import { useUser } from '../../containers/UserProvider'
import useBoolean from '../../hooks/useBoolean'
import { mdiIcon } from '../../utils/mdiIcon'
import DeleteDialog from '../Points/Components/DeleteDialog'
import PaymentDialog from './Payments/PaymentDialog'
import { currencySymbol } from './PlanCard'

interface Props {
    pack: PackageType
    current?: SubscriptionType
    packRemover?: (id: string) => void
}

export default React.memo(PackageCard)
const Earth = mdiIcon(mdiEarth)
const Dots = mdiIcon(mdiDotsHorizontal)

function PackageCard(props: Props) {
    const { pack, packRemover, current } = props
    const { cost, currency, numberOfOffers, periodNumber, periodUnit, subscribers, title, type, _id } = pack
    const classes = useStyles()
    const alert = useAlert()
    const user = useUser().user
    const isAdmin = user?.permissions.includes('manageSubscriptions') || user?.superAdmin
    const subs = subscribers ? subscribers : 0

    const [deleteState, openDelete, closeDelete] = useBoolean()
    const [paymentState, openPayment, closePayment] = useBoolean()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const deletePack = useCallback(async () => {
        await subscriptions.packs.delete({ _id })
        closeDelete()
        packRemover!(_id)
        alert('success', 'Pack Deleted Successfully')
    }, [_id, alert, closeDelete, packRemover])

    return (
        <div className={classes.container}>
            <Dialog onClose={closePayment} open={paymentState} classes={{ paper: classes.dialog }}>
                <PaymentDialog close={closePayment} pack={pack} />
            </Dialog>
            <DeleteDialog open={deleteState} handleClose={closeDelete} handleConfirm={deletePack} itemName={'Pack'} />
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                <MenuItem onClick={openDelete}>
                    <MdDelete className={classes.delete} />
                    <Typography>Delete</Typography>
                </MenuItem>
            </Menu>
            <div className={classes.mainInfo}>
                <div className={classes.header}>
                    <div className={classes.headerInfo}>
                        <img src={assets.Packages} width={64} />
                        <div className={classes.titlePrice}>
                            <Typography className={classes.packageTitle}>{title}</Typography>
                            <div className={classes.costStatus}>
                                <Typography className={classes.packagePrice}>
                                    {currencySymbol(currency)} {cost}
                                    </Typography>
                                    {current ? (
                                        <Typography className={classes.dueDate}>Already purchased*</Typography>
                                    ) : null}
                            </div>
                        </div>
                    </div>
                    {isAdmin ? (
                        <ButtonBase onClick={handleClick} className={classes.menuButton}>
                            <Dots />
                        </ButtonBase>
                    ) : null}
                </div>
                <Divider className={classes.divider} />
                <div className={classes.offersStar}>
                    <Typography className={classes.offersCount}>{numberOfOffers} Offers</Typography>
                    <MdStar className={classes.star} />
                </div>
                <div className={classes.paymentStar}>
                    <Typography className={classes.paymentPeriod}>
                        {periodNumber} {periodUnit}
                    </Typography>
                    <MdStar className={classes.star} />
                </div>
                {isAdmin ? null : (
                    <Button className={classes.buyButton} variant="contained" onClick={openPayment}>
                        {current ? 'Buy again' : 'Buy'}
                    </Button>
                )}
            </div>
            {isAdmin ? (
                <div className={classes.typeInfo}>
                    {type == 'public' ? (
                        <Earth className={classes.earth} />
                    ) : (
                        <MdLockOutline className={classes.earth} />
                    )}

                    <Typography className={classes.audience}>
                        {type == 'public' ? `${subs} subscribers` : `Private`}
                    </Typography>
                </div>
            ) : null}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: 240,
        minWidth: 378,
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 4,
    },
    mainInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: `16px 16px 10px 16px`,
    },
    packageTitle: {
        fontWeight: 500,
        fontSize: 16,
        color: theme.palette.text.secondary,
    },
    packagePrice: {
        fontWeight: 700,
        fontSize: 28,
        color: theme.palette.text.primary,
    },
    divider: {
        marginTop: 24,
    },
    offersCount: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    paymentPeriod: {
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    typeInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        background: theme.palette.background.default,
        height: 32,
        marginTop: 16,
    },
    audience: {
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        letterSpacing: 0.4,
    },
    earth: {
        color: theme.palette.text.secondary,
        height: 16,
        fontSize: 16,
        marginInline: 4,
    },
    offersStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 24,
    },
    paymentStar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 12,
    },
    star: {
        height: 16,

        color: theme.palette.divider,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    headerInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    titlePrice: {
        display: 'flex',
        flexDirection: 'column',
        marginInlineStart: 16,
    },
    delete: {
        fontSize: 20,
        marginRight: 8,
    },
    menuButton: { alignSelf: 'flex-start' },
    buyButton: { marginTop: 8 },
    dialog: {
        maxWidth: 960,
        width: '100%',
        height: 560,
    },
    dueDate: {
        marginLeft:12,
        fontWeight: 500,
        fontSize: 12,
        color: theme.palette.success.main,
    },
    costStatus: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}))
