import {
    GetAdvertiserByIdArgs,
    GetAdvertiserByIdResponse,
    GetAdvertisersArgs,
    GetAdvertisersResponse,
} from 'shared/types/advertiserTypes'
import { UpdateAvatarArgs, UpdateAvatarResponse } from 'shared/types/userTypes'
import { serverEndPoints } from './serverEndpoints'
import sendRequest from './utils/sendRequest'

export const advertisers = {
    getAdvertisers: (args: GetAdvertisersArgs) =>
        sendRequest<GetAdvertisersResponse>({
            endpoint: serverEndPoints.advertisers.get,
            args,
            method: 'get',
        }),
    UpdateAvatar: (args: UpdateAvatarArgs) =>
        sendRequest<UpdateAvatarResponse>({
            endpoint: serverEndPoints.profile.avatar,
            args,
            method: 'put',
        }),

    getById: (args: GetAdvertiserByIdArgs) => {
        return sendRequest<GetAdvertiserByIdResponse>({
            endpoint: serverEndPoints.advertisers.getById.replace(':id', args.id),
            args: {},
            method: 'get',
        })
    },
}
